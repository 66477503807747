.weekly-goal {
  height: 60px;
}
.weekly-goal svg {
  position: relative;
  top: -1px;
}
.weekly-goal .weekly-goal-icon-data {
  position: relative;
  top: -60px;
  left: 20px;
}
.weekly-goal .weekly-goal-icon-data img {
  position: relative;
  left: 5px;
  width: 15px;
}
.weekly-goal .weekly-goal-icon-data .tree-activity-metric-data {
  font-family: "nunitosemibold";
  position: relative;
  left: -3px;
}
.weekly-goal .weekly-goal-icon-data .tree-activity-metric-data-small-font-size {
  font-family: "nunitosemibold";
  position: relative;
  left: -6px;
  font-size: 0.9rem;
}

