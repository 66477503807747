.badge-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  padding: 0.8rem;
  padding-bottom: 20px;
  width: 100%;
}
.badge-component .badge-icon {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

