@import "../../../../module";

@mixin ToastNotificationHead {
	.toast-notification-head {
		height: 30px;
		@include Flexbox(row, center, flex-start, nowrap);

		border-bottom: 1px solid $border-grey;
		padding: 0 10px;

		.toast-notification-status {
			border-radius: 5px;
			height: 20px;
			width: 20px;
			background-color: #007aff;
			margin-right: 5px;

			&.success {
				background-color: $brand-success;
			}

			&.error {
				background-color: $brand-danger;
			}
		}

		.toast-notification-title {
			color: $label-dark-grey;
			width: 100%;
			@include ellipsis(100%, block);
		}

		.toast-notification-close {
			cursor: pointer;

			svg {
				path {
					fill: $label-grey;
				}
			}
		}
	}
}

.toast-notification {
	width: 100%;
	background-color: #fff;
	border: 1px solid $border-grey;
	box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
	height: $toastNotificationHeight;

	&-show-full-message {
		display: block;
		width: 100%;
		background-color: #fff;
		border: 1px solid $border-grey;
		box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
		border-radius: 0.5rem;
		@include ToastNotificationHead();

		.toast-notification-body-show-full-message {
			padding: 0 10px;
			min-height: 40px;
			@include Flexbox(row, center);

			.toast-notification-message {
				font-family: $font-family-semi-bold;
			}
		}
	}
	@include ToastNotificationHead();

	.toast-notification-body {
		padding: 0 10px;
		height: 40px;
		@include Flexbox(row, center);

		.toast-notification-message {
			font-family: $font-family-semi-bold;
			@include ellipsis(100%, block);
			@include SetMediaQuery(small-average-phone) {
				text-overflow: clip;
				white-space: normal;
			}
		}

		.toast-notification-expand-message {
			cursor: pointer;

			svg {
				path {
					fill: $label-grey;
				}
			}
		}
	}
}
