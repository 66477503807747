.assessment-learning-opportunities .learning-opportunity-assets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-wrap: wrap;
}
.assessment-learning-opportunities .learning-opportunity-assets .learning-opportunity-outcome {
  cursor: pointer;
  border-radius: 1rem;
  height: 100%;
  color: #1e1c1a;
  text-align: center;
}
.assessment-learning-opportunities .learning-opportunity-assets .learning-opportunity-outcome:hover {
  background-color: #f8f9fa;
}
.assessment-learning-opportunities .learning-opportunity-assets .learning-opportunity-outcome .outcome-img {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

