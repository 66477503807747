.toast-notification {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  height: 70px;
}
.toast-notification-show-full-message {
  display: block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.toast-notification-show-full-message .toast-notification-head {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-bottom: 1px solid #dee2e6;
  padding: 0 10px;
}
.toast-notification-show-full-message .toast-notification-head .toast-notification-status {
  border-radius: 5px;
  height: 20px;
  width: 20px;
  background-color: #007aff;
  margin-right: 5px;
}
.toast-notification-show-full-message .toast-notification-head .toast-notification-status.success {
  background-color: #5cb85c;
}
.toast-notification-show-full-message .toast-notification-head .toast-notification-status.error {
  background-color: #d9534f;
}
.toast-notification-show-full-message .toast-notification-head .toast-notification-title {
  color: #6c757d;
  width: 100%;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.toast-notification-show-full-message .toast-notification-head .toast-notification-close {
  cursor: pointer;
}
.toast-notification-show-full-message .toast-notification-head .toast-notification-close svg path {
  fill: #979797;
}
.toast-notification-show-full-message .toast-notification-body-show-full-message {
  padding: 0 10px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.toast-notification-show-full-message .toast-notification-body-show-full-message .toast-notification-message {
  font-family: "nunitosemibold";
}
.toast-notification .toast-notification-head {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-bottom: 1px solid #dee2e6;
  padding: 0 10px;
}
.toast-notification .toast-notification-head .toast-notification-status {
  border-radius: 5px;
  height: 20px;
  width: 20px;
  background-color: #007aff;
  margin-right: 5px;
}
.toast-notification .toast-notification-head .toast-notification-status.success {
  background-color: #5cb85c;
}
.toast-notification .toast-notification-head .toast-notification-status.error {
  background-color: #d9534f;
}
.toast-notification .toast-notification-head .toast-notification-title {
  color: #6c757d;
  width: 100%;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.toast-notification .toast-notification-head .toast-notification-close {
  cursor: pointer;
}
.toast-notification .toast-notification-head .toast-notification-close svg path {
  fill: #979797;
}
.toast-notification .toast-notification-body {
  padding: 0 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.toast-notification .toast-notification-body .toast-notification-message {
  font-family: "nunitosemibold";
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (max-width: 375px) {
  .toast-notification .toast-notification-body .toast-notification-message {
    text-overflow: clip;
    white-space: normal;
  }
}
.toast-notification .toast-notification-body .toast-notification-expand-message {
  cursor: pointer;
}
.toast-notification .toast-notification-body .toast-notification-expand-message svg path {
  fill: #979797;
}

