$modal-padding: 40px;
$modal-header-height: 57px;
$modal-footer-height: 57px;
$modal-formatted-header-height: 66px;
$modal-formatted-footer-height: 66px;

.modal-no-padding {
	padding-left: 0;
	padding-right: 0;
}

.react-modal-open {
	z-index: 9000;
	position: absolute;
	right: 0;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.overlay {
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.redux-react-modal-open {
	z-index: 4000;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.ReactModal__Body--open {
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.ReactModal__Overlay {
	&.global-modal-overlay {
		height: 100%;
		overflow: auto;
		transition: padding-top 0.75s ease;

		.ReactModal__Content {
			padding: 0;
			opacity: 0;
			transition: opacity 0.5s ease;
			overflow: visible;

			&.global-modal {
				width: 100%;
				min-height: 100%;
				margin-left: auto;
				margin-right: auto;
				padding-left: 1rem;
				padding-right: 1rem;
				pointer-events: none;
				@include Flexbox(column, center, center);

				& > * {
					pointer-events: auto;
				}

				&.modal-size- {
					&sm {
						max-width: 400px;
					}

					&sm-md {
						max-width: 550px;
					}

					&md {
						max-width: 800px;
					}

					&lg {
						max-width: 1000px;
					}
				}

				.modal-header {
					width: 100%;
					padding: 10px 20px;
					height: $modal-header-height;
					border-bottom: 0;
					@include Flexbox(row, center);

					.modal-header-content {
						display: block;
						width: 100%;
						font-size: 1.2em;
					}

					.modal-close-btn {
						background-color: transparent;
						padding: 0;
						border: 0;
						width: 25px;
						height: 25px;
						opacity: 0.5;

						&:hover {
							opacity: 0.75;
						}

						svg {
							height: 100%;
							width: 100%;
						}
					}
				}

				.modal-body {
					background-color: white;
					flex: none;
					width: 100%;
				}

				.modal-footer {
					width: 100%;
					background-color: #fff;
				}

				&.full-size {
					@include SetMediaQuery(bootstrap-sm) {
						padding: 0;
					}
				}
			}

			&--before-open {
				&.global-modal {
					opacity: 0;
				}
			}

			&--after-open {
				&.global-modal {
					opacity: 1;
				}
			}

			.modal-no-padding {
				padding: 0;
			}

			.modal-body-text {
				text-align: center;
			}

			.modal-body-form-footer {
				padding-top: 1rem;
				margin-top: 1rem;
				text-align: end;
			}

			.modal-body-form-footer-two-buttons {
				padding-top: 1rem;
				margin-top: 1rem;
			}

			.modal-body-message {
				text-align: center;
				margin-top: 2.5rem;
				margin-bottom: 2.5rem;
			}

			.button-width-fixed {
				width: 45%;
			}

			.student-info-list {
				text-align: left;

				.no-list-style {
					list-style: none;
				}
			}

			.new-activity,
			.duplicate-activity {
				height: 100%;
				width: 100%;
				max-width: 1000px;
				max-height: calc(100% - 40px);
				@include editActivityModal();

				.editor-activity-header {
					display: flex;
					align-items: center;
					.free-trial-info {
						@include Flexbox(row, center, center);
						margin-right: 10px;
						.info {
							font-size: 11px;
							line-height: 15px;
							width: 180px;
						}
						.upgrade-label {
							@include Flexbox(row, center, center);
							color: #fff;
							background-color: $north-texas-green;
							padding: 1px 5px;
							border-radius: 5px;
							margin-left: 5px;
							text-transform: uppercase;
							font-size: 11px;
							height: 33px;
							width: 130px;
							.icon {
								padding-right: 2px;
								svg {
									color: #fff;
								}
							}
						}
					}
				}
			}

			.materials-list {
				font-size: 0.8rem;
			}
		}

		/*
			Styling for Modal within device window
			START
		*/
		&.global-modal-in-window {
			overflow: hidden;
			@include Flexbox(row, center);

			.ReactModal__Content {
				&.global-modal {
					height: 100%;
					min-height: auto;

					.modal-header {
						height: $modal-formatted-header-height;
					}

					.modal-body {
						max-height: calc(
							100% - #{$modal-formatted-header-height} - #{$modal-formatted-footer-height}
						);
						overflow-y: auto;

						&.nh-f {
							max-height: calc(100% - #{$modal-formatted-footer-height});
						}

						&.h-nf {
							max-height: calc(100% - #{$modal-formatted-header-height});
						}
					}

					.modal-footer {
						height: $modal-formatted-footer-height;
					}
				}
			}
		}

		/*
			Styling for Modal within device window
			END
		*/
	}
}

.modal-position {
	margin-top: 10rem;

	&--before-open {
		padding-top: 0;
	}

	&--after-open {
		padding-top: 5px;
	}
}
