@import "~react-datepicker/dist/react-datepicker.css";
.adm-nav.nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
  border: none;
}
.adm-nav.nav-link:hover {
  color: #fff;
  background-color: #8f3d6b !important;
  border: none !important;
}
.adm-nav.nav-link.active {
  background-color: #8f3d6b !important;
  color: #fff !important;
  border: none !important;
}

.edu-nav.nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
  border: none;
}
.edu-nav.nav-link:hover {
  color: #fff;
  background-color: #23b9bb !important;
  border: none !important;
}
.edu-nav.nav-link.active {
  background-color: #23b9bb !important;
  color: #fff !important;
  border: none !important;
}

.modal-nav.nav-link {
  color: rgba(255, 255, 255, 0.8);
  border: none;
  background-color: #199b32;
}
.modal-nav.nav-link:hover {
  color: #fff;
  cursor: pointer;
}

.support-email {
  color: #fff;
  text-decoration: none;
  background: transparent;
}
.support-email:hover {
  color: #fff;
  text-decoration: underline;
}

.adm-nav-item {
  flex: 1 1 auto;
  margin-bottom: -1px;
}
.adm-nav-item .act-nav {
  background-color: #fff;
  width: 100%;
}
.adm-nav-item .adm-nav-link {
  color: #6c757d;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.adm-nav-item .adm-nav-link.active {
  color: #8f3d6b;
  border-bottom: 3px solid #8f3d6b;
}
.adm-nav-item .adm-nav-link:hover {
  color: #8f3d6b;
}
.adm-nav-item .adm-nav-link:focus {
  outline: none;
}

.no-default-link-style {
  color: #fff;
  text-decoration: none;
}
.no-default-link-style:hover, .no-default-link-style:focus {
  text-decoration: none;
  color: #fff;
}

.btn-clickable {
  cursor: pointer;
}

.adm-btn-primary {
  background-color: #8f3d6b !important;
  border-color: #8f3d6b !important;
  color: #fff !important;
}
.adm-btn-primary:hover:enabled {
  background-color: #682e52 !important;
  border-color: #682e52 !important;
}

.adm-btn-outline-primary {
  color: #8f3d6b !important;
  border-color: #8f3d6b !important;
  border-width: 1px !important;
  background-color: #fff !important;
  cursor: pointer;
}
.adm-btn-outline-primary.active {
  background-color: #8f3d6b !important;
  color: rgba(255, 255, 255, 0.068) !important;
}
.adm-btn-outline-primary:hover {
  background-color: #8f3d6b !important;
  color: #fff !important;
}

.adm-btn-alt {
  color: #6c757d;
  cursor: pointer;
}

.adm-btn-alt:hover {
  color: #8f3d6b !important;
}

.adm-btn-alt-border:hover {
  cursor: pointer;
  background-color: #dee2e6;
}

.adm-btn-domain-outline-primary {
  border-width: 2px;
  opacity: 0.5;
}
.adm-btn-domain-outline-primary.active {
  border-color: #8f3d6b !important;
  color: #fff !important;
  opacity: 1;
}

.edu-btn-primary {
  background-color: #23b9bb !important;
  border-color: #23b9bb !important;
  color: #fff !important;
}
.edu-btn-primary:hover {
  background-color: #10a8aa !important;
}

.accent-btn {
  background-color: #23b9bb;
  border-color: #23b9bb;
  color: #fff;
}
.accent-btn:hover {
  background-color: #10a8aa;
  border-color: #10a8aa;
  color: #fff;
}

.reg-btn-link {
  color: #23b9bb !important;
  background-color: transparent !important;
}

.reg-btn-outline-primary {
  color: black !important;
  border-color: #addf25 !important;
  border-width: 2px !important;
  background-color: #fff !important;
}
.reg-btn-outline-primary.active {
  background-color: #addf25 !important;
  color: black !important;
}
.reg-btn-outline-primary:hover {
  background-color: #addf25 !important;
  color: black !important;
}

.bg-white-button {
  background-color: #fff;
}

.nav-page-btn-left {
  left: 0;
  align-self: center;
  margin: 0;
}

.nav-page-btn-right {
  right: 0;
  margin: 0;
  float: right;
}

.no-default-style {
  border: 0;
  padding: 0;
  background-color: #fff;
}
.no-default-style:focus {
  outline: none;
}

.text-capitalize::first-letter {
  text-transform: capitalize;
}

.role-toggle-icon {
  display: inline-block;
  width: 1rem;
}

.text-btn {
  color: #6c757d;
}
.text-btn:hover, .text-btn.active {
  cursor: pointer;
  color: #199b32;
  text-decoration: none;
}
.text-btn-edu {
  color: #6c757d;
}
.text-btn-edu:hover, .text-btn-edu.active {
  cursor: pointer;
  color: #23b9bb;
  text-decoration: none;
}
.text-btn-adm {
  color: #6c757d;
}
.text-btn-adm:hover, .text-btn-adm.active {
  cursor: pointer;
  color: #8f3d6b;
  text-decoration: none;
}

.circle-badge {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 30px;
  background: #1acac0;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.no-event {
  pointer-events: none;
}

.exit-btn {
  position: relative;
  z-index: 9999;
}
.exit-btn::before {
  content: "this is a button";
  padding: 15px;
  border-radius: 50px;
  position: absolute;
  background-color: #036627;
  display: block;
}

.modal {
  z-index: 3003;
}

.modal-dialog {
  z-index: 3003;
}

.modal-lg {
  z-index: 3003;
}

.adm-text-primary {
  color: #8f3d6b !important;
}

.mc {
  top: 292px;
  position: relative;
}

.mc-2 {
  margin-top: 56px;
}

.ws-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-top-secondary {
  position: fixed;
  top: 54px;
  right: 0;
  left: 0;
  z-index: 1000;
}

.d3-tip {
  z-index: 99999;
}

.activity-card {
  height: 100%;
}

strong {
  font-family: "nunitobold";
  font-weight: 400;
}

.sec-top-border,
.sec-edu-top-border {
  border-top: 1px solid #10a8aa;
}

.sec-adm-top-border {
  border-top: 1px solid #682e52;
}

.bg-none {
  background: none !important;
}

.bg-primary {
  background-color: #199b32 !important;
}

.adm-bg-primary {
  background-color: #8f3d6b !important;
}

.adm-bg-secondary {
  background-color: #682e52 !important;
}

.adm-bg-20 {
  background-color: #edd7e2 !important;
}

.adm-bg-80 {
  background-color: #a5648b !important;
}

.edu-bg-primary {
  background-color: #23b9bb !important;
}

.edu-bg-secondary {
  background-color: #10a8aa !important;
}

.modal-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.react-modal-open {
  z-index: 9000;
  position: absolute;
  right: 0;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.redux-react-modal-open {
  z-index: 4000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay.global-modal-overlay {
  height: 100%;
  overflow: auto;
  transition: padding-top 0.75s ease;
  /*
  	Styling for Modal within device window
  	START
  */
  /*
  	Styling for Modal within device window
  	END
  */
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content {
  padding: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
  overflow: visible;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal {
  width: 100%;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal > * {
  pointer-events: auto;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal.modal-size-sm {
  max-width: 400px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal.modal-size-sm-md {
  max-width: 550px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal.modal-size-md {
  max-width: 800px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal.modal-size-lg {
  max-width: 1000px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-header {
  width: 100%;
  padding: 10px 20px;
  height: 57px;
  border-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-header .modal-header-content {
  display: block;
  width: 100%;
  font-size: 1.2em;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-header .modal-close-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  width: 25px;
  height: 25px;
  opacity: 0.5;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-header .modal-close-btn:hover {
  opacity: 0.75;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-header .modal-close-btn svg {
  height: 100%;
  width: 100%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-body {
  background-color: white;
  flex: none;
  width: 100%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal .modal-footer {
  width: 100%;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .ReactModal__Overlay.global-modal-overlay .ReactModal__Content.global-modal.full-size {
    padding: 0;
  }
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content--before-open.global-modal {
  opacity: 0;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content--after-open.global-modal {
  opacity: 1;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .modal-no-padding {
  padding: 0;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .modal-body-text {
  text-align: center;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .modal-body-form-footer {
  padding-top: 1rem;
  margin-top: 1rem;
  text-align: end;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .modal-body-form-footer-two-buttons {
  padding-top: 1rem;
  margin-top: 1rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .modal-body-message {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .button-width-fixed {
  width: 45%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .student-info-list {
  text-align: left;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .student-info-list .no-list-style {
  list-style: none;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  max-height: calc(100% - 40px);
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .multi-select-item,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .multi-select-item {
  background-color: #6c757d;
  padding: 0 3px;
  margin-right: 3px;
  border-radius: 4px;
  float: left;
  font-size: 80%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .multi-select-item:not(:last-child),
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .multi-select-item:not(:last-child) {
  margin-bottom: 3px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .multi-select-item.published-status,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .multi-select-item.published-status {
  background-color: #23b9bb;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .multi-select-item-contrast,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .multi-select-item-contrast {
  background-color: white;
  color: #23b9bb;
  border: 1px solid #23b9bb;
  padding: 0 3px;
  margin-right: 3px;
  border-radius: 4px;
  float: left;
  font-size: 80%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .multi-select-item-contrast:not(:last-child),
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .multi-select-item-contrast:not(:last-child) {
  margin-bottom: 3px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .multi-select-item-bg-dark-grey,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .multi-select-item-bg-dark-grey {
  background-color: #6c757d;
  padding: 0 3px;
  margin-right: 3px;
  border-radius: 4px;
  float: left;
  font-size: 80%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .new-activity-form,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .new-activity-form {
  padding: 2rem 2rem 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid #dee2e6;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .new-activity-form input[type=radio] + label,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .new-activity-form input[type=radio] + label {
  cursor: pointer;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .new-activity-form input[type=radio] + label::after,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .new-activity-form input[type=radio] + label::after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: 5px;
  left: 2px;
  position: relative;
  margin: 0 20px 0 0;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #dee2e6;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .new-activity-form input[type=radio] + label:hover::after,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .new-activity-form input[type=radio] + label:hover::after {
  background: #23b9bb;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .new-activity-form input[type=radio]:checked + label::after,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .new-activity-form input[type=radio]:checked + label::after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: 5px;
  left: 2px;
  position: relative;
  margin: 0 20px 0 0;
  background-color: #23b9bb;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #dee2e6;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .activity-curriculum,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .activity-curriculum {
  padding: 1rem 2rem 2rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .activity-curriculum .collapse-css-transition,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .activity-curriculum .collapse-css-transition {
  transition: height 300ms linear;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .activity-curriculum .fa-chevron-up,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .activity-curriculum .fa-chevron-up {
  color: #23b9bb;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .activity-curriculum .fa-chevron-down,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .activity-curriculum .fa-chevron-down {
  color: #23b9bb;
  opacity: 0.5;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .competencies,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .competencies {
  padding: 1rem 0 0 0;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes-dropdown,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes-dropdown {
  max-height: 20rem;
  overflow-y: scroll;
  overflow-x: scroll;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes {
  padding-top: 1rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .curriculum-learning-outcomes,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .curriculum-learning-outcomes {
  border: 1px solid #dee2e6;
  padding: 1rem 1rem;
  margin-bottom: 0.8rem;
  border-radius: 1rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .curriculum-learning-outcomes .learning-outcomes,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .curriculum-learning-outcomes .learning-outcomes {
  width: 100%;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .curriculum-learning-outcomes .learning-outcomes .learning-outcome-button,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .curriculum-learning-outcomes .learning-outcomes .learning-outcome-button {
  position: relative;
  top: 1.5rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .curriculum-learning-outcomes .learning-outcomes .learning-outcome-button .fa-chevron-up,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .curriculum-learning-outcomes .learning-outcomes .learning-outcome-button .fa-chevron-up {
  color: #23b9bb;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .curriculum-learning-outcomes .learning-outcomes .learning-outcome-button .fa-chevron-down,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .curriculum-learning-outcomes .learning-outcomes .learning-outcome-button .fa-chevron-down {
  color: #23b9bb;
  opacity: 0.5;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .curriculum-learning-outcomes .general-outcomes,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .curriculum-learning-outcomes .general-outcomes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.2rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes {
  width: 100%;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-md,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-md {
  position: absolute;
  left: 0;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-md .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-md .small-row {
  width: 15px;
  height: 22px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-sm,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-lg,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-xl,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-sm,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-lg,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-xl {
  position: absolute;
  left: 0;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-sm .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-lg .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-xl .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-sm .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-lg .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-xl .small-row {
  width: 15px;
  height: 24px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-xs,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-xs {
  position: absolute;
  left: 0;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .small-col-xs .small-row,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .small-col-xs .small-row {
  width: 15px;
  height: 28px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row #row-2-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row #row-2-right {
  border-bottom: 1px solid #dee2e6;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row #row-5-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row #row-5-right {
  border-bottom: 1px solid #dee2e6;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row #row-3-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row #row-4-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row #row-5-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row #row-3-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row #row-4-right,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row #row-5-right {
  border-left: 1px solid #dee2e6;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .big-col,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .big-col {
  width: 18rem;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .general-curriculum-outcomes .row .delete-outcome-button,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .general-curriculum-outcomes .row .delete-outcome-button {
  position: absolute;
  top: 3px;
  line-height: 0;
  left: 12.5rem;
  padding: 1px 5px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .add-learning-outcome,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .add-learning-outcome {
  position: relative;
  margin-top: 10px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .outcomes .add-learning-outcome-button,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .outcomes .add-learning-outcome-button {
  margin-top: 1rem;
  display: block;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .add-curriculum,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .add-curriculum {
  padding: 2rem 2rem 2rem;
  text-align: center;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .editor-activity-header,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .editor-activity-header {
  display: flex;
  align-items: center;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .editor-activity-header .free-trial-info,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .editor-activity-header .free-trial-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-right: 10px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .editor-activity-header .free-trial-info .info,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .editor-activity-header .free-trial-info .info {
  font-size: 11px;
  line-height: 15px;
  width: 180px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .editor-activity-header .free-trial-info .upgrade-label,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .editor-activity-header .free-trial-info .upgrade-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #fff;
  background-color: #00962b;
  padding: 1px 5px;
  border-radius: 5px;
  margin-left: 5px;
  text-transform: uppercase;
  font-size: 11px;
  height: 33px;
  width: 130px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .editor-activity-header .free-trial-info .upgrade-label .icon,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .editor-activity-header .free-trial-info .upgrade-label .icon {
  padding-right: 2px;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .new-activity .editor-activity-header .free-trial-info .upgrade-label .icon svg,
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .duplicate-activity .editor-activity-header .free-trial-info .upgrade-label .icon svg {
  color: #fff;
}
.ReactModal__Overlay.global-modal-overlay .ReactModal__Content .materials-list {
  font-size: 0.8rem;
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window .ReactModal__Content.global-modal {
  height: 100%;
  min-height: auto;
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window .ReactModal__Content.global-modal .modal-header {
  height: 66px;
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window .ReactModal__Content.global-modal .modal-body {
  max-height: calc(100% - 66px - 66px);
  overflow-y: auto;
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window .ReactModal__Content.global-modal .modal-body.nh-f {
  max-height: calc(100% - 66px);
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window .ReactModal__Content.global-modal .modal-body.h-nf {
  max-height: calc(100% - 66px);
}
.ReactModal__Overlay.global-modal-overlay.global-modal-in-window .ReactModal__Content.global-modal .modal-footer {
  height: 66px;
}

.modal-position {
  margin-top: 10rem;
}
.modal-position--before-open {
  padding-top: 0;
}
.modal-position--after-open {
  padding-top: 5px;
}

.flex {
  display: flex;
}
.flex.direction-column {
  flex-direction: column;
}
.flex.align-start {
  align-items: flex-start;
}
.flex.align-center {
  align-items: center;
}
.flex.align-end {
  align-items: flex-end;
}
.flex.justify-center {
  justify-content: center;
}
.flex.justify-end {
  justify-content: flex-end;
}
.flex.justify-between {
  justify-content: space-between;
}
.flex.justify-around {
  justify-content: space-around;
}
.flex.wrap {
  flex-wrap: wrap;
}
.flex.nowrap {
  flex-wrap: nowrap;
}
.flex-1 {
  flex: 1;
}

.richEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  font-size: 14px;
  padding: 15px;
  overflow: hidden;
}
.richEditor-root .richEditor-controls {
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}
.richEditor-root .richEditor-controls .richEditor-styleButton {
  color: #23b9bb;
  cursor: pointer;
  margin-right: 16px;
  display: inline-block;
  border: 1px solid #ddd;
  padding: 2px 3px 0;
  border-radius: 2px;
  opacity: 0.8;
}
.richEditor-root .richEditor-controls .richEditor-styleButton:hover {
  opacity: 1;
}
.richEditor-root .richEditor-controls .richEditor-activeButton {
  opacity: 1;
  border-color: #23b9bb;
}
.richEditor-root .richEditor-editor {
  box-sizing: border-box;
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}
.richEditor-root .richEditor-editor:global(.public-DraftEditor-content) {
  min-height: 140px;
}
.richEditor-root .richEditor-editor .public-DraftEditorPlaceholder-root,
.richEditor-root .richEditor-editor .public-DraftEditor-content {
  padding-top: 15px;
  min-height: 100px;
}
.richEditor-root .richEditor-editor .public-DraftEditorPlaceholder-root figure img,
.richEditor-root .richEditor-editor .public-DraftEditor-content figure img {
  margin: 10px;
}
.richEditor-root .richEditor-editor .richEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}
.richEditor-root .richEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 16px;
  padding: 20px;
}
.richEditor-root .richEditor-editor .richEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}
.richEditor-root .richEditor-editor .draftJsToolbar__toolbar__dNtBH {
  position: absolute;
  z-index: 9999;
}
.richEditor-root .richEditor-editor .draftJsToolbar__toolbar__dNtBH button {
  border: 1px solid #ddd;
  border-radius: 10px;
}
.richEditor-root .richEditor-editor .draftJsToolbar__toolbar__dNtBH input {
  width: 16rem;
  border: 1px solid #ddd;
  padding: 6px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgb(220, 220, 220);
}

.image-upload input[type=file] {
  display: none;
}
.image-upload .custom-image-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: #6c757d;
  opacity: 0.8;
}
.image-upload .custom-image-upload:hover {
  opacity: 1;
}

.video-link {
  display: flex;
  flex-direction: column;
}
.video-link .custom-video-link {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: #23b9bb;
  opacity: 0.8;
}
.video-link .custom-video-link:hover {
  opacity: 1;
}
.video-link .video-url-input {
  display: none;
  width: 18rem;
  border: 1px solid #ddd;
  padding: 9px;
  border-radius: 3px;
  box-shadow: 0 4px 10px 0 rgb(220, 220, 220);
  background: transparent;
  z-index: 1;
}
.video-link .video-url-input.open {
  display: inline;
}
.video-link .video-url-input.no-value input {
  border-color: red;
}
.video-link .video-url-input input {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.video-link .video-url-input span {
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
  color: #23b9bb;
  opacity: 0.8;
}
.video-link .video-url-input span:hover {
  opacity: 1;
}

