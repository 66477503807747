@import "../../../../../module";

.d3-line-chart {
	.domain {
		stroke-width: 1px;
		stroke: $border-grey;
	}

	.x-axis-bands {
		stroke: $border-grey;
		stroke-width: 1px;
	}

	.axis {
		.axis-label {
			font-size: 12px;
			fill: $label-dark-grey;
			font-weight: 600;
			font-family: $font-family-semi-bold;
		}
		@include SetMediaQuery(phone) {
			.tick {
				line {
					display: none;
				}

				&:nth-child(even) {
					text {
						font-size: 12px;
						fill: $label-grey;
					}
				}

				&:nth-child(odd) {
					text {
						font-size: 12px;
						fill: #fff;
					}
				}
			}
		}
		@include SetMediaQuery(tablet-portrait) {
			.tick {
				line {
					display: none;
				}

				text {
					fill: $label-grey;
					font-size: 12px;
					font-family: $font-family-semi-bold;
				}
			}
		}
	}

	.x-axis-bands {
		.x-axis-band {
			fill: transparent;
			stroke: transparent;
			cursor: pointer;
			transition: fill 0.5s ease;

			&:hover {
				fill: rgba($border-grey, 0.5);
			}

			&.current {
				fill: $border-grey;
			}

			&:last-child {
				&:hover {
					fill: transparent;
				}
			}
		}
	}

	.x-axis-band-dividers {
		.x-axis-band-divider {
			stroke: $border-grey;
			stroke-width: 1px;
		}
	}

	.deviation {
		cursor: pointer;
		transition: fill 0.5s ease;

		&.above {
			fill: rgba($sprig-baby-blue, 0.7);

			&.selected,
			&:hover {
				fill: rgba($sprig-baby-blue, 1);
			}
		}

		&.below {
			fill: rgba($sprig-orange, 0.7);

			&.selected,
			&:hover {
				fill: rgba($sprig-orange, 1);
			}
		}
	}

	.current-point {
		fill: $label-grey;
		stroke: $label-dark-grey;
		stroke-width: 2px;
	}

	.line {
		fill: none;
		stroke: $label-dark-grey;
		stroke-width: 5px;
	}
}
