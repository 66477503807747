.d3-line-chart .domain {
  stroke-width: 1px;
  stroke: #dee2e6;
}
.d3-line-chart .x-axis-bands {
  stroke: #dee2e6;
  stroke-width: 1px;
}
.d3-line-chart .axis .axis-label {
  font-size: 12px;
  fill: #6c757d;
  font-weight: 600;
  font-family: "nunitosemibold";
}
@media (max-width: 599px) {
  .d3-line-chart .axis .tick line {
    display: none;
  }
  .d3-line-chart .axis .tick:nth-child(even) text {
    font-size: 12px;
    fill: #979797;
  }
  .d3-line-chart .axis .tick:nth-child(odd) text {
    font-size: 12px;
    fill: #fff;
  }
}
@media (min-width: 600px) {
  .d3-line-chart .axis .tick line {
    display: none;
  }
  .d3-line-chart .axis .tick text {
    fill: #979797;
    font-size: 12px;
    font-family: "nunitosemibold";
  }
}
.d3-line-chart .x-axis-bands .x-axis-band {
  fill: transparent;
  stroke: transparent;
  cursor: pointer;
  transition: fill 0.5s ease;
}
.d3-line-chart .x-axis-bands .x-axis-band:hover {
  fill: rgba(222, 226, 230, 0.5);
}
.d3-line-chart .x-axis-bands .x-axis-band.current {
  fill: #dee2e6;
}
.d3-line-chart .x-axis-bands .x-axis-band:last-child:hover {
  fill: transparent;
}
.d3-line-chart .x-axis-band-dividers .x-axis-band-divider {
  stroke: #dee2e6;
  stroke-width: 1px;
}
.d3-line-chart .deviation {
  cursor: pointer;
  transition: fill 0.5s ease;
}
.d3-line-chart .deviation.above {
  fill: rgba(79, 199, 201, 0.7);
}
.d3-line-chart .deviation.above.selected, .d3-line-chart .deviation.above:hover {
  fill: #4fc7c9;
}
.d3-line-chart .deviation.below {
  fill: rgba(255, 143, 26, 0.7);
}
.d3-line-chart .deviation.below.selected, .d3-line-chart .deviation.below:hover {
  fill: #ff8f1a;
}
.d3-line-chart .current-point {
  fill: #979797;
  stroke: #6c757d;
  stroke-width: 2px;
}
.d3-line-chart .line {
  fill: none;
  stroke: #6c757d;
  stroke-width: 5px;
}

