.menu-hamburger {
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
}
.menu-hamburger .menu-hamburger-line {
  position: absolute;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  transition: all 0.2s ease;
}
.menu-hamburger .menu-hamburger-line:first-child {
  top: 0;
}
.menu-hamburger .menu-hamburger-line:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.menu-hamburger .menu-hamburger-line:last-child {
  bottom: 0;
}
.menu-hamburger.open .menu-hamburger-line:first-child {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}
.menu-hamburger.open .menu-hamburger-line:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%) rotate(-45deg);
}
.menu-hamburger.open .menu-hamburger-line:last-child {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

