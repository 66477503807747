@import "../../../../module";

.flyout-navigation-component {
	height: 100vh;
	width: 100%;
	position: fixed;
	left: -120%;
	top: 56px;
	background-color: white;
	border-right: 1px solid $border-grey;
	padding: 0;
	transition: left 0.5s ease;

	&.open {
		left: 0;
	}

	.flyout-navigation-header {
		width: 100%;
		height: 13%;
		padding-left: 40px;
		border-bottom: 1px solid $border-grey;
		@include Flexbox(row, center);

		svg,
		h3 {
			color: $label-dark-grey;
			margin: 0 0 0 5px;
		}

		svg {
			width: 30px;
			height: 30px;
		}

		.flyout-link {
			margin-right: 5px;

			svg {
				height: 20px;
				width: 20px;
			}
		}

		&.go-back-link {
			padding-left: 0;
		}
	}

	.flyout-navigation-items {
		list-style: none;
		padding: 20px 0;

		.flyout-navigation-item {
			padding: 10px 0;

			span {
				font-size: 1.2rem;
			}

			a {
				position: relative;
				color: $label-dark-grey;
				text-decoration: none;
				padding: 0 10px 0 40px;
				@include Flexbox(row, center);

				&:hover {
					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 5px;
						background-color: $label-dark-grey;
					}
				}

				svg {
					width: 16px;
					height: 16px;
					margin-right: 5px;
					fill: $label-dark-grey;
				}
			}

			.active,
			.active:hover {
				position: relative;
				.admin-portal & {
					color: $primary-admin;
				}
				.educator-portal & {
					color: $primary-educator;
				}
				.principal-portal & {
					color: $primary-educator;
				}
				.director-portal & {
					color: $primary-educator;
				}

				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 5px;
					.admin-portal & {
						background-color: $primary-admin;
					}
					.educator-portal & {
						background-color: $primary-educator;
					}
					.principal-portal & {
						background-color: $primary-educator;
					}
					.director-portal & {
						background-color: $primary-educator;
					}
				}

				svg {
					.admin-portal & {
						fill: $primary-admin;
					}
					.educator-portal & {
						fill: $primary-educator;
					}
					.principal-portal & {
						fill: $primary-educator;
					}
					.director-portal & {
						fill: $primary-educator;
					}
				}
			}
		}
	}
}

.admin-portal {
	.flyout-navigation-component {
		.flyout-navigation-item {
			.active,
			.active:hover {
				color: $primary-admin;

				&::after {
					background-color: $primary-admin;
				}
			}
		}
	}
}

.educator-portal,
.principal-portal {
	.flyout-navigation-component {
		.flyout-navigation-item {
			.active,
			.active:hover {
				color: $primary-educator;

				&::after {
					background-color: $primary-educator;
				}
			}
		}
	}
}
