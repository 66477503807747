@import "../../../../module";

.badge-collection {
	padding: 20px;
	padding-top: 0;

	.badge-category {
		padding-bottom: 10px;

		.badge-category-title {
			padding-top: 20px;
		}

		.badge-category-content {
			@include Flexbox(row);

			flex-wrap: wrap;
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}
