@import "../../module";

.loader-spinner-component {
	height: 100px;
	width: 100px;
	position: relative;
	@include Flexbox(row, center, center);

	.loader-circle {
		height: 100%;
		width: 100%;
		border: 5px solid $loader-white;
		border-top: 5px solid $loader-green;
		border-radius: 50%;
		animation: spin 1.2s ease 0s infinite;
	}

	.loader-img {
		padding: 25px;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

@keyframes spin {
	0% { transform: rotate(0); }
	70% { transform: rotate(80deg); }
	100% { transform: rotate(360deg); }
}
