.form-component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.form-component .form-content {
  max-height: calc(100vh - 20px);
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.form-component .form-content .form-header .form-header-logo {
  padding: 30px 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.form-component .form-content .form-body {
  max-height: calc(100% - 125px);
  overflow: auto;
}
.form-component .form-content .form-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.form-component .form-content .form-checkbox .checkbox-container {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  border: 3px solid #dee2e6;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.form-component .form-content .form-checkbox .checkbox-container svg {
  height: 80%;
  width: 80%;
  opacity: 0.2;
  transition: opacity 0.5s ease;
}
.form-component .form-content .form-checkbox .checkbox-container svg path {
  fill: #199b32;
}
.form-component .form-content .form-checkbox .checkbox-container.checked svg {
  opacity: 1;
}
.form-component .form-content .form-checkbox strong {
  width: calc(100% - 40px);
}
.form-component .form-content .form-group {
  width: 100%;
}
.form-component .form-content .form-group .eye-icon {
  float: right;
  margin-top: -35px;
  margin-right: 10px;
  position: relative;
  z-index: 2;
  color: #6c757d;
  width: 25px;
}
.form-component .form-content .form-dropdown {
  width: 100%;
  position: relative;
}
.form-component .form-content .form-dropdown .form-dropdown-input {
  position: relative;
  height: 40px;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 0 30px 0 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.form-component .form-content .form-dropdown .form-dropdown-input .form-dropdown-input-text {
  display: inline-block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.form-component .form-content .form-dropdown .form-dropdown-input.no-arrow {
  cursor: default;
}
.form-component .form-content .form-dropdown .form-dropdown-input.arrow::after {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 2px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}
.form-component .form-content .form-dropdown .form-dropdown-input:focus {
  outline: none;
}
.form-component .form-content .form-dropdown .form-dropdown-menu {
  position: absolute;
  z-index: 9999;
  top: 100%;
  width: 100%;
  left: 0;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-height: 100px;
  overflow: auto;
  background-color: white;
  padding: 0;
}
.form-component .form-content .form-dropdown .form-dropdown-menu .form-dropdown-menu-item {
  height: 40px;
  border-bottom: 1px solid #ced4da;
  padding: 0 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.form-component .form-content .form-dropdown .form-dropdown-menu .form-dropdown-menu-item:last-child {
  border-bottom: none;
}
.form-component .form-content .form-dropdown.open .form-dropdown-input.arrow::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: none;
  border-bottom: 5px solid #000;
}

.form-group .eye-icon-password-setting {
  float: right;
  margin-top: 12px;
  margin-left: -15px;
  position: relative;
  z-index: 3;
  color: #6c757d;
  left: -26px;
  width: 25px;
}
.form-group #form-group-information-tooltip {
  max-width: 300px;
}
.form-group.valid input:focus {
  border-color: #199b32;
  box-shadow: 0 0 0 0.2rem rgba(25, 155, 50, 0.25);
}
.form-group.invalid input {
  border-color: #d9534f;
  box-shadow: 0 0 1px 0.2rem rgba(217, 83, 79, 0.25);
}

.form-rating .rating-label {
  margin-bottom: 10px;
}
.form-rating .rating-items {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.form-rating .rating-items .rating-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.form-rating .rating-items .rating-item .rating-icon {
  cursor: pointer;
}
.form-rating .rating-items .rating-item .rating-icon svg {
  height: 50px;
  width: 50px;
}
.form-rating .rating-items .rating-item .rating-icon svg path {
  transition: fill 0.5s ease;
  fill: #dee2e6;
}
.form-rating .rating-items .rating-item .rating-icon.selected svg path {
  fill: #199b32;
}
.form-rating .rating-items .rating-item:hover .rating-icon svg path, .form-rating .rating-items .rating-item:hover ~ .rating-item .rating-icon svg path {
  fill: #199b32;
}

