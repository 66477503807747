$brand-primary: yellow !default; // #337ab7
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$badge-background: #ff8f1a;

$light-silver: #d9d9d9;
$light-grey-background: #f8f9fa;
$irish-green: #148a2b;
$irish-green-light: #199b32;
$valentine-red: #e55451;
$water-blue: #0280c8;
$steel-grey: #7a828a;
$topaz: #17c8bb;
$eastern-blue: #27a3a5;
$gamboge: #ed9408;
$jasper: #dc3545;
$american-green: #39bf43;
$viridian-green: #009d9e;
$north-texas-green: #00962b;
$la-salle-green: #006e28;

$primary-educator: #23b9bb;
$secondary-educator: #10a8aa;
$primary-caregiver: $irish-green-light;
$secondary-caregiver: #036627;
$primary-admin: #8f3d6b;
$secondary-admin: #682e52;

$leaf-green-bold: #199b32;
$leaf-green-semi-bold: #39be42;
$leaf-green-medium: #69d746;
$leaf-green-base: #8ce655;
$leaf-green-light: #aaf06e;
$leaf-green-darkest: #007822;
$leaf-green-lightest: #bef046;

$leaf-green-bold-hover: #47af5b;
$leaf-green-semi-bold-hover: #60cb67;
$leaf-green-medium-hover: #87e96b;
$leaf-green-base-hover: #9de373;
$leaf-green-light-hover: #bbf38b;
$leaf-green-darkest-hover: #47af5b;
$leaf-green-lightest-hover: #9de373;

$default-label-black: #1e1c1a;
$rich-black: #030303;
$form-border-grey: #ced4da;
$label-grey: #979797;
$label-dark-grey: #6c757d;
$border-grey: #dee2e6;
$gainsboro: #dadddf;
$lotion: #fafafa;
$alice-blue: #f4f8fc;

$reading-mastered: #388437;
$reading-more-practice: #cca306;
$reading-need-instruction: #de4242;
$reading-not-assessed: #535353;

// Button Defaults
$button-darkgray: #5a6268;
$button-gray: $label-dark-grey;
$button-lightgray: $border-grey;

$default-text-color: #1e1c1a;
$badge-text-color: #5ca54d;

$sprig-orange: #ff8f1a;
$sprig-baby-blue: #4fc7c9;

$grid-columns: 12;
$grid-gutter-width: 15px;

$loader-green: #39be42;
$loader-white: #f9f9f9;

$outcome-color-reading-viewing: #39be42;
$outcome-color-listening-speaking: #199b32;
$outcome-color-geometry: #199b32;
$outcome-color-measurement: #39be42;
$outcome-color-number-sense: #69d746;
$outcome-color-patterns-relations: #8ce655;

// Global heights
$PrimaryNavigationHeight: 56px;
$SecondaryNavigationHeight: 75px;
$MobileNavigationHeight: 64px;
$primaryNavigationHeight_numerical: 56;
$secondaryNavigationHeight_numerical: 75;
$caregiverMobileSecondaryNavigationHeight: 64px;
$educator-navbar-main-height: 56px;
$educator-navbar-tertiary-height: 75px;
$toastNotificationHeight: 70px;

// font styles
$font-family-semi-bold: "nunitosemibold";
$font-family-bold: "nunitobold";

$circle-chart-color-code-dec-jan: #0280c8;
$circle-chart-color-code-feb-mar: #01962c;
$circle-chart-color-code-apr-jun: #ffa02f;
$circle-chart-color-code-sep-nov: #c6548c;
$circle-chart-color-code-previous: #0b0b0b;

$circle-chart-high-light-cornflower: #97d7f2;
$circle-chart-high-light-teal-deer: #96dcab;
$circle-chart-high-light-mauvelous: #ed97ab;
$circle-chart-high-light-jasmine: #f5dd79;

$operation: #ffa02f;
$measurement: #be46b9;
$numbers: #8bd321;
$geometry: #46d2ff;
