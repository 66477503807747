@import "../../../../Stylesheets/scss/module.scss";

.mobile-search-bar {
	opacity: 0;
	height: 0;
	transition: height 0.3s ease;
	position: absolute;
	top: 0;
	z-index: 9998;

	.primary-nav-search-activity {
		height: 0;
		transition: height 0.5s ease;
	}

	&.mobile-search-bar--show {
		@include SetMediaQuery(bootstrap-sm) {
			opacity: 1;
			height: 56px;
			width: 100%;

			.caregiver-portal & {
				background: $primary-caregiver;
			}

			.educator-portal & {
				background: $primary-educator;
			}
			.reading-portal & {
				background: $primary-educator;
			}
			@include Flexbox(row, center, center);

			.primary-nav-search-activity {
				align-self: flex-start;
				height: 43px;
			}
		}
	}
}
