@import "../../../Stylesheets/scss/module";

.portal-tour-step {
	height: 100%;
	position: relative;

	.portal-tour-step-component {
		opacity: 0;
		pointer-events: none;
		transition: opacity 500ms ease;
	}

	&.active {
		.portal-tour-step-component {
			opacity: 1;
			pointer-events: all;
		}
	}
	.portal-tour-content {
		height: 100%;
	}
}
