@import "../../../../Stylesheets/scss/module";

.activity-card-header {
	min-height: 140px;
	background: var(--backgroundColor, $irish-green-light);
	position: relative;
	border-top-left-radius: 22px;
	border-top-right-radius: 22px;

	.topic-logo {
		padding-left: 15px;
		padding-top: 15px;
	}

	.activity-title {
		color: #fff;
		position: absolute;
		font-size: 17px;
		bottom: 10px;
		left: 20px;
	}

	.save-icon {
		background: #fff;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		position: absolute;
		right: 20px;
		bottom: -20px;
		text-align: center;
		transition: background 0.5s ease-in-out 0.2s, bottom 0.5s ease-in-out;

		&:hover {
			cursor: pointer;
		}

		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			@include Flexbox(row, center, center);
			.spinner-icon {
				color: $label-dark-grey;
			}
		}

		&.saved {
			.heart-icon {
				color: var(--backgroundColor, $irish-green-light);
			}
		}

		&.un-saved {
			.heart-icon {
				stroke: $label-dark-grey;
				stroke-width: 50;
				border-color: $label-dark-grey;
				color: #fff;
			}
		}
	}

	.activity-completion {
		height: 20px;
		color: #fff;
		right: 23px;
		position: absolute;
		top: 22px;
		width: 20px;

		.activity-completions-count {
			width: 100%;
			height: 100%;
			background-color: #ff8f1a;
			border-radius: 20px;
			border: 2px solid #fff;
			text-align: center;
			display: inline-block;
			transition: border 0.5s ease-in-out 0.2s;

			small {
				top: -3px;
				position: relative;
			}
		}

		.activity-no-completion {
			svg {
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, 0.9);
				border-radius: 20px;
			}
		}
	}
}

.activity-card-body {
	height: 190px;
	padding-left: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom-left-radius: 22px;
	border-bottom-right-radius: 22px;
	border: 1px solid #dee2e6;
	border-top: none;

	.activity-info {
		height: auto;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 13px;

		.info-block {
			$flex-basis: 24% 44% 32%;
			@for $i from 1 through 3 {
				&:nth-of-type(#{$i}) {
					flex: 1 1 nth($flex-basis, $i);
					max-width: nth($flex-basis, $i);
				}
			}

			&--title {
				color: $label-dark-grey;
				opacity: 0.9;
				font-size: 13px;
				@include SetMediaQuery(extra-small-phone) {
					font-size: 0.65rem;
				}
			}

			&--content {
				font-size: 13px;
				@include SetMediaQuery(extra-small-phone) {
					font-size: 0.65rem;
				}
			}
		}
		&.on-reading-card {
			.info-block {
				$flex-basis: 44% 56%;
				@for $i from 1 through 2 {
					&:nth-of-type(#{$i}) {
						flex: 1 1 nth($flex-basis, $i);
						max-width: nth($flex-basis, $i);
					}
				}
			}
		}
	}

	.activity-card-content {
		@include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3);

		margin-right: 20px;
		padding-bottom: 20px;
	}
}
