@import "../../../Stylesheets/scss/module";

.icon {
	svg {
		.caregiver-portal & {
			color: $primary-caregiver;
		}

		.educator-portal & {
			color: $primary-educator;
		}

		.reading-portal & {
			color: $primary-educator;
		}

		.admin-portal & {
			color: $primary-admin;
		}
	}

	.sprig-icon {
		margin: 0;
		padding: 0;

		svg {
			height: 100%;
			width: 100%;
			vertical-align: top;

			.caregiver-portal & {
				fill: $primary-caregiver;
			}

			.educator-portal & {
				fill: $primary-educator;
			}

			.reading-portal & {
				fill: $primary-educator;
			}

			.principal-portal & {
				fill: $primary-educator;
			}

			.educator-portal-modal & {
				fill: $primary-educator;
			}
		}

		&--xs {
			height: 15px;
			width: 15px;
		}

		&--sm {
			height: 20px;
			width: 20px;
		}

		&--lg {
			height: 25px;
			width: 25px;
		}

		&--1x {
			height: 10px;
			width: 10px;
		}

		&--2x {
			height: 20px;
			width: 20px;
		}

		&--3x {
			height: 30px;
			width: 30px;
		}

		&--4x {
			height: 40px;
			width: 40px;
		}

		// Customized Icons
		#activities-icon {
			.cls-1 {
				fill: none;
			}
			.cls-2 {
				clip-path: url(#clip-path-activities-icon);
			}
		}
	}
}
