@import "../../../../Stylesheets/scss/module.scss";

.reading-toast {
	@include Flexbox(row, center);
	width: 309px;
	min-height: 49px;
	border-radius: 8px;
	color: #fff;
	padding: 15px 14px 15px 22px;
	font-size: 13px;
	margin-bottom: 10px;

	&.toast-fade {
		animation: fadeInOut 4s linear forwards;
	}

	@keyframes fadeInOut {
		0%,
		100% {
			opacity: 0;
		}
		12.5%,
		87.5% {
			opacity: 1;
		}
	}

	&.reading-toast-add-classroom,
	&.reading-toast-invite-teacher,
	&.reading-toast-remove-teacher,
	&.reading-toast-add-student,
	&.reading-toast-remove-student {
		background-color: $primary-educator;
		.sprig-icon {
			fill: #fff;
		}
	}

	&.reading-toast-assessing-mastered,
	&.reading-toast-assessing-needs-practice,
	&.reading-toast-assessing-needs-instruction,
	&.reading-toast-assessing-not-yet-to-be-assessed {
		background-color: var(--backgroundColor);
		svg {
			&.mastered {
				fill: #fff;
			}

			&.needs-more {
				fill: none;

				.half-circle {
					fill: #fff;
				}

				.stroke {
					stroke: #fff;
				}
			}

			&.need-explicit {
				stroke: #fff;
				fill: var(--backgroundColor);
			}

			&.not-yet-assessed {
				opacity: 60%;
				circle {
					fill: #fff !important;
				}
			}
		}
	}

	.toast-message {
		flex: 100%;
		padding-left: 6px;
		line-height: 1.2;
	}

	.toast-close-button {
		flex: 1;
		&:hover {
			cursor: pointer;
		}
		.icon {
			opacity: 80%;
		}
	}
}
