.question-visual {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: auto;
  grid-row-gap: auto;
}
.question-visual .question-visual-group {
  background-color: #199b32;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  cursor: default;
}
.question-visual .question-visual-group .question-visual-group-text {
  width: 100%;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 20px 0;
}
.question-visual .question-visual-assets {
  grid-auto-flow: row;
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .question-visual .question-visual-assets {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 10px;
  }
}
@media (max-width: 767.98px) {
  .question-visual .question-visual-assets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 10px;
  }
}
.question-visual .question-visual-assets .question-visual-asset {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.question-visual .question-visual-assets .question-visual-asset .question-visual-img {
  position: relative;
}
.question-visual .question-visual-assets .question-visual-asset .question-visual-img .question-visual-check {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.question-visual .question-visual-assets .question-visual-asset .question-visual-img object {
  max-height: 150px;
  max-width: 120px;
}

