@import "../../../Stylesheets/scss/module";

.input-radio {
	display: none;

	& + label {
		border: 1px solid $border-grey;
		height: 25px;
		width: 25px;
		flex-shrink: 0;
		position: relative;
		border-radius: 50%;
		cursor: pointer;
	}

	&:checked + label {
		&::after {
			content: "";
			border-radius: 50%;
			height: 17px;
			width: 17px;
			position: absolute;
			top: 3px;
			left: 3px;
			transform-origin: center center;

			.caregiver-portal & {
				background: $primary-caregiver;
			}

			.educator-portal & {
				background: $primary-educator;
			}

			.reading-portal & {
				background: $primary-educator;
			}
		}
	}

	&:disabled + label {
		cursor: not-allowed;
	}
}
