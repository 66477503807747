.circle-badge {
	height: 30px;
	width: 30px;
	line-height: 30px;
	text-align: center;
	border-radius: 30px;
	background: #1acac0;
	color: white;
	margin-left: auto;
	margin-right: auto;
}

.no-event {
	pointer-events: none;
}

.exit-btn {
	position: relative;
	z-index: 9999;

	&::before {
		content: "this is a button";
		padding: 15px;
		border-radius: 50px;
		position: absolute;
		background-color: #036627;
		display: block;
	}
}

.modal {
	z-index: 3003;
}

.modal-dialog {
	z-index: 3003;
}

.modal-lg {
	z-index: 3003;
}

.adm-text-primary {
	color: #8f3d6b !important;
}

.mc {
	top: 292px;
	position: relative;
}

.mc-2 {
	margin-top: 56px;
}

.ws-nowrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fixed-top-secondary {
	position: fixed;
	top: 54px;
	right: 0;
	left: 0;
	z-index: 1000;
}

.d3-tip {
	z-index: 99999;
}

.activity-card {
	height: 100%;
}

strong {
	font-family: $font-family-bold;
	font-weight: 400;
}
