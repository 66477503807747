@import "../../../../module";

$NotificationMenuHeaderHeight: 40px;
$NotificationMenuFooterHeight: 20px;

.notification-tab {
	position: relative;
	z-index: 3000;

	button {
		img {
			width: 20px;
		}
	}

	.notification-tab-menu {
		position: absolute;
		right: 0;
		top: 100%;
		background-color: white;
		border-radius: 1rem;
		height: 400px;
		max-height: calc(100vh - #{$PrimaryNavigationHeight});
		width: 400px;
		max-width: 75vw;
		border: 1px solid $border-grey;

		.notification-tab-menu-header {
			height: $NotificationMenuHeaderHeight;
			border-bottom: 1px solid $border-grey;
			@include Flexbox(row, center, center);
		}

		.notification-tab-menu-body {
			height:
				calc(
					100% - #{$NotificationMenuHeaderHeight} - #{$NotificationMenuFooterHeight}
				);
			overflow: auto;
		}

		.notification-tab-menu-footer {
			height: $NotificationMenuFooterHeight;
			border-top: 1px solid $border-grey;
		}
	}
}
