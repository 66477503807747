@import "../../../../../module";

.question-visual {
	@include Grid(grid, 1fr, 25px 1fr);

	.question-visual-group {
		background-color: $leaf-green-bold;
		transform: rotate(180deg);
		writing-mode: vertical-lr;
		@include Flexbox(row, center, center);

		cursor: default;

		.question-visual-group-text {
			width: 100%;
			color: white;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 20px 0;
		}
	}

	.question-visual-assets {
		grid-auto-flow: row;
		padding: 20px 0;
		@include Grid(grid, 1fr, repeat(4, 1fr), 10px, 5px);
		@include SetMediaQuery(bootstrap-md) {
			@include Grid(grid, 1fr, repeat(3, 1fr), 10px, 5px);
		}
		@include SetMediaQuery(bootstrap-sm) {
			@include Grid(grid, 1fr, repeat(2, 1fr), 10px, 5px);
		}

		.question-visual-asset {
			width: 100%;
			height: 100%;
			padding: 0 10px;
			@include Flexbox(column, center, flex-end);

			.question-visual-img {
				position: relative;

				.question-visual-check {
					position: absolute;
					right: 0;
					top: 0;
					background-color: white;
					border-radius: 50%;
					height: 25px;
					width: 25px;
				}

				object {
					max-height: 150px;
					max-width: 120px;
				}
			}
		}
	}
}
