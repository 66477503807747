@import "../../module";

.table-component {
	table-layout: fixed;

	thead {
		th {
			& > * {
				@include ellipsis(100%, block);
			}
			@include TableSortable();
		}
	}
}
