.assessment-question-table {
  width: 100%;
}
.assessment-question-table .assessment-question-table-headers {
  display: grid;
  grid-template-columns: 25px 40% auto;
  grid-auto-flow: column;
  width: 100%;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
}
.assessment-question-table .assessment-question-table-headers .assessment-question-table-header-options {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assessment-question-table .assessment-question-table-headers .assessment-question-table-header-options .assessment-question-table-header {
  width: 100%;
}
.assessment-question-table .assessment-question-table-rows .assessment-question-table-grouping {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.assessment-question-table .assessment-question-table-rows .assessment-question-table-grouping .assessment-question-table-grouping-label {
  width: 25px;
  background-color: #199b32;
}
.assessment-question-table .assessment-question-table-rows .assessment-question-table-grouping .assessment-question-table-grouping-label .assessment-question-rotated-label {
  transform: rotate(-90deg);
  height: 100%;
  color: white;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assessment-question-table .assessment-question-table-rows .assessment-question-table-grouping .assessment-question-table-grouping-question {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}
.assessment-question-table .assessment-question-table-rows .assessment-question-table-grouping:nth-child(2) .assessment-question-table-grouping-label {
  background-color: #39be42;
}
.assessment-question-table .assessment-question-table-rows .assessment-question-table-grouping:nth-child(3) .assessment-question-table-grouping-label {
  background-color: #69d746;
}

