@import "../../../../../module";

.modal-page-navigatior-menu {
	position: relative;

	.modal-page-navigator-menu-icon {
		height: 22px;
		width: 22px;
		cursor: pointer;

		svg {
			height: 100%;
			width: 100%;

			path {
				fill: $label-dark-grey;
			}

			&:hover {
				path {
					fill: $default-text-color;
				}
			}
		}
	}

	.modal-page-navigator-menu-dropdown {
		position: absolute;
		top: 100%;
		right: 0;
		left: auto;
		z-index: 3001;
		max-height: 200px;
		max-width: 224px;
		overflow: auto;
		@include Flexbox(column);

		.modal-page-navigator-menu-dropdown-item {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			min-height: 40px;
			font-size: 80%;
		}
	}
}
