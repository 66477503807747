@import "../../module";
$form-header-h: 125px;
$form-input-height: 40px;

.form-component {
	height: 100%;
	width: 100%;
	@include Flexbox(row, center, center);

	.form-content {
		max-height: calc(100vh - 20px);
		height: 100%;
		width: 100%;
		padding: 10px;
		@include Flexbox(column, center, center);

		.form-header {
			.form-header-logo {
				padding: 30px 0;
				height: auto;
				width: 100%;
				@include Flexbox(column, center, center);
			}
		}

		.form-body {
			max-height: calc(100% - #{$form-header-h});
			overflow: auto;
		}

		.form-checkbox {
			@include Flexbox(row, center);

			.checkbox-container {
				width: 30px;
				height: 30px;
				margin-right: 10px;
				cursor: pointer;
				border: 3px solid $border-grey;
				border-radius: 0.25rem;
				@include Flexbox(row, center, center);

				svg {
					height: 80%;
					width: 80%;
					opacity: 0.2;
					transition: opacity 0.5s ease;

					path {
						fill: $primary-caregiver;
					}
				}

				&.checked {
					svg {
						opacity: 1;
					}
				}
			}

			strong {
				width: calc(100% - 40px);
			}
		}

		.form-group {
			width: 100%;

			.eye-icon {
				float: right;
				margin-top: -35px;
				margin-right: 10px;
				position: relative;
				z-index: 2;
				color: $label-dark-grey;
				width: 25px;
			}
		}

		.form-dropdown {
			width: 100%;
			position: relative;

			.form-dropdown-input {
				position: relative;
				height: $form-input-height;
				width: 100%;
				border: 1px solid $border-grey;
				border-radius: 5px;
				padding: 0 30px 0 10px;
				cursor: pointer;
				@include Flexbox(row, center);

				.form-dropdown-input-text {
					@include ellipsis(auto);
				}

				&.no-arrow {
					cursor: default;
				}

				&.arrow {
					&::after {
						content: "";
						position: absolute;
						right: 10px;
						top: calc(50% - 2px);
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 5px solid #000;
					}
				}

				&:focus {
					outline: none;
				}
			}

			.form-dropdown-menu {
				position: absolute;
				z-index: 9999;
				top: 100%;
				width: 100%;
				left: 0;
				border: 1px solid $border-grey;
				border-radius: 5px;
				max-height: 100px;
				overflow: auto;
				background-color: white;
				padding: 0;

				.form-dropdown-menu-item {
					height: $form-input-height;
					border-bottom: 1px solid $form-border-grey;
					padding: 0 10px;
					cursor: pointer;
					@include ellipsis();
					@include Flexbox(row, center);

					&:last-child {
						border-bottom: none;
					}
				}
			}

			&.open {
				.form-dropdown-input {
					&.arrow {
						&::after {
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-top: none;
							border-bottom: 5px solid #000;
						}
					}
				}
			}
		}
	}
}

.form-group {
	.eye-icon-password-setting {
		float: right;
		margin-top: 12px;
		margin-left: -15px;
		position: relative;
		z-index: 3;
		color: $label-dark-grey;
		left: -26px;
		width: 25px;
	}

	#form-group-information-tooltip {
		max-width: 300px;
	}

	&.valid {
		input {
			&:focus {
				border-color: $primary-caregiver;
				box-shadow: 0 0 0 0.2rem rgba($primary-caregiver, 0.25);
			}
		}
	}

	&.invalid {
		input {
			border-color: $brand-danger;
			box-shadow: 0 0 1px 0.2rem rgba($brand-danger, 0.25);
		}
	}
}

.form-rating {
	.rating-label {
		margin-bottom: 10px;
	}

	.rating-items {
		@include Flexbox(row-reverse, flex-start, space-between);

		.rating-item {
			@include Flexbox(column, center, center);

			.rating-icon {
				cursor: pointer;

				svg {
					height: 50px;
					width: 50px;

					path {
						transition: fill 0.5s ease;
						fill: $border-grey;
					}
				}

				&.selected {
					svg {
						path {
							fill: $primary-caregiver;
						}
					}
				}
			}

			.rating-label {
			}

			&:hover,
			&:hover ~ .rating-item {
				.rating-icon {
					svg {
						path {
							fill: $primary-caregiver;
						}
					}
				}
			}
		}
	}
}
