@import "../../../../../module";

.survey-end {
	padding: 50px 15% 40px 15%;

	.survey-end-content {
		@include Flexbox(column, center, center);

		.survey-end-text {
			@include Flexbox(column, center, center);

			.survey-split-content {
				text-align: center;
				padding: 10px 0;
			}
		}

		.survey-submit-error {
			color: $brand-danger;
		}

		.btn {
			margin-top: 20px;
		}
	}
}
