@import "../module";

.bg-none {
	background: none !important;
}

// DEFAULT
.bg-primary {
	background-color: $primary-caregiver !important;
}

// ADMIN
.adm-bg-primary {
	background-color: $primary-admin !important;
}

.adm-bg-secondary {
	background-color: $secondary-admin !important;
}

.adm-bg-20 {
	background-color: #edd7e2 !important;
}

.adm-bg-80 {
	background-color: #a5648b !important;
}

// ADMIN
.edu-bg-primary {
	background-color: $primary-educator !important;
}

.edu-bg-secondary {
	background-color: $secondary-educator !important;
}
