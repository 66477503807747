@import "../../../Stylesheets/scss/module";

.navigation-item-list {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	@include Flexbox(row);

	.navigation-item {
		margin-right: 20px;
		text-decoration: none;
		@include Flexbox(row, center, center);

		a {
			color: white;
			font-size: 15px;
			font-family: $font-family-bold;
			@include Flexbox(row, center, center);

			opacity: 0.9;
			cursor: pointer;
			text-decoration: none;

			.icon {
				margin: 0;
				padding: 0;
				margin-right: 5px;
				font-size: 150%;
				svg {
					color: white;
				}
			}

			&.active,
			&:hover,
			&:active {
				opacity: 1;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}
