@import "../../../../module";

.component-expandable-search {
	cursor: pointer;
	border-radius: 0.5rem;
	height: 40px;
	overflow: hidden;

	.search-button {
		&:hover {
			background-color: $border-grey;
		}

		svg {
			margin-right: 0 !important;
			color: $label-dark-grey;
		}
	}

	.close-button {
		padding: 0 5px;
		cursor: pointer;
	}

	input {
		background-color: transparent;
		border: none;
		transition: max-width 0.5s ease;
		height: 26px;
		width: 100%;
		padding: 0;
		max-width: 0;
	}

	&.open {
		cursor: default;

		.search-button {
			cursor: pointer;
		}

		input {
			max-width: 180px;
		}
	}
}
