@import "../../../Stylesheets/scss/module";

.portal-tour-tooltip {
	position: fixed;
	top: var(--tooltipTop);
	left: calc(100% + 7px);
	background-color: white;
	border: 1px solid $border-grey;
	border-radius: 10px;
	color: $default-text-color;
	z-index: 99999;

	&::after {
		position: absolute;
		content: "";
		left: -10px;
		top: calc(var(--arrowTop) - var(--tooltipTop) + 10px);
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid white;
		filter: drop-shadow(-1px 0 0 $border-grey);
	}

	@include SetMediaQuery(bootstrap-sm-md) {
		$mobile-tooltip-height: 250px;
		top: calc(100% - #{$mobile-tooltip-height});
		left: 0;
		border-top: 1px solid $border-grey;
		border-left: none;
		border-right: none;
		border-bottom: none;
		border-radius: 0;
		height: $mobile-tooltip-height;
		width: 100%;
	}
}
