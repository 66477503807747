@import "../../../../Stylesheets/scss/module.scss";
.page-description {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px 0 0;
	width: 331px;
	height: 100%;

	&-header {
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;
		width: 278px;
		margin-left: auto;
		margin-right: auto;

		@include SetMediaQuery(bootstrap-sm-md) {
			padding-left: 35px;
			padding-right: 35px;
			width: 100%;
		}

		.icon {
			width: 30px;
		}

		.title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 13px;
			font-size: 17px;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.41px;
			color: $default-text-color;
		}
	}

	&-content {
		width: 278px;
		margin-top: 5px;
		margin-left: auto;
		margin-right: auto;
		font-size: 13px;
		line-height: 1.85;
		letter-spacing: -0.31px;
		color: $default-text-color;
		font-family: $font-family-semi-bold;
		@include SetMediaQuery(bootstrap-sm-md) {
			padding: 10px 35px;
			width: 100%;
		}
	}

	&-buttons {
		margin: 30px auto 15px auto;
		width: 278px;
		height: 38px;
		@include Flexbox(row, center, flex-end);

		.learn-more,
		.continue,
		.got-it {
			height: 100%;
			flex: 1;
			display: flex;
			justify-content: center;
			a.no-link-decoration {
				display: flex;
				justify-content: center;
				flex-direction: column;
				text-align: center;
			}
		}

		.learn-more {
			a {
				color: $steel-grey !important;
				border: none;

				&:hover {
					color: black !important;
					background-color: #fff;
				}
				&:focus {
					box-shadow: none;
				}
			}
		}

		.continue {
			border-radius: 10px;
			background-color: $primary-educator;
		}

		.got-it {
			button {
				@include Flexbox(row, center);
				height: 100%;
				width: 100%;

				.btn--content {
					width: 100%;
					justify-content: center;
				}
			}
		}

		@include SetMediaQuery(bootstrap-sm-md) {
			margin-top: 5px;
			.no-link-decoration {
				color: #fff;
			}
		}
	}

	@include SetMediaQuery(bootstrap-sm-md) {
		justify-content: space-between;
		width: 100%;
	}
}
