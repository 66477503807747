.sticker-badge {
	&.rabbit,
	&.goose,
	&.butterfly,
	&.chickadee,
	&.leafpile,
	&.squirrel,
	&.sun {
		.cls-1,
		.cls-44,
		.cls-45,
		.cls-51,
		.cls-52,
		.cls-67 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #f5f0f0;
		}

		.cls-6 {
			clip-path: url(#clip-path);
		}

		.cls-7 {
			fill: #bba290;
		}

		.cls-50,
		.cls-53,
		.cls-8 {
			fill: #3b292d;
		}

		.cls-8 {
			opacity: 0.1;
		}

		.cls-19,
		.cls-21,
		.cls-33,
		.cls-39,
		.cls-43,
		.cls-46,
		.cls-50,
		.cls-53,
		.cls-55,
		.cls-8 {
			mix-blend-mode: multiply;
		}

		.cls-9 {
			clip-path: url(#clip-path-2);
		}

		.cls-10 {
			fill: #d1c1b5;
		}

		.cls-11 {
			fill: #a4836b;
		}

		.cls-12 {
			clip-path: url(#clip-path-3);
		}

		.cls-13,
		.cls-60,
		.cls-65 {
			fill: #fff;
		}

		.cls-14 {
			clip-path: url(#clip-path-4);
		}

		.cls-15 {
			fill: #1e1c1a;
		}

		.cls-16 {
			fill: #574545;
		}

		.cls-17 {
			fill: #ded5c0;
		}

		.cls-18 {
			fill: #b9afa6;
		}

		.cls-19,
		.cls-22,
		.cls-33 {
			fill: #682e52;
		}

		.cls-19,
		.cls-21,
		.cls-46,
		.cls-53,
		.cls-55 {
			opacity: 0.2;
		}

		.cls-20 {
			fill: #199b32;
		}

		.cls-23 {
			fill: #43b649;
		}

		.cls-24 {
			fill: #69d746;
		}

		.cls-25 {
			fill: #c3beb9;
		}

		.cls-26 {
			fill: #605a55;
		}

		.cls-27 {
			fill: #807b77;
		}

		.cls-28,
		.cls-57 {
			fill: #fff8dc;
		}

		.cls-29 {
			fill: #bc7244;
		}

		.cls-30 {
			fill: #ffd346;
		}

		.cls-31 {
			fill: #ffea4d;
		}

		.cls-32 {
			fill: #947358;
		}

		.cls-33,
		.cls-39,
		.cls-60 {
			opacity: 0.15;
		}

		.cls-34 {
			fill: #cea776;
		}

		.cls-35 {
			fill: #fcd8dd;
		}

		.cls-36 {
			fill: #82f0ff;
		}

		.cls-37 {
			fill: #a7f4ff;
		}

		.cls-38 {
			fill: #f9a136;
		}

		.cls-40 {
			fill: #735f50;
		}

		.cls-41 {
			fill: #967c6e;
		}

		.cls-42 {
			fill: #e65e49;
		}

		.cls-43,
		.cls-50,
		.cls-65 {
			opacity: 0.3;
		}

		.cls-44,
		.cls-45 {
			stroke: #000;
		}

		.cls-44,
		.cls-45,
		.cls-51,
		.cls-52,
		.cls-67 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-44,
		.cls-51 {
			stroke-width: 2px;
		}

		.cls-45,
		.cls-52 {
			stroke-width: 4px;
		}

		.cls-47,
		.cls-57 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-48 {
			fill: #c39c79;
		}

		.cls-49 {
			fill: #373126;
		}

		.cls-51,
		.cls-52 {
			stroke: #1e1c1a;
		}

		.cls-54 {
			fill: #17c8bb;
		}

		.cls-55 {
			fill: #654935;
		}

		.cls-56 {
			fill: #7c5b40;
		}

		.cls-58 {
			fill: #533b26;
		}

		.cls-59 {
			fill: #f04e49;
		}

		.cls-60,
		.cls-65 {
			mix-blend-mode: screen;
		}

		.cls-61 {
			fill: #46d2fd;
		}

		.cls-62 {
			fill: #bc8040;
		}

		.cls-63 {
			fill: #acd149;
		}

		.cls-64 {
			fill: #e5be83;
		}

		.cls-66 {
			fill: #f7a28c;
		}

		.cls-67 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}
}
