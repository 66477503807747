.survey-cells {
  padding: 30px 20px;
}
.survey-cells .survey-cells-question {
  padding-bottom: 40px;
}
.survey-cells .survey-cells-question:last-child {
  padding-bottom: 0;
}
.survey-cells .survey-cells-question .survey-cells-question-title {
  font-size: 14px;
  padding-bottom: 20px;
}
.survey-cells .survey-cells-question .survey-cells-question-options {
  display: grid;
  grid-template-columns: repeat(3, 33.33333%);
  grid-template-rows: auto;
  grid-column-gap: auto;
  grid-row-gap: 10px;
}
@media (max-width: 767.98px) {
  .survey-cells .survey-cells-question .survey-cells-question-options {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: auto;
    grid-column-gap: auto;
    grid-row-gap: 10px;
  }
}
.survey-cells .survey-cells-question .survey-cells-question-options .survey-cells-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 26px;
}
.survey-cells .survey-cells-question .survey-cells-question-options .survey-cells-option .survey-cell-option-label {
  font-family: "nunitosemibold";
  padding-left: 5px;
  display: block;
  width: calc(100% - 40px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.survey-cells .survey-cells-question .survey-cells-question-options .survey-cells-option .survey-cells-editable-input {
  border: none;
  font-family: "nunitosemibold";
}
.survey-cells .survey-cells-question .survey-cells-question-options .survey-cells-option .survey-cells-editable-input:focus {
  outline: none;
}

