.sprig-visual {
  height: 100%;
  width: 100%;
  padding: 20px 0 40px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
}
.sprig-visual .tree-activity-metrics {
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .sprig-visual .tree-activity-metrics {
    right: 160px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sprig-visual .tree-activity-metrics {
    right: 140px;
  }
}
.sprig-visual .tree-activity-metrics .tree-activity-metric {
  margin-right: 5px;
}
.sprig-visual .tree-activity-metrics .tree-activity-metric .tree-activity-metric-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
}
.sprig-visual .tree-activity-metrics .tree-activity-metric .tree-activity-metric-circle .tree-activity-metric-icon {
  text-align: center;
  position: relative;
  top: 5px;
}
.sprig-visual .tree-activity-metrics .tree-activity-metric .tree-activity-metric-circle .tree-activity-metric-icon img {
  width: 15px;
}
.sprig-visual .tree-activity-metrics .tree-activity-metric .tree-activity-metric-circle .tree-activity-metric-data {
  text-align: center;
  font-family: "nunitosemibold";
  position: relative;
  top: 5px;
}
.sprig-visual .tree-activity-metrics .tree-activity-metric .tree-activity-metric-circle .tree-activity-metric-data-small-font-size {
  text-align: center;
  font-family: "nunitosemibold";
  position: relative;
  top: 5px;
  font-size: 0.9rem;
}
.sprig-visual .tree-activity-metrics .tree-activity-metric .tree-activity-metric-title {
  color: #fff;
  text-align: center;
  font-family: "nunitosemibold";
  font-size: small;
}
.sprig-visual .sticker-toggle {
  position: absolute;
  right: 20px;
  top: 20px;
}
.sprig-visual .sprig-tree {
  height: 100%;
  width: 100%;
}
.sprig-visual .sprig-tree .slice {
  cursor: pointer;
}
.sprig-visual .sprig-tree .slice0 {
  fill: #007822;
}
.sprig-visual .sprig-tree .slice0:hover {
  fill: rgb(103.4756097561, 193.5243902439, 120.7926829268);
}
.sprig-visual .sprig-tree .slice0.outcome-selected {
  fill: #47af5b;
}
.sprig-visual .sprig-tree .slice1 {
  fill: #199b32;
}
.sprig-visual .sprig-tree .slice1:hover {
  fill: rgb(103.4756097561, 193.5243902439, 120.7926829268);
}
.sprig-visual .sprig-tree .slice1.outcome-selected {
  fill: #47af5b;
}
.sprig-visual .sprig-tree .slice2 {
  fill: #39be42;
}
.sprig-visual .sprig-tree .slice2:hover {
  fill: rgb(134.4312796209, 215.5687203791, 139.7393364929);
}
.sprig-visual .sprig-tree .slice2.outcome-selected {
  fill: #60cb67;
}
.sprig-visual .sprig-tree .slice3 {
  fill: #69d746;
}
.sprig-visual .sprig-tree .slice3:hover {
  fill: rgb(171, 239.6, 151.4);
}
.sprig-visual .sprig-tree .slice3.outcome-selected {
  fill: #87e96b;
}
.sprig-visual .sprig-tree .slice4 {
  fill: #8ce655;
}
.sprig-visual .sprig-tree .slice4:hover {
  fill: rgb(186.75, 235.5, 157.5);
}
.sprig-visual .sprig-tree .slice4.outcome-selected {
  fill: #9de373;
}
.sprig-visual .sprig-tree .slice5 {
  fill: #8ce655;
}
.sprig-visual .sprig-tree .slice5:hover {
  fill: rgb(186.75, 235.5, 157.5);
}
.sprig-visual .sprig-tree .slice5.outcome-selected {
  fill: #9de373;
}
.sprig-visual .sprig-tree .slice6 {
  fill: #bef046;
}
.sprig-visual .sprig-tree .slice6:hover {
  fill: rgb(186.75, 235.5, 157.5);
}
.sprig-visual .sprig-tree .slice6.outcome-selected {
  fill: #9de373;
}
.sprig-visual .sprig-tree #scale .scale-tick {
  fill: white;
  opacity: 0.5;
}
.sprig-visual .sprig-tree #scale-length {
  fill: white;
  opacity: 0.5;
}
.sprig-visual .sprig-tree .scale-arrow {
  fill: white;
}
.sprig-visual .sprig-tree .mask-animation {
  transition: transform 1s ease;
  transform-origin: 50% 50%;
  animation: PulseTree 1s 3;
}
.sprig-visual .sprig-tree-leaf {
  position: absolute;
  height: 45px;
  width: 60px;
  pointer-events: none;
  transform: translate(-50%, -25%);
  transform-origin: 50% 50%;
  opacity: 1;
  transition: all 0.5s ease;
  animation: ScaleIn 5s 1;
}
@media (max-width: 767.98px) {
  .sprig-visual .sprig-tree-leaf {
    height: 21px;
    width: 28px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .sprig-visual .sprig-tree-leaf {
    height: 30px;
    width: 40px;
  }
}
.sprig-visual .sprig-tree-leaf.leaf-animation .sprig-tree-leaf-animation {
  animation: ShakeTop 3s infinite 5.5s;
}
.sprig-visual .sprig-tree-leaf .sprig-tree-leaf-tooltip {
  position: relative;
}
.sprig-visual .sprig-tree-leaf .sprig-tree-leaf-tooltip svg path {
  stroke: white;
  stroke-width: 3px;
  pointer-events: auto;
}
.sprig-visual .sprig-tree-leaf .sprig-tree-leaf-tooltip svg circle {
  transition: stroke-dashoffset 0.35s;
}
.sprig-visual .sprig-tree-leaf .sprig-tree-leaf-tooltip .sprig-leaf-tooltip {
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  padding: 5px;
  bottom: 100%;
  left: calc(50% - 100px);
  width: 200px;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  color: white;
  text-align: center;
  border-radius: 5px;
}
.sprig-visual .sprig-tree-leaf .sprig-tree-leaf-tooltip .sprig-leaf-tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: calc(50% - 10px);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.8);
}
.sprig-visual .sprig-tree-leaf:hover .sprig-tree-leaf-tooltip .sprig-leaf-tooltip {
  opacity: 1;
}
.sprig-visual #sprig-tree-leaf0 svg path {
  fill: #007822;
}
.sprig-visual #sprig-tree-leaf1 svg path {
  fill: #199b32;
}
.sprig-visual #sprig-tree-leaf2 svg path {
  fill: #39be42;
}
.sprig-visual #sprig-tree-leaf3 svg path {
  fill: #69d746;
}
.sprig-visual #sprig-tree-leaf4 svg path {
  fill: #8ce655;
}
.sprig-visual #sprig-tree-leaf5 svg path {
  fill: #bef046;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-4 {
  fill: #d28c32;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
  stroke: #fff;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-7 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-16,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-10 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-11 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-12 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-13,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-30 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-14 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-16,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-23 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-25 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-18 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-17,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-24 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-30 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-21 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-22 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-26 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-25 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-27 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-28 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-29 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-31 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-32 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-33 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-1 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-1 .sprig-tree #saplings .cls-34 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-4 {
  fill: #d2b446;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
  stroke: #fff;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-7 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-10 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-11 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-12 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-13,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-32 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-14 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-16 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-17 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-25 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-27 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-20 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-26 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-32 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-23 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-24 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-28 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-27 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-29 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-30 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-31 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-33 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-34 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-35 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-2 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-2 .sprig-tree #saplings .cls-36 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-4 {
  fill: #e6d250;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-12,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-5 {
  stroke: #fff;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-7 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-11 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-13 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-14 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-15 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-16 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-17,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-40 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-18 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-19 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-20 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-25 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-22 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-23 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-24 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-33 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-35 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-28 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-34 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-40 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-31 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-32 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-36 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-35 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-37 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-38 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-39 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-41 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-42 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-43 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-3 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-3 .sprig-tree #saplings .cls-44 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-4 {
  fill: #ffff96;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-12,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-5 {
  stroke: #fff;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-7 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-11 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-13 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-14 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-15 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-16 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-17,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-40 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-18 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-19 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-20 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-25 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-22 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-23 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-24 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-33 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-35 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-28 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-34 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-40 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-31 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-32 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-36 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-35 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-37 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-38 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-39 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-41 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-42 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-43 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-4 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-4 .sprig-tree #saplings .cls-44 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-1,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-1,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-1,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-59 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-2,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-2,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-2,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-3,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-3,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-3,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-4,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-4,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-4,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-5,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-5,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-5,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-5 {
  fill: #a4836b;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-6,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-6,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-6 {
  fill: #d1c1b5;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-7,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-7,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-7 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-8 {
  fill: #bba290;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-9 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-49 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-10 {
  opacity: 0.1;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-10,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-51 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-11,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-11,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-11 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-12,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-12,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-12,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-12 {
  fill: #574545;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-13,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-13,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-13,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-13 {
  fill: #ded5c0;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-14,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-14,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-14,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-14 {
  fill: #b9afa6;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-18,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-29 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-15,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-51 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-16 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-19 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-20,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-20,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-20 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-21 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-22,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-22,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-22 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-23,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-23,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-23 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-24,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-24,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-24,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-53 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-25,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-25,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-25 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-26,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-26,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-26,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-26 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-27,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-27,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-27,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-27 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-28,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-28,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-28 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-29,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-32 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-30 {
  fill: #cea776;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-31 {
  fill: #fcd8dd;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-33 {
  fill: #735f50;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-34,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-34,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-34,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-34 {
  fill: #967c6e;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-35 {
  fill: #e65e49;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-36 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-37 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-38,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-38,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-38 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-39,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-46 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-41 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-59 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-47 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-48 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-53 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-44 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-45 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-48 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-50 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-51 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-52 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-54 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-55 {
  fill: #f04e49;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-56,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-56,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-56,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-56 {
  fill: #e5be83;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-57 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-58 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-5 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-5 .sprig-tree .bee .cls-59 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-1,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-1,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-1,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-59 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-2,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-2,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-2,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-3,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-3,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-3,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-4,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-4,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-4,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-5,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-5,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-5,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-5 {
  fill: #a4836b;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-6,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-6,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-6 {
  fill: #d1c1b5;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-7,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-7,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-7 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-8 {
  fill: #bba290;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-9 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-49 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-10 {
  opacity: 0.1;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-10,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-51 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-11,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-11,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-11 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-12,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-12,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-12,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-12 {
  fill: #574545;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-13,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-13,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-13,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-13 {
  fill: #ded5c0;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-14,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-14,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-14,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-14 {
  fill: #b9afa6;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-18,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-29 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-15,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-51 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-16 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-19 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-20,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-20,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-20 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-21 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-22,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-22,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-22 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-23,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-23,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-23 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-24,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-24,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-24,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-53 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-25,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-25,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-25 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-26,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-26,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-26,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-26 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-27,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-27,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-27,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-27 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-28,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-28,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-28 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-29,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-35 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-30 {
  fill: #cea776;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-31 {
  fill: #fcd8dd;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-32 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-33 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-34,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-34,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-34,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-34 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-36 {
  fill: #735f50;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-37 {
  fill: #967c6e;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-38,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-38,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-38 {
  fill: #e65e49;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-39,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-46 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-41 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-59 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-47 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-48 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-53 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-44 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-45 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-48 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-50 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-51 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-52 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-54 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-55 {
  fill: #f04e49;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-56,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-56,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-56,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-56 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-57 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-58 {
  fill: #e5be83;
}
.sprig-visual.domain-4.tree-level-6 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-6 .sprig-tree .bee .cls-59 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-1,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-1,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-1,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-63 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-2,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-2,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-2,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-3,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-3,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-3,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-4,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-4,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-4,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-5,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-5,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-5,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-5 {
  fill: #f5f0f0;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-6,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-6,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-7,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-7,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-7 {
  fill: #bba290;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-8 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-8 {
  opacity: 0.1;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-9 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-10 {
  fill: #d1c1b5;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-11,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-11,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-12,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-12,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-12,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-12 {
  fill: #a4836b;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-13,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-13,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-13,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-13 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-14,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-14,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-14,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-14 {
  fill: #574545;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-15 {
  fill: #ded5c0;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-16 {
  fill: #b9afa6;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-20,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-20,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-20,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-31 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-53 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-18 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-21 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-22,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-22,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-22 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-23,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-23,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-23 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-24,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-24,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-24 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-25,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-25,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-25 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-26,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-26,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-26,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-26,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-55 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-27,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-27,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-27,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-27 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-28,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-28,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-28 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-29 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-30 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-31,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-37,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-58 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-32 {
  fill: #cea776;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-33 {
  fill: #fcd8dd;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-34,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-34,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-34,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-34 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-35 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-36 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-38,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-38,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-38 {
  fill: #735f50;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-39 {
  fill: #967c6e;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-40 {
  fill: #e65e49;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-48 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-43 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-63 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-49 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-50 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-45,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-55 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-46 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-47 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-50 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-52 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-53 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-54 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-56,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-56,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-56,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-56 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-57 {
  fill: #f04e49;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-58 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-59 {
  fill: #46d2fd;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-60,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-60,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-60,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-60 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-61,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-61,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-61,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-61 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-62,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-62,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-62,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-62 {
  fill: #e5be83;
}
.sprig-visual.domain-4.tree-level-7 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-7 .sprig-tree .bee .cls-63 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-1,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-1,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-1,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-64 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-2,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-2,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-2,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-3,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-3,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-3,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-4,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-4,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-4,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-5,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-5,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-5,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-5 {
  fill: #f5f0f0;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-6,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-6,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-7,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-7,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-7 {
  fill: #bba290;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-8 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-8 {
  opacity: 0.1;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-9 {
  fill: #d1c1b5;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-10,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-62 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-11,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-11,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-12,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-12,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-12,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-12 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-13,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-13,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-13,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-13 {
  fill: #574545;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-14,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-14,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-14,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-14 {
  fill: #ded5c0;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-15 {
  fill: #b9afa6;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-30 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-16,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-18,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-52 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-17 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-20,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-20,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-20 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-21 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-22,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-22,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-22 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-23,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-23,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-23 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-24,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-24,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-24 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-25,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-25,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-25,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-54 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-26,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-26,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-26,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-26 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-27,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-27,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-27,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-27 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-28,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-28,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-28 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-29 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-30,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-36,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-57 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-31 {
  fill: #cea776;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-32 {
  fill: #fcd8dd;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-33 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-34,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-34,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-34,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-34 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-35 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-37 {
  fill: #735f50;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-38,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-38,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-38 {
  fill: #967c6e;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-39 {
  fill: #e65e49;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-40,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-62 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-42 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-64 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-48 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-49 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-54 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-45 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-46 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-49 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-51 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-52 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-53 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-55 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-56,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-56,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-56,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-56 {
  fill: #f04e49;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-62,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-57,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-62 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-58 {
  fill: #46d2fd;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-59 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-60,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-60,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-60,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-60 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-61,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-61,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-61,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-61 {
  fill: #e5be83;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-63 {
  fill: #f7a28c;
}
.sprig-visual.domain-4.tree-level-8 .sprig-tree #antle .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #trees .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .nest .cls-64,
.sprig-visual.domain-4.tree-level-8 .sprig-tree .bee .cls-64 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-1,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-1,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-1,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-65 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-2,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-2,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-2,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-3,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-3,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-3,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-4,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-4,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-4,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-5,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-5,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-5,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-5 {
  fill: #a4836b;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-6,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-6,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-6 {
  fill: #f5f0f0;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-7,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-7,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-7 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-8 {
  fill: #bba290;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-9 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-9 {
  opacity: 0.1;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-9 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-10,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-63 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-11,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-11,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-11 {
  fill: #d1c1b5;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-12,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-12,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-12,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-12 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-13,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-13,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-13,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-13 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-14,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-14,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-14,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-14 {
  fill: #574545;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-15 {
  fill: #ded5c0;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-16 {
  fill: #b9afa6;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-20,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-20,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-20,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-31 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-17,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-53 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-18 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-21 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-22,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-22,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-22 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-23,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-23,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-23 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-24,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-24,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-24 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-25,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-25,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-25 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-26,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-26,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-26,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-26,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-55 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-27,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-27,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-27,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-27 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-28,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-28,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-28 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-29 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-30 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-31,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-37,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-58 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-32 {
  fill: #cea776;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-33 {
  fill: #fcd8dd;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-34,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-34,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-34,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-34 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-35 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-36 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-38,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-38,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-38 {
  fill: #735f50;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-39 {
  fill: #967c6e;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-40 {
  fill: #e65e49;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-41,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-48,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-63 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-43 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-65 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-49 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-50 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-45,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-55 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-46 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-47 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-49,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-50 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-52 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-53 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-54 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-56,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-56,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-56,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-56 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-57 {
  fill: #f04e49;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-58,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-63 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-59 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-60,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-60,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-60,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-60 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-61,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-61,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-61,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-61 {
  fill: #e5be83;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-62,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-62,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-62,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-62 {
  fill: #46d2fd;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-64,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-64,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-64,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-64 {
  fill: #f7a28c;
}
.sprig-visual.domain-4.tree-level-9 .sprig-tree #antle .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #trees .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .nest .cls-65,
.sprig-visual.domain-4.tree-level-9 .sprig-tree .bee .cls-65 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-1,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-1,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-1,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-1,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-67 {
  fill: none;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-2,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-2,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-2,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-2,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-3,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-3,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-3,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-3,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-4,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-4,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-4,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-4,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-5,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-5,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-5,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-5,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-5 {
  fill: #f5f0f0;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-6,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-6,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-6,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-7,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-7,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-7,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-7 {
  fill: #bba290;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-8 {
  fill: #3b292d;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-8 {
  opacity: 0.1;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-8,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-9,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-9,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-9,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-9,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-9 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-10,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-10,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-10,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-10 {
  fill: #d1c1b5;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-11,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-11,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-11,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-11 {
  fill: #a4836b;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-12,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-12,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-12,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-12,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-12 {
  clip-path: url(#clip-path-3);
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-13,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-13,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-13,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-13,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-13,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-65 {
  fill: #fff;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-14,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-14,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-14,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-14,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-14 {
  clip-path: url(#clip-path-4);
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-15,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-15,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-15,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-15 {
  fill: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-16,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-16,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-16,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-16 {
  fill: #574545;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-17,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-17,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-17,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-17 {
  fill: #ded5c0;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-18,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-18,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-18,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-18 {
  fill: #b9afa6;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-22,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-22,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-22,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-22,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-33 {
  fill: #682e52;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-19,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-21,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-46,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-53,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-55 {
  opacity: 0.2;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-20,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-20,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-20,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-20 {
  fill: #199b32;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-23,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-23,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-23,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-23 {
  fill: #43b649;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-24,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-24,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-24,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-24 {
  fill: #69d746;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-25,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-25,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-25,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-25 {
  fill: #c3beb9;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-26,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-26,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-26,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-26,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-26 {
  fill: #605a55;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-27,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-27,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-27,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-27,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-27 {
  fill: #807b77;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-28,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-28,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-28,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-28,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-57 {
  fill: #fff8dc;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-29,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-29,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-29,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-29 {
  fill: #bc7244;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-30,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-30,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-30,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-30 {
  fill: #ffd346;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-31,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-31,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-31,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-31,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-31 {
  fill: #ffea4d;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-32,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-32,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-32,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-32,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-32 {
  fill: #947358;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-33,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-39,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-60 {
  opacity: 0.15;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-34,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-34,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-34,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-34,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-34 {
  fill: #cea776;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-35,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-35,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-35,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-35,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-35 {
  fill: #fcd8dd;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-36,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-36,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-36,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-36,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-36 {
  fill: #82f0ff;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-37,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-37,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-37,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-37,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-37 {
  fill: #a7f4ff;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-38,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-38,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-38,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-38 {
  fill: #f9a136;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-40,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-40,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-40,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-40 {
  fill: #735f50;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-41,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-41,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-41,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-41,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-41 {
  fill: #967c6e;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-42,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-42,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-42,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-42 {
  fill: #e65e49;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-43,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-50,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-65 {
  opacity: 0.3;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-45 {
  stroke: #000;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-67 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-51 {
  stroke-width: 2px;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-45,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-52 {
  stroke-width: 4px;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-47,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-47,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-47,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-57,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-47,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-57 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-48,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-48,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-48,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-48 {
  fill: #c39c79;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-49,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-49,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-49,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-49 {
  fill: #373126;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-51,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-52 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-54,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-54,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-54,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-54 {
  fill: #17c8bb;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-55,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-55 {
  fill: #654935;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-56,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-56,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-56,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-56 {
  fill: #7c5b40;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-58,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-58,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-58,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-58 {
  fill: #533b26;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-59,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-59,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-59,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-59 {
  fill: #f04e49;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-65,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-60,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-65 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-61,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-61,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-61,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-61 {
  fill: #46d2fd;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-62,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-62,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-62,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-62 {
  fill: #bc8040;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-63,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-63,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-63,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-63 {
  fill: #acd149;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-64,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-64,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-64,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-64 {
  fill: #e5be83;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-66,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-66,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-66,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-66 {
  fill: #f7a28c;
}
.sprig-visual.domain-4.tree-level-10 .sprig-tree #antle .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #trees .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .nest .cls-67,
.sprig-visual.domain-4.tree-level-10 .sprig-tree .bee .cls-67 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-4 {
  fill: #dcff8c;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
  stroke: #fff;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-7 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-28 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-11 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-12 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-13,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-34 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-14 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-16 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-17 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-44 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-25 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-19,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-40 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-31 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-34 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-35 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-40 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-44 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-44 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-29 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-30 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-46 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-32 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-36 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-37 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-45 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-39 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-40 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-41 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-42 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-1 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-1 .sprig-tree #saplings .cls-43 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-4 {
  fill: #e6d250;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
  stroke: #fff;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-5 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-7 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-30 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-11 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-12 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-13,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-36 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-14 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-16 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-17 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-18 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-19 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-46 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-20,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-27 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-21,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-23,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-42 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-33 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-36 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-37 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-26,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-42 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-27,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-46 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-46 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-31 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-32 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-48 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-34 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-38 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-39 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-40,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-47 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-41 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-42 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-43 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-44 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-2 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-2 .sprig-tree #saplings .cls-45 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-4 {
  fill: #d2b446;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-5 {
  stroke: #fff;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-53 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-7 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-38 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-11 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-12 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-13,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-44 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-14 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-16 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-17 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-22 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-19 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-20 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-21 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-23 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-46 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-26 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-27 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-53 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-35 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-49 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-41 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-32,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-44 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-45 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-49 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-53 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-45,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-53 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-39 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-40 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-41,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-55 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-42 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-54 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-48 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-49 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-50 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-51 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-3 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-3 .sprig-tree #saplings .cls-52 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-1 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-2 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-3 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-4 {
  fill: #aa8755;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-25,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-5 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-5 {
  stroke: #fff;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-53 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-5 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-6 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-7 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-8 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-36,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-15,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-9 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-10,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-38 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-11 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-12 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-13,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-44 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-14 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-16 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-17 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-18,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-22 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-19 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-20 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-21 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-23 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-24,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-46 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-26 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-27 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-53 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-28,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-35 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-29,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-31,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-49 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-30,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-41 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-32,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-44 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-33,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-45 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-34,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-49 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-35,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-53 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-37,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-38,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-43,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-45,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-46,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-53 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-39 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-40 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-41,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-55 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-42 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-47,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-54 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-48 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-49 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-50 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-51 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-4 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-4 .sprig-tree #saplings .cls-52 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-1,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-70 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-2,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-3,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-4,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-5,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-5 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-11,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-72 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-7 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-72 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-8 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-9 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-12,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-12 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-13,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-13 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-14,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-14 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-56 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-16,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-16 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-28 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-72 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-18 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-21 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-22,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-22 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-23,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-62 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-24,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-24 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-25,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-25 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-26,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-26 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-27,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-27 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-29 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-30 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-31,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-31 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-32,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-32 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-33,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-33 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-34,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-34 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-35,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-35 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-36,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-36 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-37,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-37 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-38,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-64 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-39 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-40 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-41 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-42 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-43 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-44,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-44 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-45 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-70 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-53 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-55 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-67 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-59 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-62 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-63 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-67 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-70 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-70 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-57 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-58 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-60 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-71 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-66 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-67 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-68 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-5 .sprig-tree #white_OL .cls-69 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-1,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-1,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-59 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-2,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-3,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-4,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-5,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-5 {
  fill: #a4836b;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-6,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-6 {
  fill: #d1c1b5;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-7,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-7 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-8 {
  fill: #bba290;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-9 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-49 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-10 {
  opacity: 0.1;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-10,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-32,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-51 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-11,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-11 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-12,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-12 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-13,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-13 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-14,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-14 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-18,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-29 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-15,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-51 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-16 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-19 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-20,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-20 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-21 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-22,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-22 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-23,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-23 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-24,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-24,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-53 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-25,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-25 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-26,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-26 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-27,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-27 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-28,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-28 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-29,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-32 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-30 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-31 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-33 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-34,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-34 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-35 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-36 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-37 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-38,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-38 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-39,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-46 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-41 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-59 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-47 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-48 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-53 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-44 {
  fill: #c39c79;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-45 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-48 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-50 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-51 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-52 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-54 {
  fill: #533b26;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-55 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-56,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-56 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-57 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-58 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-5 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-5 .sprig-tree .bee .cls-59 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-1,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-70 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-2,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-3,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-4,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-5,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-5 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-11,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-11,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-72 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-7 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-72 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-8 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-9 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-12,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-12 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-13,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-13 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-14,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-14 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-56 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-16,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-16 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-28 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-72 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-18 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-21 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-22,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-22 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-23,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-23,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-62 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-24,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-24 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-25,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-25 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-26,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-26 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-27,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-27 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-29 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-30 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-31,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-31 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-32,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-32 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-33,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-33 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-34,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-34 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-35,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-35 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-36,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-36 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-37,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-37 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-38,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-38,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-64 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-39 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-40 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-41 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-42 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-43 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-44,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-44 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-45 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-70 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-53 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-55 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-67 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-59 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-62 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-63 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-67 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-70 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-63,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-70 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-57 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-58 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-60 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-71 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-66 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-67 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-68 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-6 .sprig-tree #white_OL .cls-69 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-1,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-1,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-59 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-2,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-3,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-4,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-5,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-5 {
  fill: #a4836b;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-6,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-6 {
  fill: #d1c1b5;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-7,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-7 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-8 {
  fill: #bba290;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-9 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-49 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-10 {
  opacity: 0.1;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-10,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-35,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-51 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-11,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-11 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-12,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-12 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-13,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-13 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-14,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-14 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-18,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-29 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-15,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-51 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-16 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-19 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-20,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-20 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-21 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-22,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-22 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-23,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-23 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-24,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-24,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-53 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-25,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-25 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-26,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-26 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-27,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-27 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-28,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-28 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-29,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-35 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-30 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-31 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-32 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-33 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-34,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-34 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-36 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-37 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-38,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-38 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-39,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-46 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-41 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-59 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-47 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-48 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-53 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-44 {
  fill: #c39c79;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-45 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-48 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-50 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-51 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-52 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-54 {
  fill: #533b26;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-55 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-56,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-56 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-57 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-58 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-6 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-6 .sprig-tree .bee .cls-59 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-1,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-73 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-2,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-3,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-4,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-5,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-5 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-9 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-8 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-10 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-11,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-12,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-12 {
  clip-path: url(#clip-path-3);
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-13,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-13 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-14,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-14 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-15,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-15 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-16,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-59 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-17 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-29 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-75 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-19 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-22,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-22 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-23,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-23 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-24,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-65 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-25,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-25 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-26,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-26 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-27,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-27 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-28 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-30 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-31 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-32,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-32 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-33,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-33 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-34,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-34 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-35,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-35 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-36,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-36 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-37 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-38,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-38 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-39,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-67 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-40,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-40 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-41 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-42 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-43 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-44 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-45 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-46 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-47 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-48 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-73 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-56 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-58 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-70 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-62 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-65 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-66 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-70 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-73 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-73 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-60 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-61 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-63 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-74,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-74,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-74,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-74 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-69 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-70 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-71 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-7 .sprig-tree #white_OL .cls-72 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-1,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-1,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-63 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-2,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-3,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-4,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-5,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-5 {
  fill: #f5f0f0;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-6,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-7,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-7 {
  fill: #bba290;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-8 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-8 {
  opacity: 0.1;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-9 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-10 {
  fill: #d1c1b5;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-11,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-12,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-12 {
  fill: #a4836b;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-13,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-13 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-14,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-14 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-15 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-16 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-20,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-31 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-53 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-18 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-21 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-22,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-22 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-23,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-23 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-24,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-24 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-25,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-25 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-26,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-26,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-55 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-27,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-27 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-28,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-28 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-29 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-30 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-31,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-37,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-58 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-32 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-33 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-34,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-34 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-35 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-36 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-38,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-38 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-39 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-40 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-48 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-43 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-63 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-49 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-50 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-45,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-55 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-46 {
  fill: #c39c79;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-47 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-50 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-52 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-53 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-54 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-56,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-56 {
  fill: #533b26;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-57 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-58 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-59 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-60,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-60 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-61,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-61 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-62,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-62 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-7 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-7 .sprig-tree .bee .cls-63 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-1,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-75 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-2,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-3,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-4,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-5,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-5 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-9 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-8 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-10 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-11,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-12,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-12 {
  clip-path: url(#clip-path-3);
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-13,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-13 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-14,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-14 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-15,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-15 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-61 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-17 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-29 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-77,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-77 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-19 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-22,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-22 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-23,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-23 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-24,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-24,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-67 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-25,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-25 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-26,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-26 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-27,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-27 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-28 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-30 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-31,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-31 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-32,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-32 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-33,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-33 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-34,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-34 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-35,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-35 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-36 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-37,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-37 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-38,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-38 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-39,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-39,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-69 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-40 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-41 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-48 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-43 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-44,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-44 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-45 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-46 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-47 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-64 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-49 {
  fill: #f7a28c;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-50 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-75 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-58 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-60 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-72 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-67 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-68 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-72 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-75 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-66,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-68,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-75 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-62 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-63 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-65 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-76 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-71 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-72 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-73 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree #Aliet .cls-74,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #shadows .cls-74,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #oak_trunk .cls-74,
.sprig-visual.domain-15.tree-level-8 .sprig-tree #white_OL .cls-74 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-1,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-1,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-64 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-2,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-3,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-4,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-5,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-5 {
  fill: #f5f0f0;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-6,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-7,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-7 {
  fill: #bba290;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-8 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-8 {
  opacity: 0.1;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-9 {
  fill: #d1c1b5;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-62,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-10,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-62 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-11,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-12,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-12 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-13,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-13 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-14,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-14 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-15 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-30 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-16,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-18,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-52 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-17 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-20,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-20 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-21 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-22,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-22 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-23,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-23 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-24,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-24 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-25,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-25,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-54 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-26,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-26 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-27,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-27 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-28,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-28 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-29 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-30,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-36,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-57 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-31 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-32 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-33 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-34,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-34 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-35 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-37 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-38,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-38 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-39 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-62,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-40,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-62 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-42 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-64 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-48 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-49 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-54 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-45 {
  fill: #c39c79;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-46 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-49 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-51 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-52 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-53 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-55 {
  fill: #533b26;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-56,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-56 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-62,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-57,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-62 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-58 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-59 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-60,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-60 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-61,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-61 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-63 {
  fill: #f7a28c;
}
.sprig-visual.domain-15.tree-level-8 .sprig-tree .nest .cls-64,
.sprig-visual.domain-15.tree-level-8 .sprig-tree .bee .cls-64 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-1,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-76 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-2,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-3,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-4,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-5,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-5 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-9 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-8 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-10 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-11,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-12,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-12 {
  clip-path: url(#clip-path-3);
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-13,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-13 {
  clip-path: url(#clip-path-4);
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-14,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-14 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-15,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-15 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-16,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-16 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-62 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-18 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-22,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-30 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-78,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-78 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-20 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-23,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-23 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-24,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-24 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-25,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-68 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-26,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-26 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-27,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-27 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-28 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-29 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-31 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-32,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-32 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-33,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-33 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-34,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-34 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-35,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-35 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-36,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-36 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-37 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-38,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-38 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-39,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-39 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-40,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-70 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-41 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-42 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-49 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-44 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-45 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-46 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-47 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-48 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-65 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-50 {
  fill: #f7a28c;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-51 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-76 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-59 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-61 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-73 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-68 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-69 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-73 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-76 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-76 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-63 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-64 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-66 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-77,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-77,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-77,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-77 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-72 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-73 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-74,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-74,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-74,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-74 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-9 .sprig-tree #white_OL .cls-75 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-1,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-1,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-65 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-2,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-3,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-4,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-5,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-5 {
  fill: #a4836b;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-6,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-6 {
  fill: #f5f0f0;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-7,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-7 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-8 {
  fill: #bba290;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-9 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-9 {
  opacity: 0.1;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-9 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-10,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-63 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-11,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-11 {
  fill: #d1c1b5;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-12,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-12 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-13,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-13 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-14,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-14 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-15 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-16 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-20,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-20,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-31 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-17,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-53 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-18 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-21 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-22,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-22 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-23,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-23 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-24,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-24 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-25,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-25 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-26,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-26,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-55 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-27,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-27 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-28,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-28 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-29 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-30 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-31,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-37,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-58 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-32 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-33 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-34,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-34 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-35 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-36 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-38,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-38 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-39 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-40 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-41,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-48,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-63 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-43 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-65 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-42,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-49 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-50 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-45,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-55 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-46 {
  fill: #c39c79;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-47 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-49,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-50 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-52 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-53 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-54 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-56,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-56 {
  fill: #533b26;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-57 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-58,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-63 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-59 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-60,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-60 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-61,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-61 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-62,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-62 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-64,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-64 {
  fill: #f7a28c;
}
.sprig-visual.domain-15.tree-level-9 .sprig-tree .nest .cls-65,
.sprig-visual.domain-15.tree-level-9 .sprig-tree .bee .cls-65 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-1,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-1,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-1,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-1,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-76 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-2,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-2,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-2,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-3,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-3,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-3,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-4,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-4,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-4,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-5,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-5,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-5,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-5 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-9 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-9 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-7,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-7,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-7,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-7,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-9 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-8,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-8,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-8,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-8 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-10,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-10,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-10,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-10 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-11,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-11,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-11,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-11 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-12,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-12,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-12,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-12 {
  clip-path: url(#clip-path-3);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-13,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-13,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-13,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-13 {
  clip-path: url(#clip-path-4);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-14,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-14,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-14,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-14 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-15,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-15,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-15,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-15 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-16,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-16,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-16,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-16 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-17,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-17,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-17,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-17,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-62 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-18,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-18,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-18,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-18 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-22,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-22,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-22,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-22,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-30 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-78,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-78 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-20,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-20,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-20,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-20 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-23,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-23,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-23,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-23 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-24,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-24,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-24,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-24 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-25,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-25,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-25,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-25,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-68 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-26,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-26,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-26,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-26 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-27,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-27,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-27,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-27 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-28,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-28,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-28,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-28 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-29,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-29,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-29,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-29 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-31,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-31,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-31,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-31 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-32,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-32,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-32,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-32 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-33 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-34,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-34,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-34,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-34 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-35,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-35,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-35,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-35 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-36,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-36,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-36,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-36 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-37,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-37,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-37,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-37 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-38,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-38,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-38,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-38 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-39 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-40,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-40,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-40,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-40,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-70 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-41,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-41,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-41,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-41 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-42,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-42,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-42,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-42 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-49 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-44 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-45 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-46 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-47,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-47,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-47,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-47 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-48,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-48,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-48,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-48 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-65 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-50 {
  fill: #f7a28c;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-51 {
  stroke: #fff;
  stroke-width: 6px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-76 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-59 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-61 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-73 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-56,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-68,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-56,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-68,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-56,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-68,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-56,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-68 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-69 {
  stroke-width: 3.16px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-73 {
  stroke-miterlimit: 10;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-76 {
  stroke-width: 1.5px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-76,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-69,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-70,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-76 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-63,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-63,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-63,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-63 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-64,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-64,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-64,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-64 {
  fill: #c8c6be;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-66,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-66,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-66,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-66 {
  fill: #eccda1;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-77,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-77,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-77,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-71,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-77 {
  fill: #f24d49;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-72,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-72,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-72,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-72 {
  fill: #bc7b3d;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-73,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-73 {
  fill: #8f5131;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-74,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-74,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-74,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-74 {
  fill: #2c2720;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree #Aliet .cls-75,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #shadows .cls-75,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #oak_trunk .cls-75,
.sprig-visual.domain-15.tree-level-10 .sprig-tree #white_OL .cls-75 {
  fill: #7b624b;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-1,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-1,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-67 {
  fill: none;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-2,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-2 {
  isolation: isolate;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-3,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-3 {
  fill: #98e866;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-4,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-4 {
  fill: #8ee164;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-5,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-5 {
  fill: #f5f0f0;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-6,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-6 {
  clip-path: url(#clip-path);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-7,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-7 {
  fill: #bba290;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-8 {
  fill: #3b292d;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-8 {
  opacity: 0.1;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-8,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-8 {
  mix-blend-mode: multiply;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-9,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-9 {
  clip-path: url(#clip-path-2);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-10,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-10 {
  fill: #d1c1b5;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-11,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-11 {
  fill: #a4836b;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-12,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-12 {
  clip-path: url(#clip-path-3);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-13,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-13,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-65 {
  fill: #fff;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-14,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-14 {
  clip-path: url(#clip-path-4);
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-15,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-15 {
  fill: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-16,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-16 {
  fill: #574545;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-17,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-17 {
  fill: #ded5c0;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-18,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-18 {
  fill: #b9afa6;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-22,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-22,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-33 {
  fill: #682e52;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-19,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-21,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-46,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-53,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-55 {
  opacity: 0.2;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-20,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-20 {
  fill: #199b32;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-23,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-23 {
  fill: #43b649;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-24,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-24 {
  fill: #69d746;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-25,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-25 {
  fill: #c3beb9;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-26,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-26 {
  fill: #605a55;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-27,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-27 {
  fill: #807b77;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-28,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-28,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-57 {
  fill: #fff8dc;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-29,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-29 {
  fill: #bc7244;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-30,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-30 {
  fill: #ffd346;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-31,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-31 {
  fill: #ffea4d;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-32,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-32 {
  fill: #947358;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-33,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-39,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-60 {
  opacity: 0.15;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-34,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-34 {
  fill: #cea776;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-35,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-35 {
  fill: #fcd8dd;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-36,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-36 {
  fill: #82f0ff;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-37,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-37 {
  fill: #a7f4ff;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-38,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-38 {
  fill: #f9a136;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-40,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-40 {
  fill: #735f50;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-41,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-41 {
  fill: #967c6e;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-42,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-42 {
  fill: #e65e49;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-43,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-50,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-65 {
  opacity: 0.3;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-45 {
  stroke: #000;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-67 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-44,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-51 {
  stroke-width: 2px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-45,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-52 {
  stroke-width: 4px;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-47,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-57,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-47,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-57 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-48,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-48 {
  fill: #c39c79;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-49,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-49 {
  fill: #373126;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-52,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-51,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-52 {
  stroke: #1e1c1a;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-54,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-54 {
  fill: #17c8bb;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-55,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-55 {
  fill: #654935;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-56,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-56 {
  fill: #7c5b40;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-58,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-58 {
  fill: #533b26;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-59,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-59 {
  fill: #f04e49;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-65,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-60,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-65 {
  mix-blend-mode: screen;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-61,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-61 {
  fill: #46d2fd;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-62,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-62 {
  fill: #bc8040;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-63,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-63 {
  fill: #acd149;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-64,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-64 {
  fill: #e5be83;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-66,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-66 {
  fill: #f7a28c;
}
.sprig-visual.domain-15.tree-level-10 .sprig-tree .nest .cls-67,
.sprig-visual.domain-15.tree-level-10 .sprig-tree .bee .cls-67 {
  stroke: #fff;
  stroke-width: 6px;
}

@keyframes PulseTree {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ScaleIn {
  0% {
    transform: translate(-50%, -25%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -25%) scale(1);
    opacity: 1;
  }
}
@keyframes ShakeTop {
  0%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%, 40%, 60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%, 50%, 70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

