@import "../../../Stylesheets/scss/module";

.navigation-secondary {
	height: 56px;
	min-height: 56px;
	width: 100%;
	background-color: white;
	border-bottom: 1px solid $border-grey;
	box-sizing: border-box;
	@include Flexbox(row, flex-start, center);
	padding-left: 20px;

	&.circle-chart-secondary-nav {
		@include SetMediaQuery(bootstrap-sm) {
			height: 112px;
		}
	}

	&--content {
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		max-width: 1200px;
		@include Flexbox(row, center);

		.navigation-secondary--title-and-icon {
			white-space: nowrap;
			@include Flexbox(row, center);

			.icon {
				margin-right: 10px;
			}

			.navigation-secondary-title {
				font-size: 20px;
				font-family: $font-family-bold;
				color: $default-text-color;
			}
		}

		@include SetMediaQuery(bootstrap-sm) {
			.navigation-secondary {
				&--title-and-icon {
					display: none !important;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-sm) {
		padding: 0 20px;

		&--content {
			.navigation-secondary--title-and-icon {
				.navigation-secondary--content-title {
					visibility: hidden;
					display: block;
					width: 0;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-md) {
		padding: 0 20px;

		&--content {
			.navigation-secondary--title-and-icon {
				.icon {
					visibility: visible;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-lg) {

		&--content {
			.navigation-secondary--title-and-icon {
				.icon {
					visibility: visible;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-xl) {
	}
}
