@import "../../../../module";

$legendSize: 20px;

.student-milestone-report {
	.student-milestone-report-title {
		padding: 1rem;
		border-bottom: 1px solid $border-grey;

		h5 {
			margin-bottom: 0;
		}
	}

	.student-milestone-chart {
		padding: 1rem;
		border-top: 1px solid $border-grey;

		.student-milestone-chart-legend {
			@include SetMediaQuery(phone) {
				@include Flexbox(row, flex-start);

				.legend-item {
					padding-right: 15px;

					.legend-item-label {
						@include Flexbox(row, center);

						span {
							font-size: 0.8rem;
							padding-left: 5px;
						}

						.legend-item-color {
							height: 15px;
							width: 15px;
							flex-grow: 0;
							flex-shrink: 0;
							border-radius: 50%;

							&.above {
								background-color: $sprig-baby-blue;
							}

							&.below {
								background-color: $sprig-orange;
							}

							&.expectation {
								background-color: $label-dark-grey;
							}
						}
					}

					.legend-item-statistics {
						padding-left: calc(#{$legendSize} + 5px);
						@include Flexbox(row, center, space-between);

						.expectation {
							@include Flexbox(row, center);

							.arrow {
								border-left: 7px solid transparent;
								border-right: 7px solid transparent;
								margin-left: 3px;

								&.below {
									border-top: 7px solid $sprig-orange;
								}

								&.above {
									border-bottom: 7px solid $sprig-baby-blue;
								}
							}
						}

						.totals {
							font-size: 0.8rem;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
			@include SetMediaQuery(tablet-portrait) {
				@include Flexbox(row, center, center);

				.legend-item {
					margin-right: 20px;

					.legend-item-label {
						@include Flexbox(row, center);

						span {
							margin-left: 5px;
						}

						.legend-item-color {
							height: $legendSize;
							width: $legendSize;
							border-radius: 50%;

							&.above {
								background-color: $sprig-baby-blue;
							}

							&.below {
								background-color: $sprig-orange;
							}

							&.expectation {
								background-color: $label-dark-grey;
							}
						}

						&:last-child {
							margin-right: 0;
						}
					}

					.legend-item-statistics {
						padding-left: calc(#{$legendSize} + 5px);
						@include Flexbox(row, center, space-between);

						.expectation {
							@include Flexbox(row, center);

							.arrow {
								border-left: 7px solid transparent;
								border-right: 7px solid transparent;
								margin-left: 3px;

								&.below {
									border-top: 7px solid $sprig-orange;
								}

								&.above {
									border-bottom: 7px solid $sprig-baby-blue;
								}
							}
						}
					}
				}
			}
		}

		.student-milestone-chart-area {
			height: 300px;
			width: 100%;

			.line {
				&.above {
					stroke: $sprig-baby-blue;
				}

				&.below {
					stroke: $sprig-orange;
				}
			}

			.__react_component_tooltip {
				&.place-bottom {
					background-color: $sprig-orange;

					&::after {
						border-bottom-color: $sprig-orange;
					}
				}

				&.place-top {
					background-color: $sprig-baby-blue;

					&::after {
						border-top-color: $sprig-baby-blue;
					}
				}
			}
		}
	}

	.student-milestone-students {
		.student-milestone-students-selected {
			width: 100%;
			padding: 0 1rem 1rem 1rem;

			.student-milestone-selected-student {
				margin-bottom: 0.5rem;
				@include Flexbox(row, center);

				&:last-child {
					margin-bottom: 0;
				}

				.selected-student-content {
					width: 100%;

					.selected-student-label {
						color: $label-grey;
					}

					.selected-student-data {
					}
				}

				.selected-student-actions {
					@include Flexbox(row, center);

					.selected-student-link {
						padding: 0 0.25rem;
						cursor: pointer;

						svg {
							path {
								fill: $label-dark-grey;
							}
						}

						&:hover {
							svg path {
								fill: $sprig-baby-blue;
							}
						}
					}

					.selected-student-remove {
						padding: 0 0.25rem;
						cursor: pointer;

						&:hover {
							svg path {
								fill: $primary-educator;
							}
						}
					}
				}
			}
		}

		.student-milestone-students-area {
			width: 100%;
			border-top: 1px solid $border-grey;

			&.expectations- {
				&2 {
					@include Grid(grid, auto, repeat(2, auto));
					@include SetMediaQuery(bootstrap-sm-md) {
						@include Grid(grid, auto, repeat(1, auto));
					}
				}

				&3 {
					@include Grid(grid, auto, repeat(3, auto));
					@include SetMediaQuery(bootstrap-sm-md) {
						@include Grid(grid, auto, repeat(1, auto));
					}
				}
			}

			.student-milestone-expectation {
				border-right: 1px solid $border-grey;

				.expectation-header {
					padding: 0.5rem 1rem;
					@include Flexbox(row, center);

					.expectation-color {
						height: 16px;
						width: 16px;
						border-radius: 50%;
						margin-right: 5px;

						&.expectation- {
							&below {
								background-color: $sprig-orange;
							}

							&at {
								background-color: $label-dark-grey;
							}

							&above {
								background-color: $sprig-baby-blue;
							}
						}
					}

					.expectation-title {
					}
				}

				.expectation-category-header {
					width: 100%;
					border-bottom: 1px solid $border-grey;
					padding: 0.25rem 1rem;
					@include Flexbox(row, center);

					.category-name {
						width: 50%;
					}

					.category-level {
						width: 30%;
					}
				}

				.expectation-rows {
					padding: 0 0 0.5rem 0;

					.expectation-row {
						padding: 0.25rem 1rem;
						border-bottom: 1px solid $border-grey;
						cursor: pointer;
						@include Flexbox(row, center);

						&.selected {
							background-color: $border-grey;
						}

						.expectation-information {
							width: 80%;
							@include Flexbox(row, center);

							.expectation-row-name {
								width: 65%;
							}

							.expectation-row-level {
								width: 35%;
							}
						}

						.expectation-row-link {
							width: 20%;
							@include Flexbox(row, center, flex-end);

							.student-link {
								padding: 0 0.25rem;

								svg {
									path {
										fill: $label-dark-grey;
									}
								}

								&:hover {
									svg path {
										fill: $sprig-baby-blue;
									}
								}
							}
						}

						&:hover {
							background-color: $border-grey;
						}

						&:last-child {
							border-bottom: none;
						}
					}
				}

				&:last-child {
					border-right: none;
				}
				@include SetMediaQuery(bootstrap-sm-md) {
					border-bottom: 3px solid $border-grey;

					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}
