.badge-collection {
  padding: 20px;
  padding-top: 0;
}
.badge-collection .badge-category {
  padding-bottom: 10px;
}
.badge-collection .badge-category .badge-category-title {
  padding-top: 20px;
}
.badge-collection .badge-category .badge-category-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
}

