@import "../../../Stylesheets/scss/module";

.navigation-sidebar {
	height: calc(100vh - #{$PrimaryNavigationHeight});
	min-width: 56px;
	width: 56px;
	border-right: 1px solid $border-grey;
	background-color: white;

	&--content {
		.navigation-item-list {
			@include Flexbox(column);

			.navigation-item {
				font-size: 18px;
				width: 100%;
				@include Flexbox(column, center, center);

				opacity: 1;
				cursor: pointer;
				position: relative;
				box-sizing: border-box;

				a {
					border-left: 3px solid transparent;
					padding: 15px 0;
					width: 100%;

					.icon {
						font-size: 100%;
					}

					.icon,
					.sprig-icon {
						margin: 0;
						height: auto;
						width: 20px;
						@include SetMediaQuery(bootstrap-xl) {
							margin: 0 15px;
						}
						@include SetMediaQuery(bootstrap-md) {
							margin: 0 15px;
						}
						@include SetMediaQuery(bootstrap-sm) {
							margin: 0 15px;
						}

						svg {
							color: $label-dark-grey;
							fill: $label-dark-grey;
						}
					}

					.sprig-icon {
						margin: 0;
					}

					span {
						display: none;
						@include SetMediaQuery(bootstrap-xl) {
							display: block;
						}
						@include SetMediaQuery(bootstrap-md) {
							display: block;
						}
						@include SetMediaQuery(bootstrap-sm) {
							display: block;
						}
					}

					&.active {
						.caregiver-portal & {
							border-left: 3px solid $secondary-caregiver;
						}

						.educator-portal & {
							border-left: 3px solid $secondary-educator;
						}

						.reading-portal & {
							border-left: 3px solid $secondary-educator;
						}

						.holistic-math-prototype & {
							border-left: 3px solid $secondary-educator;
						}

						.admin-portal & {
							border-left: 3px solid $secondary-admin;
						}

						.icon,
						.sprig-icon {
							svg {
								.caregiver-portal & {
									fill: $primary-caregiver;
									color: $primary-caregiver;
								}

								.educator-portal & {
									fill: $primary-educator;
									color: $primary-educator;
								}

								.reading-portal & {
									fill: $primary-educator;
									color: $primary-educator;
								}

								.holistic-math-prototype & {
									fill: $primary-educator;
									color: $primary-educator;
								}

								.admin-portal & {
									fill: $primary-admin;
									color: $primary-admin;
								}
							}
						}
					}
				}

				&:hover {
					a {
						.icon,
						.sprig-icon {
							svg {
								.caregiver-portal & {
									fill: $primary-caregiver;
								}

								.educator-portal & {
									fill: $primary-educator;
									color: $primary-educator;
								}

								.reading-portal & {
									fill: $primary-educator;
									color: $primary-educator;
								}

								.admin-portal & {
									fill: $primary-admin;
									color: $primary-admin;
								}
							}
						}
					}
				}
			}
		}
	}
}
