.loader-spinner-component {
  height: 100px;
  width: 100px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.loader-spinner-component .loader-circle {
  height: 100%;
  width: 100%;
  border: 5px solid #f9f9f9;
  border-top: 5px solid #39be42;
  border-radius: 50%;
  animation: spin 1.2s ease 0s infinite;
}
.loader-spinner-component .loader-img {
  padding: 25px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.loader-spinner-component .loader-img img {
  width: 100%;
  height: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  70% {
    transform: rotate(80deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

