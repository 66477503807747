.notification-tab {
  position: relative;
  z-index: 3000;
}
.notification-tab button img {
  width: 20px;
}
.notification-tab .notification-tab-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border-radius: 1rem;
  height: 400px;
  max-height: calc(100vh - 56px);
  width: 400px;
  max-width: 75vw;
  border: 1px solid #dee2e6;
}
.notification-tab .notification-tab-menu .notification-tab-menu-header {
  height: 40px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.notification-tab .notification-tab-menu .notification-tab-menu-body {
  height: calc(100% - 40px - 20px);
  overflow: auto;
}
.notification-tab .notification-tab-menu .notification-tab-menu-footer {
  height: 20px;
  border-top: 1px solid #dee2e6;
}

