@import "../../../Stylesheets/scss/module";

.dropdown {
	position: relative;

	.btn {
		transition: transform 2s ease;

		&:focus {
			box-shadow: none;
		}
	}

	&--menu {
		position: absolute;
		top: calc(100% + 5px);
		left: 0;
		min-width: 150px;
		max-height: 0;
		box-sizing: border-box;
		background-color: white;
		overflow: hidden;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), border-width 0.3s ease;
		pointer-events: none;
		border: 1px solid $border-grey;
		border-width: 0;
		margin: 0;
		padding: 0;

		&-item {
			box-sizing: border-box;
			font-family: "nunito";
			font-size: 13px;
			width: 100%;
			height: 30px;
			list-style: none;
			text-decoration: none;
			cursor: pointer;
			padding: 0 10px;
			@include Flexbox(row, center, flex-start);

			&:hover {
				background-color: $border-grey;
			}
		}
	}

	&--enabled {
		.btn {
			.icon {
				transform: rotateX(180deg);
			}
		}

		.dropdown--menu {
			max-height: 1000px;
			border-width: 1px;
			pointer-events: all;
			transition: max-height 0.75s ease-in-out, border-width 0.3s ease;
		}
	}
}
