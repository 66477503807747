.sticker-toggle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
}
.sticker-toggle.hide {
  background-color: transparent;
}
.sticker-toggle svg {
  height: 25px;
  width: 25px;
}
.sticker-toggle svg path {
  fill: white;
}

