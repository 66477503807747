@import "../../../Stylesheets/scss/module";

.navigation-primary {
	height: $PrimaryNavigationHeight;
	width: 100%;

	.caregiver-portal & {
		background-color: $primary-caregiver;
	}
	.educator-portal & {
		background-color: $primary-educator;
	}
	.reading-portal & {
		background-color: $primary-educator;
	}
	.holistic-math-prototype & {
		background-color: $primary-educator;
	}
	.admin-portal & {
		background-color: $primary-admin;
	}
	@include Flexbox(row, center, center);

	&--content {
		height: 100%;
		max-width: 1200px;
		width: 1200px;
		@include Flexbox(row, center, space-between);

		.content-wrapper {
			height: 100%;
			width: 100%;
			@include Flexbox(row, center);

			&.content-center {
				@include Flexbox(row, center, center);
				@include SetMediaQuery(bootstrap-sm) {
					opacity: 0;
					z-index: -1;
				}
				@include SetMediaQuery(bootstrap-md) {
					max-width: 250px;
					width: 250px;
				}
			}

			&.content-right {
				@include Flexbox(row, center, flex-end);
				@include SetMediaQuery(bootstrap-sm) {
					flex: 1;
				}
			}
		}

		.navigation-primary--mobile-menu {
			display: none;
			height: 100%;
			width: 56px;
			cursor: pointer;

			.icon {
				svg {
					color: white;
				}
			}

			&.open {
				.educator-portal & {
					background-color: $secondary-educator;
				}

				.reading-portal & {
					background-color: $secondary-educator;
				}

				.caregiver-portal & {
					background-color: $secondary-caregiver;
				}
				.admin-portal & {
					background-color: $secondary-admin;
				}
			}
		}

		.navigation-primary--logo {
			margin-right: 10px;

			.icon {
				svg {
					color: white;
				}
			}
		}

		.navigation-item-list {
			.sprig-icon {
				svg {
					color: white;
					fill: white;
				}
			}
		}

		.navigation-primary--options {
			height: 100%;
			@include Flexbox(row, center, center);

			.navigation-option {
				padding: 10px;
				border-radius: 10px;
				cursor: pointer;

				.navigation-option-icon {
					svg {
						color: white;
					}
				}

				&:hover {
					.caregiver-portal & {
						background-color: $secondary-caregiver;
					}

					.educator-portal & {
						background-color: $secondary-educator;
					}

					.reading-portal & {
						background-color: $secondary-educator;
					}

					.admin-portal & {
						background-color: $secondary-admin;
					}
				}
			}

			.dropdown-wrapper--menu-item {
				cursor: pointer;

				.dropdown-menu-item {
					width: 200px;
					padding: 10px 20px;
					@include Flexbox(row, center);

					.icon {
						margin-right: 10px;
						height: 21px;
						width: 21px;
					}

					#sign-out-button {
						@include Flexbox(row);
					}

					&#support-url {
						a {
							color: $default-text-color;
							@include Flexbox(row);
						}
					}
				}
			}
		}

		.dropdown-wrapper {
			.btn {
				max-height: 50px;
				height: auto;
				padding-top: 5px;
				padding-bottom: 5px;
				border-radius: none;

				.primary-dropdown-content {
					@include Flexbox(row, center);
					.label-content {
						@include Flexbox(column, flex-start);

						margin-right: 5px;
						color: white;

						.primary-label {
							font-size: 15px;
							line-height: 17px;
						}

						.secondary-label {
							font-size: 13px;
							line-height: 15px;
						}
					}

					.icon {
						height: 14px;
						width: 14px;
						margin-left: 14px;

						svg {
							height: 100%;
							width: 100%;
							vertical-align: top;
							transition: transform 0.5s ease;
							transform-origin: center center;
							color: white;
						}
					}

					&.menu-open {
						.icon {
							svg {
								transform: rotateX(180deg);
							}
						}
					}
				}

				&:hover {
					color: white;
				}
				@include SetMediaQuery(bootstrap-sm) {
					padding: 0;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-sm) {
		&--content {
			padding: 0;
			padding-right: 20px;
			width: 100%;

			.content-wrapper {
				&.content-center {
					width: 0;
				}
			}

			.navigation-primary--mobile-menu {
				@include Flexbox(row, center, center);
			}

			.navigation-item-list,
			.navigation-primary--logo {
				display: none;
			}
		}
	}
	@include SetMediaQuery(bootstrap-md) {
		&--content {
			padding: 0;
			padding-right: 20px;
			width: 100%;

			.navigation-primary--mobile-menu {
				@include Flexbox(row, center, center);
			}

			.navigation-item-list,
			.navigation-primary--logo {
				display: none;
			}
		}
	}
	@include SetMediaQuery(bootstrap-lg) {
		&--content {
			padding: 0;
			.reading-portal & {
				padding-right: 20px;
			}

			.navigation-item-list {
				display: none;
			}

			.navigation-primary--logo {
				width: 56px;
				@include Flexbox(row, center, center);
			}
		}
	}
	@include SetMediaQuery(bootstrap-xl) {
		&--content {
			max-width: 100%;
			width: 100%;
			.reading-portal & {
				padding-right: 20px;
			}

			.navigation-primary--logo {
				margin-left: 10px;
			}
		}
	}
}
