@import "../../../../../module";

.assessment-learning-opportunities {
	.learning-opportunity-assets {
		@include Flexbox(row, center);

		flex-wrap: wrap;

		.learning-opportunity-outcome {
			cursor: pointer;
			border-radius: 1rem;
			height: 100%;
			color: $default-text-color;
			text-align: center;

			&:hover {
				background-color: $light-grey-background;
			}

			.outcome-img {
				padding: 20px;
				@include Flexbox(row, center, center);
			}
		}
	}
}
