.launch-screen-component {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.launch-screen-component .loader-spinner svg {
  color: #199b32;
}

