.assessment-question-row {
  display: grid;
  grid-template-columns: calc((100% + 25px) * 0.4) auto;
  grid-template-rows: 1fr;
  grid-column-gap: auto;
  grid-row-gap: auto;
  grid-auto-flow: column;
  border-bottom: 1px solid #dee2e6;
  height: 75px;
}
.assessment-question-row .assessment-question-img-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  padding-left: 0.5rem;
}
.assessment-question-row .assessment-question-row-option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assessment-question-row .assessment-question-row-option svg {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.assessment-question-row .assessment-question-row-option:nth-child(even) {
  background-color: rgba(222, 226, 230, 0.2);
}

