.modal-page-navigatior {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.modal-page-navigatior .modal-page-navigator-button {
  height: 34px;
  width: 34px;
  cursor: pointer;
}
.modal-page-navigatior .modal-page-navigator-button.hide-navigator {
  visibility: hidden;
}
.modal-page-navigatior .modal-page-navigator-button svg {
  height: 100%;
  width: 100%;
}
.modal-page-navigatior .modal-page-navigator-button svg path {
  fill: #6c757d;
}
.modal-page-navigatior .modal-page-navigator-button svg:hover path {
  fill: #1e1c1a;
}
.modal-page-navigatior .modal-page-navigator-title {
  font-size: 12px;
}

