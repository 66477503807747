@import "../../../../module";

.weekly-goal {
	height: 60px;

	svg {
		position: relative;
		top: -1px;
	}

	.weekly-goal-icon-data {
		position: relative;
		top: -60px;
		left: 20px;

		img {
			position: relative;
			left: 5px;
			width: 15px;
		}

		.tree-activity-metric-data {
			font-family: "nunitosemibold";
			position: relative;
			left: -3px;

			&-small-font-size {
				font-family: "nunitosemibold";
				position: relative;
				left: -6px;
				font-size: 0.9rem;
			}
		}
	}
}
