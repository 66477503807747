@import "../../../../Stylesheets/scss/module.scss";

.activities-filter {
	width: 350px;

	.activities-filter-title {
		padding: 10px 20px;
		font-size: 15px;
		border-bottom: 1px solid $border-grey;

		&:hover {
			cursor: pointer;
		}

		.clear,
		.apply {
			color: $label-dark-grey;

			&:hover {
				color: black;
			}
		}
	}

	.activities-filter-category {
		max-height: calc(90vh - 112px);
		overflow-y: auto;

		.title {
			color: $label-dark-grey;
			font-size: 13px;
			padding: 10px 20px;
		}

		.activity-taxonomy-item {
			font-size: 15px;
			padding: 10px 20px;
			border-bottom: 1px solid $border-grey;
			display: flex;
			justify-content: space-between;

			.icon {
				padding: 0;

				&:hover {
					cursor: pointer;
					background: none;
				}

				svg {
					.caregiver-portal & {
						color: $primary-caregiver;
					}

					.educator-portal & {
						color: $primary-educator;
					}
				}
			}

			&:hover {
				cursor: pointer;
			}
		}

		.activities-with {
			.activity-taxonomy-item {
				.icon-text {
					@include Flexbox(row, center, flex-start);
					.icon {
						width: 25px;
						margin-right: 10px;
						.option-icon {
							color: $rich-black !important;
							width: 100%;
							height: 100%;
							svg {
								fill: $rich-black;
							}
						}
					}
				}
			}
		}

		.hidden-activity {
			.activity-taxonomy-item {
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-sm) {
		width: 300px;
	}
}
