.portal-error-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-image: url("../../../../../../../Assets/Caregiver/Backgrounds/grass-sky.svg");
  background-size: contain;
}
@media (max-width: 991.98px) {
  .portal-error-screen {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.portal-error-screen .portal-error-content {
  max-width: 600px;
  min-width: 400px;
  width: 100%;
  background-color: white;
  padding: 1rem;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.portal-error-screen .portal-error-content .error-text {
  margin-bottom: 0.5rem;
}
.portal-error-screen .portal-error-content .error-text:first-child {
  color: #d9534f;
}

