@import "../../../../../module";

.survey-grid {
	padding: 30px 0;

	.survey-grid-question {
		.survey-grid-question-title {
			font-size: 14px;
			padding: 0 20px;
		}

		.survey-grid-content {
			width: 100%;
			@include Flexbox(row, center, center, nowrap);

			.survey-grid-questions {
				width: 100%;
				max-width: 100%;
				overflow: hidden;
				padding-top: 22px;
				min-width: 30%;

				.survey-grid-sub-question {
					height: 35px;
					padding: 0 20px;
					font-size: 12px;
					font-family: $font-family-semi-bold;
					@include Flexbox(row, center);

					.survey-grid-sub-question-text {
						@include ellipsis(100%, block);
					}

					.survey-grid-editable-input {
						border: none;
						background-color: transparent;
						font-family: $font-family-semi-bold;

						&:focus {
							outline: none;
						}
					}

					&:nth-child(odd) {
						background-color: rgba(222, 226, 230, 0.2);
					}
				}
			}

			.survey-grid-question-options {
				max-width: 70%;
				@include Flexbox(row);

				.survey-grid-option-group {
					@include Flexbox(column, center, center);

					.survey-grid-question-option {
						height: 22px;
						white-space: nowrap;
						padding: 0 5px;
						font-size: 12px;
						font-family: $font-family-semi-bold;
					}

					.survey-grid-option {
						height: 35px;
						width: 100%;
						@include Flexbox(row, center, center);

						&:nth-child(even) {
							background-color: rgba(222, 226, 230, 0.2);
						}
					}

					&:last-child {
						.survey-grid-option,
						.survey-grid-question-option {
							padding-right: 15px;
						}
					}
				}
			}
		}
	}
}
