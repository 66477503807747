@import "../module";

.adm-nav.nav-link {
	@include NavLink($primary-admin);
}

.edu-nav.nav-link {
	@include NavLink($primary-educator);
}

.modal-nav.nav-link {
	color: rgba(255, 255, 255, 0.8);
	border: none;
	background-color: $primary-caregiver;

	&:hover {
		color: #fff;
		cursor: pointer;
	}
}

.support-email {
	color: #fff;
	text-decoration: none;
	background: transparent;

	&:hover {
		color: #fff;
		text-decoration: underline;
	}
}

.adm-nav-item {
	flex: 1 1 auto;
	margin-bottom: -1px;

	.act-nav {
		background-color: #fff;
		width: 100%;
	}

	.adm-nav-link {
		color: $label-dark-grey;
		border: none;
		cursor: pointer;
		padding: 0.5rem 1rem;

		&.active {
			color: $primary-admin;
			border-bottom: 3px solid $primary-admin;
		}

		&:hover {
			color: $primary-admin;
		}

		&:focus {
			outline: none;
		}
	}
}

.no-default-link-style {
	color: #fff;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
		color: #fff;
	}
}
