.table-component {
  table-layout: fixed;
}
.table-component thead th > * {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table-component thead th.sortable {
  cursor: pointer;
  position: relative;
  user-select: none;
}
.table-component thead th.sortable > * {
  max-width: calc(100% - 10px);
}
.table-component thead th.sortable.not-sorted::after {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 5px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #6c757d;
}
.table-component thead th.sortable.not-sorted:hover {
  background-color: #dee2e6;
}
.table-component thead th.sortable.not-sorted:hover::after {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 5px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #6c757d;
}
.table-component thead th.sortable.sorted {
  background-color: #dee2e6;
}
.table-component thead th.sortable.sorted.asc::after {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 5px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #6c757d;
}
.table-component thead th.sortable.sorted.dsc::after {
  content: "";
  position: absolute;
  right: 10px;
  top: calc(50% - 5px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #6c757d;
}

