.component-expandable-search {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 40px;
  overflow: hidden;
}
.component-expandable-search .search-button:hover {
  background-color: #dee2e6;
}
.component-expandable-search .search-button svg {
  margin-right: 0 !important;
  color: #6c757d;
}
.component-expandable-search .close-button {
  padding: 0 5px;
  cursor: pointer;
}
.component-expandable-search input {
  background-color: transparent;
  border: none;
  transition: max-width 0.5s ease;
  height: 26px;
  width: 100%;
  padding: 0;
  max-width: 0;
}
.component-expandable-search.open {
  cursor: default;
}
.component-expandable-search.open .search-button {
  cursor: pointer;
}
.component-expandable-search.open input {
  max-width: 180px;
}

