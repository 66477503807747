@import "../../../Stylesheets/scss/module";

@mixin sidebarWide {
	.navigation-sidebar {
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		z-index: 9999;

		.navigation-item {
			a {
				@include Flexbox(row, center, flex-start);

				.icon {
					margin: 0 10px;
				}

				span {
					display: block;
					color: $label-dark-grey;
				}

				&.active {
					span {
						color: $primary-caregiver;

						.caregiver-portal & {
							color: $primary-caregiver;
						}

						.educator-portal & {
							color: $primary-educator;
						}

						.reading-portal & {
							color: $primary-educator;
						}

						.holistic-math-prototype & {
							color: $primary-educator;
						}

						.admin-portal & {
							color: $primary-admin;
						}
					}
				}
			}

			&:hover {
				a {
					span {
						color: $primary-caregiver;

						.caregiver-portal & {
							color: $primary-caregiver;
						}

						.educator-portal & {
							color: $primary-educator;
						}

						.reading-portal & {
							color: $primary-educator;
						}

						.holistic-math-prototype & {
							color: $primary-educator;
						}

						.admin-portal & {
							color: $primary-admin;
						}
					}
				}
			}
		}
	}
}
@mixin mobileTabletSidebarNavigation {
	@include sidebarWide();
	.navigation-sidebar {
		overflow: hidden;
	}
	&.page-layout--menu-closed {
		.navigation-sidebar {
			width: 0;
			min-width: 0;

			.navigation-sidebar--content {
				opacity: 0;
			}
		}
	}
}

.page-layout {
	height: 100vh;
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;

	.navigation-primary {
		position: relative;
		z-index: 9999;
	}

	&--main {
		height: calc(100% - #{$PrimaryNavigationHeight});
		width: 100%;
		position: relative;
		z-index: 8998;
		@include Flexbox(row);

		.page-layout--aside {
			@include Flexbox(column, center);

			width: 100%;
			min-width: 0;

			.page-layout--content {
				height: 100%;
				width: 100%;
				max-width: 1440px;
				overflow: auto;
				@include Flexbox(row, center, center);
			}

			&-secondary {
				@include Flexbox(column, center, center);

				&.page-layout--content {
					height: calc(100% - 45px);
				}
			}
		}

		.navigation-sidebar {
			//overflow: hidden;
			transition: all 0.2s ease 0.2s;
			position: relative;
			z-index: 9999;
			transform: translate3d(0, 0, 0);
			-webkit-transform: translate3d(0, 0, 0);

			.navigation-sidebar--content {
				transition: all 0.2s ease;
				opacity: 1;
			}
		}
	}
	@include SetMediaQuery(bootstrap-sm) {
		&--main {
			@include mobileTabletSidebarNavigation();

			&--content {
				padding: 0;

				.navigation-primary--mobile-menu {
					@include Flexbox(row, center, center);
				}

				.navigation-item-list {
					display: none;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-md) {
		&--main {
			@include mobileTabletSidebarNavigation();
		}
	}
	@include SetMediaQuery(bootstrap-lg) {
		&--main {
			&--content {
				padding: 0;

				.navigation-item-list {
					display: none;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-xl) {
		&--main {
			@include sidebarWide();

			.page-layout--aside.has-side-bar {
				position: relative;
				left: 200px;
				width: calc(100% - 200px);
			}
		}
	}
}
