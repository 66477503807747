@import "../../../../..//Stylesheets/scss/module.scss";

.student-milestone-filters {
	padding: 1rem;
	.student-milestone-filters-group {
		width: 100%;

		.dropdown {
			.btn-dropdown {
				width: 100%;
			}

			.dropdown-menu {
				width: 100%;
			}
		}
	}
	.student-milestone-filters-actions {
		position: relative;
		@include Flexbox(row, flex-start, center);

		button {
			&:first-child {
				margin-right: 1rem;
			}
			.icon {
				svg {
					color: #fff;
				}
			}
		}
		.recommended-activities {
			position: absolute;
			width: 375px;
			background-color: #fff;
			border: 1px solid $border-grey;
			border-radius: 10px;
			right: 0;
			top: 60px;

			&::after {
				position: absolute;
				content: "";
				left: calc(70% - 10px);
				top: -10px;
				border-left: 10px solid transparent;
				border-bottom: 10px solid white;
				border-right: 10px solid transparent;
				filter: drop-shadow(0 -1px 1px $border-grey);
			}

			.recommended-activities-title {
				@include Flexbox(row, center, center);
				height: 48px;
				border-bottom: 1px solid $border-grey;
			}
			.current-recommended-activities {
				height: 400px;
				overflow: auto;

				.recommended-activity {
					padding-left: 20px;
					padding-top: 25px;
					&:not(:last-of-type) {
						border-bottom: 1px solid $border-grey;
					}

					.activity-title {
						margin-bottom: 10px;
					}

					.activity-infos {
						height: auto;
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 13px;

						.info-block {
							$flex-basis: 24% 36% 40%;
							@for $i from 1 through 3 {
								&:nth-of-type(#{$i}) {
									flex: 1 1 nth($flex-basis, $i);
									max-width: nth($flex-basis, $i);
								}
							}

							&--title {
								color: $label-dark-grey;
								opacity: 0.9;
								font-size: 13px;
								@include SetMediaQuery(extra-small-phone) {
									font-size: 0.65rem;
								}
							}

							&--content {
								font-size: 13px;
								@include SetMediaQuery(extra-small-phone) {
									font-size: 0.65rem;
								}
							}
						}
					}
				}
			}
		}
	}
	@include SetMediaQuery(phone) {
		@include Flexbox(column, flex-start, center);
		.student-milestone-filters-group {
			margin-bottom: 1rem;
		}

		.student-milestone-filters-actions {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}
	@include SetMediaQuery(tablet-portrait) {
		@include Flexbox(row, flex-end, center);

		.student-milestone-filters-group {
			margin-right: 20px;
		}

		.student-milestone-filters-actions {
			width: 200px;

			button {
				width: 69px;
			}
		}
	}
}
