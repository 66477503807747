.assessment-question-section .assessment-question-title {
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.assessment-question-section .assessment-question-description {
  font-family: "nunitosemibold";
  width: 50%;
  margin-bottom: 30px;
}
.assessment-question-section .assessment-question-visuals .question-visual:nth-child(even) {
  background-color: rgba(222, 226, 230, 0.2);
}
.assessment-question-section .assessment-question-visuals .question-visual:nth-child(even) .question-visual-assets {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.assessment-question-section .assessment-question-visuals .question-visual:nth-child(2) .question-visual-group {
  background-color: #39be42;
}
.assessment-question-section .assessment-question-visuals .question-visual:nth-child(3) .question-visual-group {
  background-color: #69d746;
}

