@import "../../../../Stylesheets/scss/module.scss";

.notification-list {
	.notification-list-header {
		padding: 10px;
		border-bottom: 1px solid $border-grey;
		text-align: center;
	}

	.notification-list-body {
		max-height: calc(90vh - 101px); // 56px for navbar + 45px for header
		overflow-y: auto;
		overflow-x: hidden;
		width: 400px;
		@include SetMediaQuery(bootstrap-sm) {
			width: 300px;
		}

		.empty-state {
			.icon {
				margin: auto;
				padding: 25px;
				@include Flexbox(row, center, center);

				svg {
					color: $border-grey;
				}
			}

			.empty-state-text {
				padding: 25px;
				padding-top: 0;
				@include Flexbox(row, center, center);
			}
		}
	}

	.notification-item {
		padding: 20px;
		white-space: normal;
		border-bottom: 1px solid $border-grey;

		.notification-card-content {
			@include Flexbox(row, center, space-around);

			.notification-card-content-icon {
				width: 36px;

				#activities-icon {
					fill: $water-blue;
				}
			}
		}
	}
}
