// Flexbox classes
.flex {
	display: flex;

	&.direction {
		&-column {
			flex-direction: column;
		}
	}

	&.align {
		&-start {
			align-items: flex-start;
		}

		&-center {
			align-items: center;
		}

		&-end {
			align-items: flex-end;
		}
	}

	&.justify {
		&-center {
			justify-content: center;
		}

		&-end {
			justify-content: flex-end;
		}

		&-between {
			justify-content: space-between;
		}

		&-around {
			justify-content: space-around;
		}
	}

	&.wrap {
		flex-wrap: wrap;
	}

	&.nowrap {
		flex-wrap: nowrap;
	}

	&-1 {
		flex: 1;
	}
}
