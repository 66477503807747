.component-checkbox_dropdown .dropdown-menu {
  max-height: 200px;
  overflow: auto;
}
.component-checkbox_dropdown .component-dropdown_checkbox input[type=checkbox] {
  cursor: pointer;
}
.component-checkbox_dropdown .component-dropdown_checkbox label {
  margin: 0;
}
.component-checkbox_dropdown .component-dropdown_checkbox.dropdown-item {
  cursor: default;
}
.component-checkbox_dropdown .component-dropdown_checkbox.dd-master {
  border-bottom: 1px solid #dee2e6;
}
.component-checkbox_dropdown .component-dropdown_checkbox.dd-master input[type=checkbox] {
  cursor: not-allowed;
}

