@import "../../../../Stylesheets/scss/module.scss";

.current-selected-outcome {
	flex-wrap: wrap;

	span {
		color: $label-grey;

		&:last-child {
			color: black;
		}

		&:not(:first-of-type) {
			&::before {
				content: " /";
				padding: 0 10px;
				color: $label-grey;
			}
		}

		&:not(:last-of-type) {
			&:hover {
				cursor: pointer;

				.caregiver-portal & {
					color: $primary-caregiver;
				}

				.educator-portal & {
					color: $primary-educator;
				}
			}
		}
	}
}
