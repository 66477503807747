@import "../../../../Stylesheets/scss/module.scss";

.activity-list-current-card {
	background: #fff;
	width: 100%;
	border-left: 1px solid $border-grey;

	&--header {
		height: 138px;
		background: var(--backgroundColor, $irish-green-light);
		position: relative;

		.activity-title {
			color: #fff;
			position: absolute;
			font-size: 19px;
			bottom: 15px;
			left: 40px;
		}

		.save-icon {
			background: #fff;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			position: absolute;
			right: 27px;
			bottom: -20px;
			text-align: center;

			&:hover {
				cursor: pointer;
			}

			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				@include Flexbox(row, center, center);

				.spinner-icon {
					color: $label-dark-grey;
				}
			}

			&.saved {
				.heart-icon {
					color: var(--backgroundColor, $irish-green-light);
				}
			}

			&.un-saved {
				.heart-icon {
					stroke: $label-dark-grey;
					stroke-width: 50;
					border-color: $label-dark-grey;
					color: #fff;
				}
			}
		}

		.activity-completion {
			.activity-completions-count {
				height: 30px;
				color: #fff;
				top: 30px;
				right: 32px;
				width: 30px;
				background-color: #ff8f1a;
				border-radius: 20px;
				border: 2px solid #fff;
				text-align: center;
				position: absolute;

				small {
					top: 1px;
					position: relative;
				}
			}

			.activity-no-completion {
				color: #fff;
				height: 30px;
				top: 30px;
				right: 32px;
				position: absolute;

				svg {
					background: rgba(255, 255, 255, 0.9);
					border-radius: 30px;
				}
			}
		}
	}

	&--body {
		overflow-y: auto;
		height: 100%;
		max-height: calc(100% - 138px - 66px);
		padding: 15px 40px;

		.activity-info {
			height: auto;
			display: flex;
			flex-wrap: wrap;
			margin-top: 10px;

			.info-block {
				flex: 1 1 33.33%;
				max-width: 33.33%;
			}

			.material-block {
				flex: 1 1 100%;
				margin-top: 20px;
			}

			.info-block,
			.material-block {
				&--title {
					color: $label-dark-grey;
					opacity: 0.9;
					font-size: 13px;
				}

				&--content {
					font-size: 13px;
				}
			}
		}

		.activity-content {
			.title {
				margin-top: 20px;
				color: $label-dark-grey;
				opacity: 0.9;
				font-size: 13px;
			}

			img {
				max-width: 18rem;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}

			p {
				font-family: $font-family-semi-bold;
			}

			> p,
			li {
				font-family: $font-family-semi-bold;
			}

			> strong {
				font-family: $font-family-bold;
				font-weight: 700;
			}
		}
	}

	&--footer {
		border-top: 1px solid $border-grey;
		height: 66px;
		display: flex;
		justify-content: center;

		.btn {
			margin: auto;
			width: 274px;
			padding: 0.375rem 0.75rem;
			background: var(--backgroundColor, $irish-green-light);

			&--content {
				color: #fff;
				@include Flexbox(row, center, center);

				svg {
					&.spinner-icon {
						color: #fff;
					}
				}
			}

			&:hover {
				color: #fff;
			}
		}

		.footer-educator {
			@include Flexbox(row, center, center);

			.footer-buttons-educator {
				.btn {
					width: 137px;
					padding: 0.375rem 0.75rem;

					&:first-child {
						margin-right: 9px;
					}
				}
			}

			padding-left: 35px;
			padding-right: 35px;
			width: 100%;
			@include Flexbox(row, center, space-between);
			.copy-icon,
			.comment-icon,
			.hide-show-icon {
				&:hover {
					cursor: pointer;
				}

				svg {
					color: $steel-grey;
				}
			}
			.footer-icons-right {
				@include Flexbox(row, center, center);

				.copy-icon {
					margin-right: 20px;
				}
			}
			@include SetMediaQuery(bootstrap-sm) {
				.footer-icons-right,
				.hide-show-icon {
					display: none;
				}
			}
		}
	}
	@include SetMediaQuery(bootstrap-sm) {
		display: none;
	}
}
