.survey-start {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.survey-start .survey-start-asset {
  width: 50%;
  padding: 0 40px;
}
.survey-start .survey-start-asset object {
  max-width: 100%;
}
.survey-start .survey-start-content {
  width: 50%;
  padding: 0 20px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.survey-start .survey-start-content .survey-start-text {
  font-size: 14px;
  padding-bottom: 10px;
}
.survey-start .survey-start-content .survey-start-text .survey-split-content {
  padding-bottom: 10px;
}
.survey-start .survey-start-content .btn {
  width: 150px;
}

