@import "../../../../../module";

$group-spacer: 25px;

.assessment-question-table {
	width: 100%;

	.assessment-question-table-headers {
		display: grid;
		grid-template-columns: $group-spacer 40% auto;
		grid-auto-flow: column;
		width: 100%;
		border-bottom: 1px solid $border-grey;
		padding-bottom: 0.5rem;

		.assessment-question-table-header-options {
			text-align: center;
			@include Flexbox(row, center, center);

			.assessment-question-table-header {
				width: 100%;
			}
		}
	}

	.assessment-question-table-rows {
		.assessment-question-table-grouping {
			@include Flexbox(row);

			.assessment-question-table-grouping-label {
				width: $group-spacer;
				background-color: $leaf-green-bold;

				.assessment-question-rotated-label {
					transform: rotate(-90deg);
					height: 100%;
					color: white;
					white-space: nowrap;
					@include Flexbox(row, center, center);
				}
			}

			.assessment-question-table-grouping-question {
				@include Flexbox(column);

				width: 100%;
			}

			&:nth-child(2) {
				.assessment-question-table-grouping-label {
					background-color: $leaf-green-semi-bold;
				}
			}

			&:nth-child(3) {
				.assessment-question-table-grouping-label {
					background-color: $leaf-green-medium;
				}
			}
		}
	}
}
