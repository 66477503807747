@import "../../../../Stylesheets/scss/module.scss";
.filter-icon,
.list-toggle-icon {
	.icon {
		padding: 10px;
		border-radius: 10px;
		cursor: pointer;
		margin: 0;

		svg {
			color: $label-dark-grey;
		}

		&:hover {
			background-color: $border-grey;
		}
	}
}

.filter-icon {
	&.filter-disabled {
		.icon {
			opacity: 0.65;

			&:hover {
				cursor: default;
				background-color: transparent;
			}
		}
	}
}

.list-toggle-icon {
	@include SetMediaQuery(bootstrap-sm) {
		display: none;
	}
}
