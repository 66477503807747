@import "../../../../../module";

.modal-page-navigatior {
	width: 100%;
	@include Flexbox(row, center, space-between);

	.modal-page-navigator-button {
		height: 34px;
		width: 34px;
		cursor: pointer;

		&.hide-navigator {
			visibility: hidden;
		}

		svg {
			height: 100%;
			width: 100%;

			path {
				fill: $label-dark-grey;
			}

			&:hover {
				path {
					fill: $default-text-color;
				}
			}
		}
	}

	.modal-page-navigator-title {
		font-size: 12px;
	}
}
