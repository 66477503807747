.modal-page-navigatior-menu {
  position: relative;
}
.modal-page-navigatior-menu .modal-page-navigator-menu-icon {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.modal-page-navigatior-menu .modal-page-navigator-menu-icon svg {
  height: 100%;
  width: 100%;
}
.modal-page-navigatior-menu .modal-page-navigator-menu-icon svg path {
  fill: #6c757d;
}
.modal-page-navigatior-menu .modal-page-navigator-menu-icon svg:hover path {
  fill: #1e1c1a;
}
.modal-page-navigatior-menu .modal-page-navigator-menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 3001;
  max-height: 200px;
  max-width: 224px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.modal-page-navigatior-menu .modal-page-navigator-menu-dropdown .modal-page-navigator-menu-dropdown-item {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 40px;
  font-size: 80%;
}

