.portal-activity-monitor {
  position: fixed;
  top: 0;
  right: calc(50% - 210px);
  height: 100vh;
  pointer-events: none;
  z-index: 8999;
  width: 420px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .portal-activity-monitor {
    width: 100%;
    padding-left: 20px;
  }
}
@media (max-width: 375px) {
  .portal-activity-monitor {
    width: 100%;
    padding-left: 0;
    right: 0;
  }
}
.portal-activity-monitor .portal-activity-monitor-toasts {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  flex-wrap: nowrap;
  pointer-events: none !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 10px;
}
.portal-activity-monitor .portal-activity-monitor-toasts > * {
  pointer-events: auto;
}
.portal-activity-monitor .portal-activity-monitor-toasts .toast-notification,
.portal-activity-monitor .portal-activity-monitor-toasts .toast-notification-show-full-message {
  margin-bottom: 10px;
}
.portal-activity-monitor .portal-activity-monitor-toasts .toast-notification:last-child,
.portal-activity-monitor .portal-activity-monitor-toasts .toast-notification-show-full-message:last-child {
  animation: grow 0.2s 1;
}
.portal-activity-monitor .portal-activity-monitor-toasts .toast-notification .toast-notification-message,
.portal-activity-monitor .portal-activity-monitor-toasts .toast-notification-show-full-message .toast-notification-message {
  animation: show 0.4s 1;
}
.portal-activity-monitor.reading-toast-monitor {
  right: 10px;
  width: 329px;
}

@keyframes grow {
  0% {
    height: 0;
  }
  100% {
    height: 70px;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

