@import "../../../../module";

.badge-component {
	@include Flexbox(column, center, center);

	flex-shrink: 0;
	padding: 0.8rem;
	padding-bottom: 20px;
	width: 100%;

	.badge-icon {
		height: 150px;
		width: 150px;
		border-radius: 50%;
	}
}
