@import "../../../../Stylesheets/scss/module.scss";

.primary-nav-search-activity {
	display: flex;
	border-radius: 8px;
	height: 43px;
	width: 337px !important;
	z-index: inherit;

	.caregiver-portal & {
		background: $irish-green;
	}

	.educator-portal & {
		background: $eastern-blue;
	}

	.search-icon {
		display: flex;
		align-items: center;
		padding: 5px 8px 5px 18px;

		svg {
			color: #fff;
			opacity: 0.8;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.search-input {
		border: none;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		color: #fff;
		width: 100%;
		outline: none;

		.caregiver-portal & {
			background: $irish-green;
		}

		.educator-portal & {
			background: $eastern-blue;
		}

		.reading-portal & {
			background: $eastern-blue;
		}

		&::placeholder {
			color: #fff;
			letter-spacing: -0.36px;
			font-size: 15px;
			opacity: 0.8;
		}
	}

	.times-icon {
		display: flex;
		align-items: center;
		padding: 5px 8px 5px 18px;

		svg {
			color: #fff;
			opacity: 0.8;
		}

		&:hover {
			cursor: pointer;
		}
	}
}
