@import "../../../../module";

.checkbox {
	height: 20px;
	width: 20px;
	cursor: pointer;
	flex-shrink: 0;

	svg {
		height: 100%;
		width: 100% !important;

		path {
			fill: $primary-caregiver;
		}
	}

	.empty-checkbox {
		border: 1px solid $border-grey;
		border-radius: 0.2em;
		height: 100%;
		width: 100%;
	}
}
