@import "../../../../../module";

.survey-cells {
	padding: 30px 20px;

	.survey-cells-question {
		padding-bottom: 40px;

		&:last-child {
			padding-bottom: 0;
		}

		.survey-cells-question-title {
			font-size: 14px;
			padding-bottom: 20px;
		}

		.survey-cells-question-options {
			@include Grid(grid, auto, repeat(3, 33.33333%), 10px);
			@include SetMediaQuery(bootstrap-sm) {
				@include Grid(grid, auto, repeat(2, 50%), 10px);
			}

			.survey-cells-option {
				@include Flexbox(row, center);

				height: 26px;

				.survey-cell-option-label {
					font-family: $font-family-semi-bold;
					padding-left: 5px;
					@include ellipsis(calc(100% - 40px), block);
				}

				.survey-cells-editable-input {
					border: none;
					font-family: $font-family-semi-bold;

					&:focus {
						outline: none;
					}
				}
			}
		}
	}
}
