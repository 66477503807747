@import "../module.scss";

.richEditor-root {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 0.25rem;
	font-size: 14px;
	padding: 15px;
	overflow: hidden;

	.richEditor-controls {
		font-size: 14px;
		margin-bottom: 5px;
		user-select: none;

		.richEditor-styleButton {
			color: $primary-educator;
			cursor: pointer;
			margin-right: 16px;
			display: inline-block;
			border: 1px solid #ddd;
			padding: 2px 3px 0;
			border-radius: 2px;
			opacity: 0.8;

			&:hover {
				opacity: 1;
			}
		}

		.richEditor-activeButton {
			opacity: 1;
			border-color: $primary-educator;
		}
	}

	.richEditor-editor {
		box-sizing: border-box;
		border-top: 1px solid #ddd;
		cursor: text;
		font-size: 16px;
		margin-top: 10px;

		&:global(.public-DraftEditor-content) {
			min-height: 140px;
		}

		.public-DraftEditorPlaceholder-root,
		.public-DraftEditor-content {
			padding-top: 15px;
			min-height: 100px;

			figure {
				img {
					margin: 10px;
				}
			}
		}

		.richEditor-blockquote {
			border-left: 5px solid #eee;
			color: #666;
			font-style: italic;
			margin: 16px 0;
			padding: 10px 20px;
		}

		.public-DraftStyleDefault-pre {
			background-color: rgba(0, 0, 0, 0.05);
			font-size: 16px;
			padding: 20px;
		}

		.richEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
			display: none;
		}

		.draftJsToolbar__toolbar__dNtBH {
			position: absolute;
			z-index: 9999;

			button {
				border: 1px solid #ddd;
				border-radius: 10px;
			}

			input {
				width: 16rem;
				border: 1px solid #ddd;
				padding: 6px;
				border-radius: 10px;
				box-shadow: 0 4px 10px 0 rgb(220, 220, 220);
			}
		}
	}
}

.image-upload {
	input[type="file"] {
		display: none;
	}

	.custom-image-upload {
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
		color: $label-dark-grey;
		opacity: 0.8;

		&:hover {
			opacity: 1;
		}
	}
}

.video-link {
	display: flex;
	flex-direction: column;

	.custom-video-link {
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
		color: $primary-educator;
		opacity: 0.8;

		&:hover {
			opacity: 1;
		}
	}

	.video-url-input {
		display: none;
		width: 18rem;
		border: 1px solid #ddd;
		padding: 9px;
		border-radius: 3px;
		box-shadow: 0 4px 10px 0 rgb(220, 220, 220);
		background: transparent;
		z-index: 1;

		&.open {
			display: inline;
		}

		&.no-value {
			input {
				border-color: red;
			}
		}

		input {
			border: 1px solid #ddd;
			border-radius: 5px;
		}

		span {
			border-radius: 10px;
			margin-left: 10px;
			cursor: pointer;
			color: $primary-educator;
			opacity: 0.8;

			&:hover {
				opacity: 1;
			}
		}
	}
}
