.no-results-found-component {
	text-align: center;
	margin: auto;
	margin-top: 170px;
	color: #717981;

	.no-results-found-icon {
		.icon {
			svg {
				color: #717981;
			}
		}
	}

	.no-results-found-title {
		margin: 10px 0;
	}

	.no-results-found-description {
		max-width: 315px;
		margin: auto;
		font-family: "nunitosemibold";
		font-size: smaller;
	}
}
