@import "../../../../../module";

.assessment-question-section {
	.assessment-question-title {
		margin-bottom: 0;
		padding-top: 1rem;
		padding-bottom: 0.5rem;
	}

	.assessment-question-description {
		font-family: $font-family-semi-bold;
		width: 50%;
		margin-bottom: 30px;
	}

	.assessment-question-visuals {
		.question-visual {
			&:nth-child(even) {
				background-color: rgba(222, 226, 230, 0.2);

				.question-visual-assets {
					border-top: 1px solid $border-grey;
					border-bottom: 1px solid $border-grey;
				}
			}

			&:nth-child(2) {
				.question-visual-group {
					background-color: $leaf-green-semi-bold;
				}
			}

			&:nth-child(3) {
				.question-visual-group {
					background-color: $leaf-green-medium;
				}
			}
		}
	}
}
