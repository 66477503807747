@import "../../../Stylesheets/scss/module";

.dropdown-wrapper {
	position: relative;

	.dropdown-wrapper--parent {
		cursor: pointer;
	}

	.dropdown-wrapper--menu {
		position: absolute;
		border: 1px solid transparent;
		border-radius: 12px;
		background-color: transparent;
		max-height: 0;
		top: 100%;
		left: 0;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), border 0.3s ease,
			background-color 0.5s ease;
		overflow: hidden;

		.dropdown-wrapper--menu-item {
			white-space: nowrap;
			max-width: 600px;
			border-bottom: 1px solid $border-grey;

			&:last-child {
				border-bottom: none;
			}

			.navigation-primary--primary-dropdown & {
				.dropdown-menu-item {
					border-bottom: 1px solid $border-grey;
					padding: 10px 20px;
					width: 240px;
					@include Flexbox(row, center, center);
				}

				.dropdown-title {
					border-bottom: none;
				}

				.dropdown-footer {
					border-bottom: none;
					padding: 10px 20px;

					.btn {
						width: 100%;
						max-height: 40px;
						@include Flexbox(row, center, center);

						.btn--content {
							font-family: "nunitobold";
						}
						&.upgrade {
							background-color: $american-green;
							a {
								color: #fff;
							}
						}
					}

					.add-classroom-disabled {
						width: 180px;
						text-align: center;
						white-space: pre-wrap;
					}
				}

				&:nth-child(2) {
					max-height: calc(
						90vh - 160px
					); // 56px for navigation + 104px for menu header + footer
					overflow-y: auto;
					overflow-x: hidden;
				}
			}
		}

		&.menu-open {
			z-index: 1;
			max-height: 1000px;
			border: 1px solid $border-grey;
			background-color: white;
			transition: max-height 0.75s ease-in-out, border 0.3s ease;
		}
	}

	&--se {
		.dropdown-wrapper--menu {
			right: auto;
			left: 0;
		}
	}

	&--sw {
		.dropdown-wrapper--menu {
			right: 0;
			left: auto;
		}
	}

	&--s {
		.dropdown-wrapper--menu {
			left: 50%;
			transform: translate(-50%, 0);
		}
	}

	&--nw {
		.dropdown-wrapper--menu {
			left: 50%;
			transform: translate(-100%, -130%);
		}
	}
}
