@import "../../../../module";

.dropdown-wrapper {
	position: relative;

	.dropdown-wrapper-content {
		position: absolute;
		right: 0;
		top: 100%;
		z-index: 1;

		.dropdown-wrapper-menu {
			position: relative;
			z-index: 1;
			border-radius: 0.5rem;
			background-color: white;
			overflow: hidden;
			border: 1px solid $border-grey;
			padding: 10px 0;
		}
	}
}
