@import "../../../../module";

.sprig-visual {
	height: 100%;
	width: 100%;
	padding: 20px 0 40px 0;
	@include Flexbox(row, flex-end);

	position: relative;

	.tree-activity-metrics {
		position: absolute;
		right: 50%;
		transform: translate(50%, 0);
		top: 20px;
		@include Flexbox(row);
		@include SetMediaQuery(bootstrap-sm) {
			right: 160px;
		}
		@include SetMediaQuery(bootstrap-md) {
			right: 140px;
		}

		.tree-activity-metric {
			margin-right: 5px;

			.tree-activity-metric-circle {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				color: #000;
				background-color: #fff;

				.tree-activity-metric-icon {
					text-align: center;
					position: relative;
					top: 5px;

					img {
						width: 15px;
					}
				}

				.tree-activity-metric-data {
					text-align: center;
					font-family: "nunitosemibold";
					position: relative;
					top: 5px;

					&-small-font-size {
						text-align: center;
						font-family: "nunitosemibold";
						position: relative;
						top: 5px;
						font-size: 0.9rem;
					}
				}
			}

			.tree-activity-metric-title {
				color: #fff;
				text-align: center;
				font-family: "nunitosemibold";
				font-size: small;
			}
		}
	}

	.sticker-toggle {
		position: absolute;
		right: 20px;
		top: 20px;
	}

	.sprig-tree {
		height: 100%;
		width: 100%;
		@include SprigTreeSlice();

		#scale {
			.scale-tick {
				fill: white;
				opacity: 0.5;
			}
		}

		#scale-length {
			fill: white;
			opacity: 0.5;
		}

		.scale-arrow {
			fill: white;
		}

		.mask-animation {
			transition: transform 1s ease;
			transform-origin: 50% 50%;
			animation: PulseTree 1s 3;
		}
	}

	.sprig-tree-leaf {
		position: absolute;
		height: 45px;
		width: 60px;
		pointer-events: none;
		transform: translate(-50%, -25%);
		transform-origin: 50% 50%;
		opacity: 1;
		transition: all 0.5s ease;
		animation: ScaleIn 5s 1;
		@include SetMediaQuery(bootstrap-sm) {
			height: 21px;
			width: 28px;
		}
		@include SetMediaQuery(bootstrap-md) {
			height: 30px;
			width: 40px;
		}

		&.leaf-animation {
			.sprig-tree-leaf-animation {
				animation: ShakeTop 3s infinite 5.5s;
			}
		}

		.sprig-tree-leaf-tooltip {
			position: relative;

			svg {
				path {
					stroke: white;
					stroke-width: 3px;
					pointer-events: auto;
				}

				circle {
					transition: stroke-dashoffset 0.35s;
				}
			}

			.sprig-leaf-tooltip {
				opacity: 0;
				transition: opacity 0.5s ease;
				position: absolute;
				padding: 5px;
				bottom: 100%;
				left: calc(50% - 100px);
				width: 200px;
				background-color: rgba(0, 0, 0, 0.8);
				pointer-events: none;
				color: white;
				text-align: center;
				border-radius: 5px;

				.tooltip-arrow {
					position: absolute;
					width: 0;
					height: 0;
					top: 100%;
					left: calc(50% - 10px);
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 5px solid rgba(0, 0, 0, 0.8);
				}
			}
		}

		&:hover {
			.sprig-tree-leaf-tooltip {
				.sprig-leaf-tooltip {
					opacity: 1;
				}
			}
		}
	}

	#sprig-tree-leaf {
		&0 {
			svg {
				path {
					fill: $leaf-green-darkest;
				}
			}
		}

		&1 {
			svg {
				path {
					fill: $leaf-green-bold;
				}
			}
		}

		&2 {
			svg {
				path {
					fill: $leaf-green-semi-bold;
				}
			}
		}

		&3 {
			svg {
				path {
					fill: $leaf-green-medium;
				}
			}
		}

		&4 {
			svg {
				path {
					fill: $leaf-green-base;
				}
			}
		}

		&5 {
			svg {
				path {
					fill: $leaf-green-lightest;
				}
			}
		}
	}

	@mixin Tree5Colors {
		.cls-1,
		.cls-40,
		.cls-41,
		.cls-47,
		.cls-48,
		.cls-59 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #a4836b;
		}

		.cls-6 {
			fill: #d1c1b5;
		}

		.cls-7 {
			fill: #fff;
		}

		.cls-8 {
			fill: #bba290;
		}

		.cls-9 {
			clip-path: url(#clip-path);
		}

		.cls-10,
		.cls-46,
		.cls-49 {
			fill: #3b292d;
		}

		.cls-10 {
			opacity: 0.1;
		}

		.cls-10,
		.cls-15,
		.cls-17,
		.cls-29,
		.cls-32,
		.cls-39,
		.cls-42,
		.cls-46,
		.cls-49,
		.cls-51 {
			mix-blend-mode: multiply;
		}

		.cls-11 {
			fill: #1e1c1a;
		}

		.cls-12 {
			fill: #574545;
		}

		.cls-13 {
			fill: #ded5c0;
		}

		.cls-14 {
			fill: #b9afa6;
		}

		.cls-15,
		.cls-18,
		.cls-29 {
			fill: #682e52;
		}

		.cls-15,
		.cls-17,
		.cls-42,
		.cls-49,
		.cls-51 {
			opacity: 0.2;
		}

		.cls-16 {
			fill: #199b32;
		}

		.cls-19 {
			fill: #43b649;
		}

		.cls-20 {
			fill: #69d746;
		}

		.cls-21 {
			fill: #c3beb9;
		}

		.cls-22 {
			fill: #605a55;
		}

		.cls-23 {
			fill: #807b77;
		}

		.cls-24,
		.cls-53 {
			fill: #fff8dc;
		}

		.cls-25 {
			fill: #bc7244;
		}

		.cls-26 {
			fill: #ffd346;
		}

		.cls-27 {
			fill: #ffea4d;
		}

		.cls-28 {
			fill: #947358;
		}

		.cls-29,
		.cls-32 {
			opacity: 0.15;
		}

		.cls-30 {
			fill: #cea776;
		}

		.cls-31 {
			fill: #fcd8dd;
		}

		.cls-33 {
			fill: #735f50;
		}

		.cls-34 {
			fill: #967c6e;
		}

		.cls-35 {
			fill: #e65e49;
		}

		.cls-36 {
			fill: #82f0ff;
		}

		.cls-37 {
			fill: #a7f4ff;
		}

		.cls-38 {
			fill: #f9a136;
		}

		.cls-39,
		.cls-46 {
			opacity: 0.3;
		}

		.cls-40,
		.cls-41 {
			stroke: #000;
		}

		.cls-40,
		.cls-41,
		.cls-47,
		.cls-48,
		.cls-59 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-40,
		.cls-47 {
			stroke-width: 2px;
		}

		.cls-41,
		.cls-48 {
			stroke-width: 4px;
		}

		.cls-43,
		.cls-53 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-44 {
			fill: #c39c79;
		}

		.cls-45 {
			fill: #373126;
		}

		.cls-47,
		.cls-48 {
			stroke: #1e1c1a;
		}

		.cls-50 {
			fill: #17c8bb;
		}

		.cls-51 {
			fill: #654935;
		}

		.cls-52 {
			fill: #7c5b40;
		}

		.cls-54 {
			fill: #533b26;
		}

		.cls-55 {
			fill: #f04e49;
		}

		.cls-56 {
			fill: #e5be83;
		}

		.cls-57 {
			fill: #bc8040;
		}

		.cls-58 {
			fill: #acd149;
		}

		.cls-59 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}

	@mixin Tree6Colors {
		.cls-1,
		.cls-40,
		.cls-41,
		.cls-47,
		.cls-48,
		.cls-59 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #a4836b;
		}

		.cls-6 {
			fill: #d1c1b5;
		}

		.cls-7 {
			fill: #fff;
		}

		.cls-8 {
			fill: #bba290;
		}

		.cls-9 {
			clip-path: url(#clip-path);
		}

		.cls-10,
		.cls-46,
		.cls-49 {
			fill: #3b292d;
		}

		.cls-10 {
			opacity: 0.1;
		}

		.cls-10,
		.cls-15,
		.cls-17,
		.cls-29,
		.cls-35,
		.cls-39,
		.cls-42,
		.cls-46,
		.cls-49,
		.cls-51 {
			mix-blend-mode: multiply;
		}

		.cls-11 {
			fill: #1e1c1a;
		}

		.cls-12 {
			fill: #574545;
		}

		.cls-13 {
			fill: #ded5c0;
		}

		.cls-14 {
			fill: #b9afa6;
		}

		.cls-15,
		.cls-18,
		.cls-29 {
			fill: #682e52;
		}

		.cls-15,
		.cls-17,
		.cls-42,
		.cls-49,
		.cls-51 {
			opacity: 0.2;
		}

		.cls-16 {
			fill: #199b32;
		}

		.cls-19 {
			fill: #43b649;
		}

		.cls-20 {
			fill: #69d746;
		}

		.cls-21 {
			fill: #c3beb9;
		}

		.cls-22 {
			fill: #605a55;
		}

		.cls-23 {
			fill: #807b77;
		}

		.cls-24,
		.cls-53 {
			fill: #fff8dc;
		}

		.cls-25 {
			fill: #bc7244;
		}

		.cls-26 {
			fill: #ffd346;
		}

		.cls-27 {
			fill: #ffea4d;
		}

		.cls-28 {
			fill: #947358;
		}

		.cls-29,
		.cls-35 {
			opacity: 0.15;
		}

		.cls-30 {
			fill: #cea776;
		}

		.cls-31 {
			fill: #fcd8dd;
		}

		.cls-32 {
			fill: #82f0ff;
		}

		.cls-33 {
			fill: #a7f4ff;
		}

		.cls-34 {
			fill: #f9a136;
		}

		.cls-36 {
			fill: #735f50;
		}

		.cls-37 {
			fill: #967c6e;
		}

		.cls-38 {
			fill: #e65e49;
		}

		.cls-39,
		.cls-46 {
			opacity: 0.3;
		}

		.cls-40,
		.cls-41 {
			stroke: #000;
		}

		.cls-40,
		.cls-41,
		.cls-47,
		.cls-48,
		.cls-59 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-40,
		.cls-47 {
			stroke-width: 2px;
		}

		.cls-41,
		.cls-48 {
			stroke-width: 4px;
		}

		.cls-43,
		.cls-53 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-44 {
			fill: #c39c79;
		}

		.cls-45 {
			fill: #373126;
		}

		.cls-47,
		.cls-48 {
			stroke: #1e1c1a;
		}

		.cls-50 {
			fill: #17c8bb;
		}

		.cls-51 {
			fill: #654935;
		}

		.cls-52 {
			fill: #7c5b40;
		}

		.cls-54 {
			fill: #533b26;
		}

		.cls-55 {
			fill: #f04e49;
		}

		.cls-56 {
			fill: #bc8040;
		}

		.cls-57 {
			fill: #acd149;
		}

		.cls-58 {
			fill: #e5be83;
		}

		.cls-59 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}

	@mixin Tree7Colors {
		.cls-1,
		.cls-42,
		.cls-43,
		.cls-49,
		.cls-50,
		.cls-63 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #f5f0f0;
		}

		.cls-6 {
			clip-path: url(#clip-path);
		}

		.cls-7 {
			fill: #bba290;
		}

		.cls-48,
		.cls-51,
		.cls-8 {
			fill: #3b292d;
		}

		.cls-8 {
			opacity: 0.1;
		}

		.cls-17,
		.cls-19,
		.cls-31,
		.cls-37,
		.cls-41,
		.cls-44,
		.cls-48,
		.cls-51,
		.cls-53,
		.cls-8 {
			mix-blend-mode: multiply;
		}

		.cls-58,
		.cls-9 {
			fill: #fff;
		}

		.cls-10 {
			fill: #d1c1b5;
		}

		.cls-11 {
			clip-path: url(#clip-path-2);
		}

		.cls-12 {
			fill: #a4836b;
		}

		.cls-13 {
			fill: #1e1c1a;
		}

		.cls-14 {
			fill: #574545;
		}

		.cls-15 {
			fill: #ded5c0;
		}

		.cls-16 {
			fill: #b9afa6;
		}

		.cls-17,
		.cls-20,
		.cls-31 {
			fill: #682e52;
		}

		.cls-17,
		.cls-19,
		.cls-44,
		.cls-51,
		.cls-53 {
			opacity: 0.2;
		}

		.cls-18 {
			fill: #199b32;
		}

		.cls-21 {
			fill: #43b649;
		}

		.cls-22 {
			fill: #69d746;
		}

		.cls-23 {
			fill: #c3beb9;
		}

		.cls-24 {
			fill: #605a55;
		}

		.cls-25 {
			fill: #807b77;
		}

		.cls-26,
		.cls-55 {
			fill: #fff8dc;
		}

		.cls-27 {
			fill: #bc7244;
		}

		.cls-28 {
			fill: #ffd346;
		}

		.cls-29 {
			fill: #ffea4d;
		}

		.cls-30 {
			fill: #947358;
		}

		.cls-31,
		.cls-37,
		.cls-58 {
			opacity: 0.15;
		}

		.cls-32 {
			fill: #cea776;
		}

		.cls-33 {
			fill: #fcd8dd;
		}

		.cls-34 {
			fill: #82f0ff;
		}

		.cls-35 {
			fill: #a7f4ff;
		}

		.cls-36 {
			fill: #f9a136;
		}

		.cls-38 {
			fill: #735f50;
		}

		.cls-39 {
			fill: #967c6e;
		}

		.cls-40 {
			fill: #e65e49;
		}

		.cls-41,
		.cls-48 {
			opacity: 0.3;
		}

		.cls-42,
		.cls-43 {
			stroke: #000;
		}

		.cls-42,
		.cls-43,
		.cls-49,
		.cls-50,
		.cls-63 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-42,
		.cls-49 {
			stroke-width: 2px;
		}

		.cls-43,
		.cls-50 {
			stroke-width: 4px;
		}

		.cls-45,
		.cls-55 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-46 {
			fill: #c39c79;
		}

		.cls-47 {
			fill: #373126;
		}

		.cls-49,
		.cls-50 {
			stroke: #1e1c1a;
		}

		.cls-52 {
			fill: #17c8bb;
		}

		.cls-53 {
			fill: #654935;
		}

		.cls-54 {
			fill: #7c5b40;
		}

		.cls-56 {
			fill: #533b26;
		}

		.cls-57 {
			fill: #f04e49;
		}

		.cls-58 {
			mix-blend-mode: screen;
		}

		.cls-59 {
			fill: #46d2fd;
		}

		.cls-60 {
			fill: #bc8040;
		}

		.cls-61 {
			fill: #acd149;
		}

		.cls-62 {
			fill: #e5be83;
		}

		.cls-63 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}

	@mixin Tree8Colors {
		.cls-1,
		.cls-41,
		.cls-42,
		.cls-48,
		.cls-49,
		.cls-64 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #f5f0f0;
		}

		.cls-6 {
			clip-path: url(#clip-path);
		}

		.cls-7 {
			fill: #bba290;
		}

		.cls-47,
		.cls-50,
		.cls-8 {
			fill: #3b292d;
		}

		.cls-8 {
			opacity: 0.1;
		}

		.cls-16,
		.cls-18,
		.cls-30,
		.cls-36,
		.cls-40,
		.cls-43,
		.cls-47,
		.cls-50,
		.cls-52,
		.cls-8 {
			mix-blend-mode: multiply;
		}

		.cls-9 {
			fill: #d1c1b5;
		}

		.cls-10,
		.cls-57,
		.cls-62 {
			fill: #fff;
		}

		.cls-11 {
			clip-path: url(#clip-path-2);
		}

		.cls-12 {
			fill: #1e1c1a;
		}

		.cls-13 {
			fill: #574545;
		}

		.cls-14 {
			fill: #ded5c0;
		}

		.cls-15 {
			fill: #b9afa6;
		}

		.cls-16,
		.cls-19,
		.cls-30 {
			fill: #682e52;
		}

		.cls-16,
		.cls-18,
		.cls-43,
		.cls-50,
		.cls-52 {
			opacity: 0.2;
		}

		.cls-17 {
			fill: #199b32;
		}

		.cls-20 {
			fill: #43b649;
		}

		.cls-21 {
			fill: #69d746;
		}

		.cls-22 {
			fill: #c3beb9;
		}

		.cls-23 {
			fill: #605a55;
		}

		.cls-24 {
			fill: #807b77;
		}

		.cls-25,
		.cls-54 {
			fill: #fff8dc;
		}

		.cls-26 {
			fill: #bc7244;
		}

		.cls-27 {
			fill: #ffd346;
		}

		.cls-28 {
			fill: #ffea4d;
		}

		.cls-29 {
			fill: #947358;
		}

		.cls-30,
		.cls-36,
		.cls-57 {
			opacity: 0.15;
		}

		.cls-31 {
			fill: #cea776;
		}

		.cls-32 {
			fill: #fcd8dd;
		}

		.cls-33 {
			fill: #82f0ff;
		}

		.cls-34 {
			fill: #a7f4ff;
		}

		.cls-35 {
			fill: #f9a136;
		}

		.cls-37 {
			fill: #735f50;
		}

		.cls-38 {
			fill: #967c6e;
		}

		.cls-39 {
			fill: #e65e49;
		}

		.cls-40,
		.cls-47,
		.cls-62 {
			opacity: 0.3;
		}

		.cls-41,
		.cls-42 {
			stroke: #000;
		}

		.cls-41,
		.cls-42,
		.cls-48,
		.cls-49,
		.cls-64 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-41,
		.cls-48 {
			stroke-width: 2px;
		}

		.cls-42,
		.cls-49 {
			stroke-width: 4px;
		}

		.cls-44,
		.cls-54 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-45 {
			fill: #c39c79;
		}

		.cls-46 {
			fill: #373126;
		}

		.cls-48,
		.cls-49 {
			stroke: #1e1c1a;
		}

		.cls-51 {
			fill: #17c8bb;
		}

		.cls-52 {
			fill: #654935;
		}

		.cls-53 {
			fill: #7c5b40;
		}

		.cls-55 {
			fill: #533b26;
		}

		.cls-56 {
			fill: #f04e49;
		}

		.cls-57,
		.cls-62 {
			mix-blend-mode: screen;
		}

		.cls-58 {
			fill: #46d2fd;
		}

		.cls-59 {
			fill: #bc8040;
		}

		.cls-60 {
			fill: #acd149;
		}

		.cls-61 {
			fill: #e5be83;
		}

		.cls-63 {
			fill: #f7a28c;
		}

		.cls-64 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}

	@mixin Tree9Colors {
		.cls-1,
		.cls-42,
		.cls-43,
		.cls-49,
		.cls-50,
		.cls-65 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #a4836b;
		}

		.cls-6 {
			fill: #f5f0f0;
		}

		.cls-7 {
			clip-path: url(#clip-path);
		}

		.cls-8 {
			fill: #bba290;
		}

		.cls-48,
		.cls-51,
		.cls-9 {
			fill: #3b292d;
		}

		.cls-9 {
			opacity: 0.1;
		}

		.cls-17,
		.cls-19,
		.cls-31,
		.cls-37,
		.cls-41,
		.cls-44,
		.cls-48,
		.cls-51,
		.cls-53,
		.cls-9 {
			mix-blend-mode: multiply;
		}

		.cls-10,
		.cls-58,
		.cls-63 {
			fill: #fff;
		}

		.cls-11 {
			fill: #d1c1b5;
		}

		.cls-12 {
			clip-path: url(#clip-path-2);
		}

		.cls-13 {
			fill: #1e1c1a;
		}

		.cls-14 {
			fill: #574545;
		}

		.cls-15 {
			fill: #ded5c0;
		}

		.cls-16 {
			fill: #b9afa6;
		}

		.cls-17,
		.cls-20,
		.cls-31 {
			fill: #682e52;
		}

		.cls-17,
		.cls-19,
		.cls-44,
		.cls-51,
		.cls-53 {
			opacity: 0.2;
		}

		.cls-18 {
			fill: #199b32;
		}

		.cls-21 {
			fill: #43b649;
		}

		.cls-22 {
			fill: #69d746;
		}

		.cls-23 {
			fill: #c3beb9;
		}

		.cls-24 {
			fill: #605a55;
		}

		.cls-25 {
			fill: #807b77;
		}

		.cls-26,
		.cls-55 {
			fill: #fff8dc;
		}

		.cls-27 {
			fill: #bc7244;
		}

		.cls-28 {
			fill: #ffd346;
		}

		.cls-29 {
			fill: #ffea4d;
		}

		.cls-30 {
			fill: #947358;
		}

		.cls-31,
		.cls-37,
		.cls-58 {
			opacity: 0.15;
		}

		.cls-32 {
			fill: #cea776;
		}

		.cls-33 {
			fill: #fcd8dd;
		}

		.cls-34 {
			fill: #82f0ff;
		}

		.cls-35 {
			fill: #a7f4ff;
		}

		.cls-36 {
			fill: #f9a136;
		}

		.cls-38 {
			fill: #735f50;
		}

		.cls-39 {
			fill: #967c6e;
		}

		.cls-40 {
			fill: #e65e49;
		}

		.cls-41,
		.cls-48,
		.cls-63 {
			opacity: 0.3;
		}

		.cls-42,
		.cls-43 {
			stroke: #000;
		}

		.cls-42,
		.cls-43,
		.cls-49,
		.cls-50,
		.cls-65 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-42,
		.cls-49 {
			stroke-width: 2px;
		}

		.cls-43,
		.cls-50 {
			stroke-width: 4px;
		}

		.cls-45,
		.cls-55 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-46 {
			fill: #c39c79;
		}

		.cls-47 {
			fill: #373126;
		}

		.cls-49,
		.cls-50 {
			stroke: #1e1c1a;
		}

		.cls-52 {
			fill: #17c8bb;
		}

		.cls-53 {
			fill: #654935;
		}

		.cls-54 {
			fill: #7c5b40;
		}

		.cls-56 {
			fill: #533b26;
		}

		.cls-57 {
			fill: #f04e49;
		}

		.cls-58,
		.cls-63 {
			mix-blend-mode: screen;
		}

		.cls-59 {
			fill: #bc8040;
		}

		.cls-60 {
			fill: #acd149;
		}

		.cls-61 {
			fill: #e5be83;
		}

		.cls-62 {
			fill: #46d2fd;
		}

		.cls-64 {
			fill: #f7a28c;
		}

		.cls-65 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}

	@mixin Tree10Colors {
		.cls-1,
		.cls-44,
		.cls-45,
		.cls-51,
		.cls-52,
		.cls-67 {
			fill: none;
		}

		.cls-2 {
			isolation: isolate;
		}

		.cls-3 {
			fill: #98e866;
		}

		.cls-4 {
			fill: #8ee164;
		}

		.cls-5 {
			fill: #f5f0f0;
		}

		.cls-6 {
			clip-path: url(#clip-path);
		}

		.cls-7 {
			fill: #bba290;
		}

		.cls-50,
		.cls-53,
		.cls-8 {
			fill: #3b292d;
		}

		.cls-8 {
			opacity: 0.1;
		}

		.cls-19,
		.cls-21,
		.cls-33,
		.cls-39,
		.cls-43,
		.cls-46,
		.cls-50,
		.cls-53,
		.cls-55,
		.cls-8 {
			mix-blend-mode: multiply;
		}

		.cls-9 {
			clip-path: url(#clip-path-2);
		}

		.cls-10 {
			fill: #d1c1b5;
		}

		.cls-11 {
			fill: #a4836b;
		}

		.cls-12 {
			clip-path: url(#clip-path-3);
		}

		.cls-13,
		.cls-60,
		.cls-65 {
			fill: #fff;
		}

		.cls-14 {
			clip-path: url(#clip-path-4);
		}

		.cls-15 {
			fill: #1e1c1a;
		}

		.cls-16 {
			fill: #574545;
		}

		.cls-17 {
			fill: #ded5c0;
		}

		.cls-18 {
			fill: #b9afa6;
		}

		.cls-19,
		.cls-22,
		.cls-33 {
			fill: #682e52;
		}

		.cls-19,
		.cls-21,
		.cls-46,
		.cls-53,
		.cls-55 {
			opacity: 0.2;
		}

		.cls-20 {
			fill: #199b32;
		}

		.cls-23 {
			fill: #43b649;
		}

		.cls-24 {
			fill: #69d746;
		}

		.cls-25 {
			fill: #c3beb9;
		}

		.cls-26 {
			fill: #605a55;
		}

		.cls-27 {
			fill: #807b77;
		}

		.cls-28,
		.cls-57 {
			fill: #fff8dc;
		}

		.cls-29 {
			fill: #bc7244;
		}

		.cls-30 {
			fill: #ffd346;
		}

		.cls-31 {
			fill: #ffea4d;
		}

		.cls-32 {
			fill: #947358;
		}

		.cls-33,
		.cls-39,
		.cls-60 {
			opacity: 0.15;
		}

		.cls-34 {
			fill: #cea776;
		}

		.cls-35 {
			fill: #fcd8dd;
		}

		.cls-36 {
			fill: #82f0ff;
		}

		.cls-37 {
			fill: #a7f4ff;
		}

		.cls-38 {
			fill: #f9a136;
		}

		.cls-40 {
			fill: #735f50;
		}

		.cls-41 {
			fill: #967c6e;
		}

		.cls-42 {
			fill: #e65e49;
		}

		.cls-43,
		.cls-50,
		.cls-65 {
			opacity: 0.3;
		}

		.cls-44,
		.cls-45 {
			stroke: #000;
		}

		.cls-44,
		.cls-45,
		.cls-51,
		.cls-52,
		.cls-67 {
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.cls-44,
		.cls-51 {
			stroke-width: 2px;
		}

		.cls-45,
		.cls-52 {
			stroke-width: 4px;
		}

		.cls-47,
		.cls-57 {
			opacity: 0.4;
			mix-blend-mode: overlay;
		}

		.cls-48 {
			fill: #c39c79;
		}

		.cls-49 {
			fill: #373126;
		}

		.cls-51,
		.cls-52 {
			stroke: #1e1c1a;
		}

		.cls-54 {
			fill: #17c8bb;
		}

		.cls-55 {
			fill: #654935;
		}

		.cls-56 {
			fill: #7c5b40;
		}

		.cls-58 {
			fill: #533b26;
		}

		.cls-59 {
			fill: #f04e49;
		}

		.cls-60,
		.cls-65 {
			mix-blend-mode: screen;
		}

		.cls-61 {
			fill: #46d2fd;
		}

		.cls-62 {
			fill: #bc8040;
		}

		.cls-63 {
			fill: #acd149;
		}

		.cls-64 {
			fill: #e5be83;
		}

		.cls-66 {
			fill: #f7a28c;
		}

		.cls-67 {
			stroke: #fff;
			stroke-width: 6px;
		}
	}

	&.domain-4 {
		&.tree-level- {
			&1 {
				.sprig-tree {
					#antle,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #d28c32;
						}

						.cls-5 {
							fill: #69d746;
							stroke: #fff;
						}

						.cls-17,
						.cls-18,
						.cls-24,
						.cls-25,
						.cls-5 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-18,
						.cls-25,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #c3beb9;
						}

						.cls-15,
						.cls-19,
						.cls-26,
						.cls-28,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-15,
						.cls-16,
						.cls-19,
						.cls-23,
						.cls-26,
						.cls-28,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-15,
						.cls-9 {
							fill: #682e52;
						}

						.cls-10 {
							fill: #fff;
						}

						.cls-11 {
							fill: #605a55;
						}

						.cls-12 {
							fill: #807b77;
						}

						.cls-13,
						.cls-30 {
							fill: #fff8dc;
						}

						.cls-14 {
							fill: #bc7244;
						}

						.cls-16,
						.cls-23 {
							opacity: 0.3;
						}

						.cls-17,
						.cls-18,
						.cls-24,
						.cls-25 {
							fill: none;
						}

						.cls-17,
						.cls-18 {
							stroke: #000;
						}

						.cls-17,
						.cls-24 {
							stroke-width: 2px;
						}

						.cls-20,
						.cls-30 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-21 {
							fill: #c39c79;
						}

						.cls-22 {
							fill: #373126;
						}

						.cls-23,
						.cls-26 {
							fill: #3b292d;
						}

						.cls-24,
						.cls-25 {
							stroke: #1e1c1a;
						}

						.cls-27 {
							fill: #17c8bb;
						}

						.cls-28 {
							fill: #654935;
						}

						.cls-29 {
							fill: #7c5b40;
						}

						.cls-31 {
							fill: #947358;
						}

						.cls-32 {
							fill: #533b26;
						}

						.cls-33 {
							fill: #bc8040;
						}

						.cls-34 {
							fill: #acd149;
						}
					}
				}
			}

			&2 {
				.sprig-tree {
					#antle,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #d2b446;
						}

						.cls-5 {
							fill: #69d746;
							stroke: #fff;
						}

						.cls-19,
						.cls-20,
						.cls-26,
						.cls-27,
						.cls-5 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-20,
						.cls-27,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #c3beb9;
						}

						.cls-15,
						.cls-21,
						.cls-28,
						.cls-30,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-15,
						.cls-18,
						.cls-21,
						.cls-25,
						.cls-28,
						.cls-30,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-15,
						.cls-9 {
							fill: #682e52;
						}

						.cls-10 {
							fill: #fff;
						}

						.cls-11 {
							fill: #605a55;
						}

						.cls-12 {
							fill: #807b77;
						}

						.cls-13,
						.cls-32 {
							fill: #fff8dc;
						}

						.cls-14 {
							fill: #bc7244;
						}

						.cls-16 {
							fill: #ffd346;
						}

						.cls-17 {
							fill: #ffea4d;
						}

						.cls-18,
						.cls-25 {
							opacity: 0.3;
						}

						.cls-19,
						.cls-20,
						.cls-26,
						.cls-27 {
							fill: none;
						}

						.cls-19,
						.cls-20 {
							stroke: #000;
						}

						.cls-19,
						.cls-26 {
							stroke-width: 2px;
						}

						.cls-22,
						.cls-32 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-23 {
							fill: #c39c79;
						}

						.cls-24 {
							fill: #373126;
						}

						.cls-25,
						.cls-28 {
							fill: #3b292d;
						}

						.cls-26,
						.cls-27 {
							stroke: #1e1c1a;
						}

						.cls-29 {
							fill: #17c8bb;
						}

						.cls-30 {
							fill: #654935;
						}

						.cls-31 {
							fill: #7c5b40;
						}

						.cls-33 {
							fill: #947358;
						}

						.cls-34 {
							fill: #533b26;
						}

						.cls-35 {
							fill: #bc8040;
						}

						.cls-36 {
							fill: #acd149;
						}
					}
				}
			}

			&3 {
				.sprig-tree {
					#antle,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #e6d250;
						}

						.cls-12,
						.cls-5 {
							fill: #69d746;
						}

						.cls-5 {
							stroke: #fff;
						}

						.cls-27,
						.cls-28,
						.cls-34,
						.cls-35,
						.cls-5 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-28,
						.cls-35,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #199b32;
						}

						.cls-10,
						.cls-29,
						.cls-36,
						.cls-38,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-10,
						.cls-21,
						.cls-25,
						.cls-26,
						.cls-29,
						.cls-33,
						.cls-36,
						.cls-38,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-10,
						.cls-25,
						.cls-9 {
							fill: #682e52;
						}

						.cls-11 {
							fill: #43b649;
						}

						.cls-13 {
							fill: #c3beb9;
						}

						.cls-14 {
							fill: #fff;
						}

						.cls-15 {
							fill: #605a55;
						}

						.cls-16 {
							fill: #807b77;
						}

						.cls-17,
						.cls-40 {
							fill: #fff8dc;
						}

						.cls-18 {
							fill: #bc7244;
						}

						.cls-19 {
							fill: #ffd346;
						}

						.cls-20 {
							fill: #ffea4d;
						}

						.cls-21,
						.cls-25 {
							opacity: 0.15;
						}

						.cls-22 {
							fill: #82f0ff;
						}

						.cls-23 {
							fill: #a7f4ff;
						}

						.cls-24 {
							fill: #f9a136;
						}

						.cls-26,
						.cls-33 {
							opacity: 0.3;
						}

						.cls-27,
						.cls-28,
						.cls-34,
						.cls-35 {
							fill: none;
						}

						.cls-27,
						.cls-28 {
							stroke: #000;
						}

						.cls-27,
						.cls-34 {
							stroke-width: 2px;
						}

						.cls-30,
						.cls-40 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-31 {
							fill: #c39c79;
						}

						.cls-32 {
							fill: #373126;
						}

						.cls-33,
						.cls-36 {
							fill: #3b292d;
						}

						.cls-34,
						.cls-35 {
							stroke: #1e1c1a;
						}

						.cls-37 {
							fill: #17c8bb;
						}

						.cls-38 {
							fill: #654935;
						}

						.cls-39 {
							fill: #7c5b40;
						}

						.cls-41 {
							fill: #947358;
						}

						.cls-42 {
							fill: #533b26;
						}

						.cls-43 {
							fill: #bc8040;
						}

						.cls-44 {
							fill: #acd149;
						}
					}
				}
			}

			&4 {
				.sprig-tree {
					#antle,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #ffff96;
						}

						.cls-12,
						.cls-5 {
							fill: #69d746;
						}

						.cls-5 {
							stroke: #fff;
						}

						.cls-27,
						.cls-28,
						.cls-34,
						.cls-35,
						.cls-5 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-28,
						.cls-35,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #199b32;
						}

						.cls-10,
						.cls-29,
						.cls-36,
						.cls-38,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-10,
						.cls-21,
						.cls-25,
						.cls-26,
						.cls-29,
						.cls-33,
						.cls-36,
						.cls-38,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-10,
						.cls-25,
						.cls-9 {
							fill: #682e52;
						}

						.cls-11 {
							fill: #43b649;
						}

						.cls-13 {
							fill: #c3beb9;
						}

						.cls-14 {
							fill: #fff;
						}

						.cls-15 {
							fill: #605a55;
						}

						.cls-16 {
							fill: #807b77;
						}

						.cls-17,
						.cls-40 {
							fill: #fff8dc;
						}

						.cls-18 {
							fill: #bc7244;
						}

						.cls-19 {
							fill: #ffd346;
						}

						.cls-20 {
							fill: #ffea4d;
						}

						.cls-21,
						.cls-25 {
							opacity: 0.15;
						}

						.cls-22 {
							fill: #82f0ff;
						}

						.cls-23 {
							fill: #a7f4ff;
						}

						.cls-24 {
							fill: #f9a136;
						}

						.cls-26,
						.cls-33 {
							opacity: 0.3;
						}

						.cls-27,
						.cls-28,
						.cls-34,
						.cls-35 {
							fill: none;
						}

						.cls-27,
						.cls-28 {
							stroke: #000;
						}

						.cls-27,
						.cls-34 {
							stroke-width: 2px;
						}

						.cls-30,
						.cls-40 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-31 {
							fill: #c39c79;
						}

						.cls-32 {
							fill: #373126;
						}

						.cls-33,
						.cls-36 {
							fill: #3b292d;
						}

						.cls-34,
						.cls-35 {
							stroke: #1e1c1a;
						}

						.cls-37 {
							fill: #17c8bb;
						}

						.cls-38 {
							fill: #654935;
						}

						.cls-39 {
							fill: #7c5b40;
						}

						.cls-41 {
							fill: #947358;
						}

						.cls-42 {
							fill: #533b26;
						}

						.cls-43 {
							fill: #bc8040;
						}

						.cls-44 {
							fill: #acd149;
						}
					}
				}
			}

			&5 {
				.sprig-tree {
					#antle,
					#shadows,
					#trees,
					#white_OL,
					.nest,
					.bee {
						@include Tree5Colors();
					}
				}
			}

			&6 {
				.sprig-tree {
					#antle,
					#shadows,
					#trees,
					#white_OL,
					.nest,
					.bee {
						@include Tree6Colors();
					}
				}
			}

			&7 {
				.sprig-tree {
					#antle,
					#shadows,
					#trees,
					#white_OL,
					.nest,
					.bee {
						@include Tree7Colors();
					}
				}
			}

			&8 {
				.sprig-tree {
					#antle,
					#shadows,
					#trees,
					#white_OL,
					.nest,
					.bee {
						@include Tree8Colors();
					}
				}
			}

			&9 {
				.sprig-tree {
					#antle,
					#shadows,
					#trees,
					#white_OL,
					.nest,
					.bee {
						@include Tree9Colors();
					}
				}
			}

			&10 {
				.sprig-tree {
					#antle,
					#shadows,
					#trees,
					#white_OL,
					.nest,
					.bee {
						@include Tree10Colors();
					}
				}
			}
		}
	}

	&.domain-15 {
		&.tree-level- {
			&1 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #dcff8c;
						}

						.cls-5 {
							fill: #69d746;
							stroke: #fff;
						}

						.cls-18,
						.cls-19,
						.cls-21,
						.cls-23,
						.cls-25,
						.cls-27,
						.cls-28,
						.cls-33,
						.cls-35,
						.cls-36,
						.cls-38,
						.cls-44,
						.cls-5 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-18,
						.cls-27,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #c3beb9;
						}

						.cls-15,
						.cls-26,
						.cls-46,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-15,
						.cls-20,
						.cls-26,
						.cls-31,
						.cls-46,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-15,
						.cls-9 {
							fill: #682e52;
						}

						.cls-10,
						.cls-28 {
							fill: #fff;
						}

						.cls-11 {
							fill: #605a55;
						}

						.cls-12 {
							fill: #807b77;
						}

						.cls-13,
						.cls-34 {
							fill: #fff8dc;
						}

						.cls-14 {
							fill: #bc7244;
						}

						.cls-16 {
							fill: #bc8040;
						}

						.cls-17 {
							fill: #acd149;
						}

						.cls-18,
						.cls-21,
						.cls-23,
						.cls-25,
						.cls-27,
						.cls-33,
						.cls-35,
						.cls-44 {
							fill: none;
						}

						.cls-18,
						.cls-19,
						.cls-21,
						.cls-23,
						.cls-24,
						.cls-25 {
							stroke: #000;
						}

						.cls-19,
						.cls-21,
						.cls-24,
						.cls-28,
						.cls-33,
						.cls-36,
						.cls-38,
						.cls-40 {
							stroke-width: 2px;
						}

						.cls-20,
						.cls-31 {
							opacity: 0.3;
						}

						.cls-22,
						.cls-34 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-23,
						.cls-35 {
							stroke-width: 3.16px;
						}

						.cls-24,
						.cls-40 {
							stroke-miterlimit: 10;
						}

						.cls-25,
						.cls-44 {
							stroke-width: 1.5px;
						}

						.cls-27,
						.cls-28,
						.cls-33,
						.cls-35,
						.cls-36,
						.cls-38,
						.cls-40,
						.cls-44 {
							stroke: #1e1c1a;
						}

						.cls-29 {
							fill: #373126;
						}

						.cls-30 {
							fill: #c8c6be;
						}

						.cls-31,
						.cls-46 {
							fill: #3b292d;
						}

						.cls-32 {
							fill: #eccda1;
						}

						.cls-36 {
							fill: #43b649;
						}

						.cls-37 {
							fill: #199b32;
						}

						.cls-38,
						.cls-45 {
							fill: #f24d49;
						}

						.cls-39 {
							fill: #bc7b3d;
						}

						.cls-40 {
							fill: #8f5131;
						}

						.cls-41 {
							fill: #2c2720;
						}

						.cls-42 {
							fill: #f04e49;
						}

						.cls-43 {
							fill: #7b624b;
						}
					}
				}
			}

			&2 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #e6d250;
						}

						.cls-5 {
							fill: #69d746;
							stroke: #fff;
						}

						.cls-20,
						.cls-21,
						.cls-23,
						.cls-25,
						.cls-27,
						.cls-29,
						.cls-30,
						.cls-35,
						.cls-37,
						.cls-38,
						.cls-40,
						.cls-46,
						.cls-5 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-20,
						.cls-29,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #c3beb9;
						}

						.cls-15,
						.cls-28,
						.cls-48,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-15,
						.cls-22,
						.cls-28,
						.cls-33,
						.cls-48,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-15,
						.cls-9 {
							fill: #682e52;
						}

						.cls-10,
						.cls-30 {
							fill: #fff;
						}

						.cls-11 {
							fill: #605a55;
						}

						.cls-12 {
							fill: #807b77;
						}

						.cls-13,
						.cls-36 {
							fill: #fff8dc;
						}

						.cls-14 {
							fill: #bc7244;
						}

						.cls-16 {
							fill: #ffd346;
						}

						.cls-17 {
							fill: #ffea4d;
						}

						.cls-18 {
							fill: #bc8040;
						}

						.cls-19 {
							fill: #acd149;
						}

						.cls-20,
						.cls-23,
						.cls-25,
						.cls-27,
						.cls-29,
						.cls-35,
						.cls-37,
						.cls-46 {
							fill: none;
						}

						.cls-20,
						.cls-21,
						.cls-23,
						.cls-25,
						.cls-26,
						.cls-27 {
							stroke: #000;
						}

						.cls-21,
						.cls-23,
						.cls-26,
						.cls-30,
						.cls-35,
						.cls-38,
						.cls-40,
						.cls-42 {
							stroke-width: 2px;
						}

						.cls-22,
						.cls-33 {
							opacity: 0.3;
						}

						.cls-24,
						.cls-36 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-25,
						.cls-37 {
							stroke-width: 3.16px;
						}

						.cls-26,
						.cls-42 {
							stroke-miterlimit: 10;
						}

						.cls-27,
						.cls-46 {
							stroke-width: 1.5px;
						}

						.cls-29,
						.cls-30,
						.cls-35,
						.cls-37,
						.cls-38,
						.cls-40,
						.cls-42,
						.cls-46 {
							stroke: #1e1c1a;
						}

						.cls-31 {
							fill: #373126;
						}

						.cls-32 {
							fill: #c8c6be;
						}

						.cls-33,
						.cls-48 {
							fill: #3b292d;
						}

						.cls-34 {
							fill: #eccda1;
						}

						.cls-38 {
							fill: #43b649;
						}

						.cls-39 {
							fill: #199b32;
						}

						.cls-40,
						.cls-47 {
							fill: #f24d49;
						}

						.cls-41 {
							fill: #bc7b3d;
						}

						.cls-42 {
							fill: #8f5131;
						}

						.cls-43 {
							fill: #2c2720;
						}

						.cls-44 {
							fill: #f04e49;
						}

						.cls-45 {
							fill: #7b624b;
						}
					}
				}
			}

			&3 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #d2b446;
						}

						.cls-25,
						.cls-5 {
							fill: #69d746;
						}

						.cls-5 {
							stroke: #fff;
						}

						.cls-28,
						.cls-29,
						.cls-31,
						.cls-33,
						.cls-35,
						.cls-37,
						.cls-38,
						.cls-43,
						.cls-45,
						.cls-46,
						.cls-47,
						.cls-5,
						.cls-53 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-28,
						.cls-37,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #c3beb9;
						}

						.cls-15,
						.cls-36,
						.cls-55,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-15,
						.cls-18,
						.cls-22,
						.cls-30,
						.cls-36,
						.cls-41,
						.cls-55,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-15,
						.cls-22,
						.cls-9 {
							fill: #682e52;
						}

						.cls-10,
						.cls-38 {
							fill: #fff;
						}

						.cls-11 {
							fill: #605a55;
						}

						.cls-12 {
							fill: #807b77;
						}

						.cls-13,
						.cls-44 {
							fill: #fff8dc;
						}

						.cls-14 {
							fill: #bc7244;
						}

						.cls-16 {
							fill: #ffd346;
						}

						.cls-17 {
							fill: #ffea4d;
						}

						.cls-18,
						.cls-22 {
							opacity: 0.15;
						}

						.cls-19 {
							fill: #82f0ff;
						}

						.cls-20 {
							fill: #a7f4ff;
						}

						.cls-21 {
							fill: #f9a136;
						}

						.cls-23 {
							fill: #199b32;
						}

						.cls-24,
						.cls-46 {
							fill: #43b649;
						}

						.cls-26 {
							fill: #bc8040;
						}

						.cls-27 {
							fill: #acd149;
						}

						.cls-28,
						.cls-31,
						.cls-33,
						.cls-35,
						.cls-37,
						.cls-43,
						.cls-45,
						.cls-53 {
							fill: none;
						}

						.cls-28,
						.cls-29,
						.cls-31,
						.cls-33,
						.cls-34,
						.cls-35 {
							stroke: #000;
						}

						.cls-29,
						.cls-31,
						.cls-34,
						.cls-38,
						.cls-43,
						.cls-46,
						.cls-47,
						.cls-49 {
							stroke-width: 2px;
						}

						.cls-30,
						.cls-41 {
							opacity: 0.3;
						}

						.cls-32,
						.cls-44 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-33,
						.cls-45 {
							stroke-width: 3.16px;
						}

						.cls-34,
						.cls-49 {
							stroke-miterlimit: 10;
						}

						.cls-35,
						.cls-53 {
							stroke-width: 1.5px;
						}

						.cls-37,
						.cls-38,
						.cls-43,
						.cls-45,
						.cls-46,
						.cls-47,
						.cls-49,
						.cls-53 {
							stroke: #1e1c1a;
						}

						.cls-39 {
							fill: #373126;
						}

						.cls-40 {
							fill: #c8c6be;
						}

						.cls-41,
						.cls-55 {
							fill: #3b292d;
						}

						.cls-42 {
							fill: #eccda1;
						}

						.cls-47,
						.cls-54 {
							fill: #f24d49;
						}

						.cls-48 {
							fill: #bc7b3d;
						}

						.cls-49 {
							fill: #8f5131;
						}

						.cls-50 {
							fill: #2c2720;
						}

						.cls-51 {
							fill: #f04e49;
						}

						.cls-52 {
							fill: #7b624b;
						}
					}
				}
			}

			&4 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#saplings {
						.cls-1 {
							isolation: isolate;
						}

						.cls-2 {
							fill: #98e866;
						}

						.cls-3 {
							fill: #8ee164;
						}

						.cls-4 {
							fill: #aa8755;
						}

						.cls-25,
						.cls-5 {
							fill: #69d746;
						}

						.cls-5 {
							stroke: #fff;
						}

						.cls-28,
						.cls-29,
						.cls-31,
						.cls-33,
						.cls-35,
						.cls-37,
						.cls-38,
						.cls-43,
						.cls-45,
						.cls-46,
						.cls-47,
						.cls-5,
						.cls-53 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-28,
						.cls-37,
						.cls-5 {
							stroke-width: 4px;
						}

						.cls-6 {
							fill: #1e1c1a;
						}

						.cls-7 {
							fill: #c3beb9;
						}

						.cls-15,
						.cls-36,
						.cls-55,
						.cls-8 {
							opacity: 0.2;
						}

						.cls-15,
						.cls-18,
						.cls-22,
						.cls-30,
						.cls-36,
						.cls-41,
						.cls-55,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-15,
						.cls-22,
						.cls-9 {
							fill: #682e52;
						}

						.cls-10,
						.cls-38 {
							fill: #fff;
						}

						.cls-11 {
							fill: #605a55;
						}

						.cls-12 {
							fill: #807b77;
						}

						.cls-13,
						.cls-44 {
							fill: #fff8dc;
						}

						.cls-14 {
							fill: #bc7244;
						}

						.cls-16 {
							fill: #ffd346;
						}

						.cls-17 {
							fill: #ffea4d;
						}

						.cls-18,
						.cls-22 {
							opacity: 0.15;
						}

						.cls-19 {
							fill: #82f0ff;
						}

						.cls-20 {
							fill: #a7f4ff;
						}

						.cls-21 {
							fill: #f9a136;
						}

						.cls-23 {
							fill: #199b32;
						}

						.cls-24,
						.cls-46 {
							fill: #43b649;
						}

						.cls-26 {
							fill: #bc8040;
						}

						.cls-27 {
							fill: #acd149;
						}

						.cls-28,
						.cls-31,
						.cls-33,
						.cls-35,
						.cls-37,
						.cls-43,
						.cls-45,
						.cls-53 {
							fill: none;
						}

						.cls-28,
						.cls-29,
						.cls-31,
						.cls-33,
						.cls-34,
						.cls-35 {
							stroke: #000;
						}

						.cls-29,
						.cls-31,
						.cls-34,
						.cls-38,
						.cls-43,
						.cls-46,
						.cls-47,
						.cls-49 {
							stroke-width: 2px;
						}

						.cls-30,
						.cls-41 {
							opacity: 0.3;
						}

						.cls-32,
						.cls-44 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-33,
						.cls-45 {
							stroke-width: 3.16px;
						}

						.cls-34,
						.cls-49 {
							stroke-miterlimit: 10;
						}

						.cls-35,
						.cls-53 {
							stroke-width: 1.5px;
						}

						.cls-37,
						.cls-38,
						.cls-43,
						.cls-45,
						.cls-46,
						.cls-47,
						.cls-49,
						.cls-53 {
							stroke: #1e1c1a;
						}

						.cls-39 {
							fill: #373126;
						}

						.cls-40 {
							fill: #c8c6be;
						}

						.cls-41,
						.cls-55 {
							fill: #3b292d;
						}

						.cls-42 {
							fill: #eccda1;
						}

						.cls-47,
						.cls-54 {
							fill: #f24d49;
						}

						.cls-48 {
							fill: #bc7b3d;
						}

						.cls-49 {
							fill: #8f5131;
						}

						.cls-50 {
							fill: #2c2720;
						}

						.cls-51 {
							fill: #f04e49;
						}

						.cls-52 {
							fill: #7b624b;
						}
					}
				}
			}

			&5 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#oak_trunk,
					#white_OL {
						.cls-1,
						.cls-45,
						.cls-46,
						.cls-49,
						.cls-51,
						.cls-53,
						.cls-55,
						.cls-61,
						.cls-63,
						.cls-70 {
							fill: none;
						}

						.cls-2 {
							isolation: isolate;
						}

						.cls-3 {
							fill: #98e866;
						}

						.cls-4 {
							fill: #8ee164;
						}

						.cls-5 {
							fill: #654935;
						}

						.cls-6 {
							clip-path: url(#clip-path);
						}

						.cls-11,
						.cls-59,
						.cls-7,
						.cls-72 {
							fill: #3b292d;
						}

						.cls-10,
						.cls-28,
						.cls-7 {
							opacity: 0.15;
						}

						.cls-10,
						.cls-17,
						.cls-19,
						.cls-28,
						.cls-48,
						.cls-54,
						.cls-59,
						.cls-7,
						.cls-72 {
							mix-blend-mode: multiply;
						}

						.cls-8 {
							fill: #7c5b40;
						}

						.cls-9 {
							clip-path: url(#clip-path-2);
						}

						.cls-12 {
							fill: #1e1c1a;
						}

						.cls-13 {
							fill: #574545;
						}

						.cls-14 {
							fill: #ded5c0;
						}

						.cls-15,
						.cls-56 {
							fill: #fff;
						}

						.cls-16 {
							fill: #b9afa6;
						}

						.cls-17,
						.cls-20,
						.cls-28 {
							fill: #682e52;
						}

						.cls-17,
						.cls-19,
						.cls-54,
						.cls-72 {
							opacity: 0.2;
						}

						.cls-18 {
							fill: #c3beb9;
						}

						.cls-21 {
							fill: #605a55;
						}

						.cls-22 {
							fill: #807b77;
						}

						.cls-23,
						.cls-62 {
							fill: #fff8dc;
						}

						.cls-24 {
							fill: #bc7244;
						}

						.cls-25 {
							fill: #ffd346;
						}

						.cls-26 {
							fill: #ffea4d;
						}

						.cls-27 {
							fill: #947358;
						}

						.cls-29 {
							fill: #cea776;
						}

						.cls-30 {
							fill: #fcd8dd;
						}

						.cls-31 {
							fill: #735f50;
						}

						.cls-32 {
							fill: #967c6e;
						}

						.cls-33 {
							fill: #e65e49;
						}

						.cls-34 {
							fill: #82f0ff;
						}

						.cls-35 {
							fill: #a7f4ff;
						}

						.cls-36 {
							fill: #f9a136;
						}

						.cls-37 {
							fill: #199b32;
						}

						.cls-38,
						.cls-64 {
							fill: #43b649;
						}

						.cls-39 {
							fill: #69d746;
						}

						.cls-40 {
							fill: #f04e49;
						}

						.cls-41 {
							fill: #17c8bb;
						}

						.cls-42 {
							fill: #e5be83;
						}

						.cls-43 {
							fill: #bc8040;
						}

						.cls-44 {
							fill: #acd149;
						}

						.cls-45 {
							stroke: #fff;
							stroke-width: 6px;
						}

						.cls-45,
						.cls-46,
						.cls-47,
						.cls-49,
						.cls-51,
						.cls-53,
						.cls-55,
						.cls-56,
						.cls-61,
						.cls-63,
						.cls-64,
						.cls-65,
						.cls-70 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-46,
						.cls-47,
						.cls-49,
						.cls-51,
						.cls-52,
						.cls-53 {
							stroke: #000;
						}

						.cls-46,
						.cls-55 {
							stroke-width: 4px;
						}

						.cls-47,
						.cls-49,
						.cls-52,
						.cls-56,
						.cls-61,
						.cls-64,
						.cls-65,
						.cls-67 {
							stroke-width: 2px;
						}

						.cls-48,
						.cls-59 {
							opacity: 0.3;
						}

						.cls-50,
						.cls-62 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-51,
						.cls-63 {
							stroke-width: 3.16px;
						}

						.cls-52,
						.cls-67 {
							stroke-miterlimit: 10;
						}

						.cls-53,
						.cls-70 {
							stroke-width: 1.5px;
						}

						.cls-55,
						.cls-56,
						.cls-61,
						.cls-63,
						.cls-64,
						.cls-65,
						.cls-67,
						.cls-70 {
							stroke: #1e1c1a;
						}

						.cls-57 {
							fill: #373126;
						}

						.cls-58 {
							fill: #c8c6be;
						}

						.cls-60 {
							fill: #eccda1;
						}

						.cls-65,
						.cls-71 {
							fill: #f24d49;
						}

						.cls-66 {
							fill: #bc7b3d;
						}

						.cls-67 {
							fill: #8f5131;
						}

						.cls-68 {
							fill: #2c2720;
						}

						.cls-69 {
							fill: #7b624b;
						}
					}
					.nest,
					.bee {
						@include Tree5Colors();
					}
				}
			}

			&6 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#oak_trunk,
					#white_OL {
						.cls-1,
						.cls-45,
						.cls-46,
						.cls-49,
						.cls-51,
						.cls-53,
						.cls-55,
						.cls-61,
						.cls-63,
						.cls-70 {
							fill: none;
						}

						.cls-2 {
							isolation: isolate;
						}

						.cls-3 {
							fill: #98e866;
						}

						.cls-4 {
							fill: #8ee164;
						}

						.cls-5 {
							fill: #654935;
						}

						.cls-6 {
							clip-path: url(#clip-path);
						}

						.cls-11,
						.cls-59,
						.cls-7,
						.cls-72 {
							fill: #3b292d;
						}

						.cls-10,
						.cls-28,
						.cls-7 {
							opacity: 0.15;
						}

						.cls-10,
						.cls-17,
						.cls-19,
						.cls-28,
						.cls-48,
						.cls-54,
						.cls-59,
						.cls-7,
						.cls-72 {
							mix-blend-mode: multiply;
						}

						.cls-8 {
							fill: #7c5b40;
						}

						.cls-9 {
							clip-path: url(#clip-path-2);
						}

						.cls-12 {
							fill: #1e1c1a;
						}

						.cls-13 {
							fill: #574545;
						}

						.cls-14 {
							fill: #ded5c0;
						}

						.cls-15,
						.cls-56 {
							fill: #fff;
						}

						.cls-16 {
							fill: #b9afa6;
						}

						.cls-17,
						.cls-20,
						.cls-28 {
							fill: #682e52;
						}

						.cls-17,
						.cls-19,
						.cls-54,
						.cls-72 {
							opacity: 0.2;
						}

						.cls-18 {
							fill: #c3beb9;
						}

						.cls-21 {
							fill: #605a55;
						}

						.cls-22 {
							fill: #807b77;
						}

						.cls-23,
						.cls-62 {
							fill: #fff8dc;
						}

						.cls-24 {
							fill: #bc7244;
						}

						.cls-25 {
							fill: #ffd346;
						}

						.cls-26 {
							fill: #ffea4d;
						}

						.cls-27 {
							fill: #947358;
						}

						.cls-29 {
							fill: #cea776;
						}

						.cls-30 {
							fill: #fcd8dd;
						}

						.cls-31 {
							fill: #82f0ff;
						}

						.cls-32 {
							fill: #a7f4ff;
						}

						.cls-33 {
							fill: #f9a136;
						}

						.cls-34 {
							fill: #735f50;
						}

						.cls-35 {
							fill: #967c6e;
						}

						.cls-36 {
							fill: #e65e49;
						}

						.cls-37 {
							fill: #199b32;
						}

						.cls-38,
						.cls-64 {
							fill: #43b649;
						}

						.cls-39 {
							fill: #69d746;
						}

						.cls-40 {
							fill: #f04e49;
						}

						.cls-41 {
							fill: #17c8bb;
						}

						.cls-42 {
							fill: #bc8040;
						}

						.cls-43 {
							fill: #acd149;
						}

						.cls-44 {
							fill: #e5be83;
						}

						.cls-45 {
							stroke: #fff;
							stroke-width: 6px;
						}

						.cls-45,
						.cls-46,
						.cls-47,
						.cls-49,
						.cls-51,
						.cls-53,
						.cls-55,
						.cls-56,
						.cls-61,
						.cls-63,
						.cls-64,
						.cls-65,
						.cls-70 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-46,
						.cls-47,
						.cls-49,
						.cls-51,
						.cls-52,
						.cls-53 {
							stroke: #000;
						}

						.cls-46,
						.cls-55 {
							stroke-width: 4px;
						}

						.cls-47,
						.cls-49,
						.cls-52,
						.cls-56,
						.cls-61,
						.cls-64,
						.cls-65,
						.cls-67 {
							stroke-width: 2px;
						}

						.cls-48,
						.cls-59 {
							opacity: 0.3;
						}

						.cls-50,
						.cls-62 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-51,
						.cls-63 {
							stroke-width: 3.16px;
						}

						.cls-52,
						.cls-67 {
							stroke-miterlimit: 10;
						}

						.cls-53,
						.cls-70 {
							stroke-width: 1.5px;
						}

						.cls-55,
						.cls-56,
						.cls-61,
						.cls-63,
						.cls-64,
						.cls-65,
						.cls-67,
						.cls-70 {
							stroke: #1e1c1a;
						}

						.cls-57 {
							fill: #373126;
						}

						.cls-58 {
							fill: #c8c6be;
						}

						.cls-60 {
							fill: #eccda1;
						}

						.cls-65,
						.cls-71 {
							fill: #f24d49;
						}

						.cls-66 {
							fill: #bc7b3d;
						}

						.cls-67 {
							fill: #8f5131;
						}

						.cls-68 {
							fill: #2c2720;
						}

						.cls-69 {
							fill: #7b624b;
						}
					}
					.nest,
					.bee {
						@include Tree6Colors();
					}
				}
			}

			&7 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#oak_trunk,
					#white_OL {
						.cls-1,
						.cls-48,
						.cls-49,
						.cls-52,
						.cls-54,
						.cls-56,
						.cls-58,
						.cls-64,
						.cls-66,
						.cls-73 {
							fill: none;
						}

						.cls-2 {
							isolation: isolate;
						}

						.cls-3 {
							fill: #98e866;
						}

						.cls-4 {
							fill: #8ee164;
						}

						.cls-5 {
							fill: #654935;
						}

						.cls-6 {
							clip-path: url(#clip-path);
						}

						.cls-62,
						.cls-7,
						.cls-75,
						.cls-9 {
							fill: #3b292d;
						}

						.cls-29,
						.cls-42,
						.cls-7,
						.cls-8 {
							opacity: 0.15;
						}

						.cls-18,
						.cls-20,
						.cls-29,
						.cls-51,
						.cls-57,
						.cls-62,
						.cls-7,
						.cls-75,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-10 {
							fill: #7c5b40;
						}

						.cls-11 {
							clip-path: url(#clip-path-2);
						}

						.cls-12 {
							clip-path: url(#clip-path-3);
						}

						.cls-13 {
							fill: #1e1c1a;
						}

						.cls-14 {
							fill: #574545;
						}

						.cls-15 {
							fill: #ded5c0;
						}

						.cls-16,
						.cls-42,
						.cls-59 {
							fill: #fff;
						}

						.cls-17 {
							fill: #b9afa6;
						}

						.cls-18,
						.cls-21,
						.cls-29 {
							fill: #682e52;
						}

						.cls-18,
						.cls-20,
						.cls-57,
						.cls-75 {
							opacity: 0.2;
						}

						.cls-19 {
							fill: #c3beb9;
						}

						.cls-22 {
							fill: #605a55;
						}

						.cls-23 {
							fill: #807b77;
						}

						.cls-24,
						.cls-65 {
							fill: #fff8dc;
						}

						.cls-25 {
							fill: #bc7244;
						}

						.cls-26 {
							fill: #ffd346;
						}

						.cls-27 {
							fill: #ffea4d;
						}

						.cls-28 {
							fill: #947358;
						}

						.cls-30 {
							fill: #cea776;
						}

						.cls-31 {
							fill: #fcd8dd;
						}

						.cls-32 {
							fill: #82f0ff;
						}

						.cls-33 {
							fill: #a7f4ff;
						}

						.cls-34 {
							fill: #f9a136;
						}

						.cls-35 {
							fill: #735f50;
						}

						.cls-36 {
							fill: #967c6e;
						}

						.cls-37 {
							fill: #e65e49;
						}

						.cls-38 {
							fill: #199b32;
						}

						.cls-39,
						.cls-67 {
							fill: #43b649;
						}

						.cls-40 {
							fill: #69d746;
						}

						.cls-41 {
							fill: #f04e49;
						}

						.cls-42 {
							mix-blend-mode: screen;
						}

						.cls-43 {
							fill: #17c8bb;
						}

						.cls-44 {
							fill: #46d2fd;
						}

						.cls-45 {
							fill: #bc8040;
						}

						.cls-46 {
							fill: #acd149;
						}

						.cls-47 {
							fill: #e5be83;
						}

						.cls-48 {
							stroke: #fff;
							stroke-width: 6px;
						}

						.cls-48,
						.cls-49,
						.cls-50,
						.cls-52,
						.cls-54,
						.cls-56,
						.cls-58,
						.cls-59,
						.cls-64,
						.cls-66,
						.cls-67,
						.cls-68,
						.cls-73 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-49,
						.cls-50,
						.cls-52,
						.cls-54,
						.cls-55,
						.cls-56 {
							stroke: #000;
						}

						.cls-49,
						.cls-58 {
							stroke-width: 4px;
						}

						.cls-50,
						.cls-52,
						.cls-55,
						.cls-59,
						.cls-64,
						.cls-67,
						.cls-68,
						.cls-70 {
							stroke-width: 2px;
						}

						.cls-51,
						.cls-62 {
							opacity: 0.3;
						}

						.cls-53,
						.cls-65 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-54,
						.cls-66 {
							stroke-width: 3.16px;
						}

						.cls-55,
						.cls-70 {
							stroke-miterlimit: 10;
						}

						.cls-56,
						.cls-73 {
							stroke-width: 1.5px;
						}

						.cls-58,
						.cls-59,
						.cls-64,
						.cls-66,
						.cls-67,
						.cls-68,
						.cls-70,
						.cls-73 {
							stroke: #1e1c1a;
						}

						.cls-60 {
							fill: #373126;
						}

						.cls-61 {
							fill: #c8c6be;
						}

						.cls-63 {
							fill: #eccda1;
						}

						.cls-68,
						.cls-74 {
							fill: #f24d49;
						}

						.cls-69 {
							fill: #bc7b3d;
						}

						.cls-70 {
							fill: #8f5131;
						}

						.cls-71 {
							fill: #2c2720;
						}

						.cls-72 {
							fill: #7b624b;
						}
					}
					.nest,
					.bee {
						@include Tree7Colors();
					}
				}
			}

			&8 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#oak_trunk,
					#white_OL {
						.cls-1,
						.cls-50,
						.cls-51,
						.cls-54,
						.cls-56,
						.cls-58,
						.cls-60,
						.cls-66,
						.cls-68,
						.cls-75 {
							fill: none;
						}

						.cls-2 {
							isolation: isolate;
						}

						.cls-3 {
							fill: #98e866;
						}

						.cls-4 {
							fill: #8ee164;
						}

						.cls-5 {
							fill: #654935;
						}

						.cls-6 {
							clip-path: url(#clip-path);
						}

						.cls-64,
						.cls-7,
						.cls-77,
						.cls-9 {
							fill: #3b292d;
						}

						.cls-29,
						.cls-42,
						.cls-7,
						.cls-8 {
							opacity: 0.15;
						}

						.cls-18,
						.cls-20,
						.cls-29,
						.cls-53,
						.cls-59,
						.cls-64,
						.cls-7,
						.cls-77,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-10 {
							fill: #7c5b40;
						}

						.cls-11 {
							clip-path: url(#clip-path-2);
						}

						.cls-12 {
							clip-path: url(#clip-path-3);
						}

						.cls-13 {
							fill: #1e1c1a;
						}

						.cls-14 {
							fill: #574545;
						}

						.cls-15 {
							fill: #ded5c0;
						}

						.cls-16,
						.cls-42,
						.cls-48,
						.cls-61 {
							fill: #fff;
						}

						.cls-17 {
							fill: #b9afa6;
						}

						.cls-18,
						.cls-21,
						.cls-29 {
							fill: #682e52;
						}

						.cls-18,
						.cls-20,
						.cls-59,
						.cls-77 {
							opacity: 0.2;
						}

						.cls-19 {
							fill: #c3beb9;
						}

						.cls-22 {
							fill: #605a55;
						}

						.cls-23 {
							fill: #807b77;
						}

						.cls-24,
						.cls-67 {
							fill: #fff8dc;
						}

						.cls-25 {
							fill: #bc7244;
						}

						.cls-26 {
							fill: #ffd346;
						}

						.cls-27 {
							fill: #ffea4d;
						}

						.cls-28 {
							fill: #947358;
						}

						.cls-30 {
							fill: #cea776;
						}

						.cls-31 {
							fill: #fcd8dd;
						}

						.cls-32 {
							fill: #82f0ff;
						}

						.cls-33 {
							fill: #a7f4ff;
						}

						.cls-34 {
							fill: #f9a136;
						}

						.cls-35 {
							fill: #735f50;
						}

						.cls-36 {
							fill: #967c6e;
						}

						.cls-37 {
							fill: #e65e49;
						}

						.cls-38 {
							fill: #199b32;
						}

						.cls-39,
						.cls-69 {
							fill: #43b649;
						}

						.cls-40 {
							fill: #69d746;
						}

						.cls-41 {
							fill: #f04e49;
						}

						.cls-42,
						.cls-48 {
							mix-blend-mode: screen;
						}

						.cls-43 {
							fill: #17c8bb;
						}

						.cls-44 {
							fill: #46d2fd;
						}

						.cls-45 {
							fill: #bc8040;
						}

						.cls-46 {
							fill: #acd149;
						}

						.cls-47 {
							fill: #e5be83;
						}

						.cls-48,
						.cls-53,
						.cls-64 {
							opacity: 0.3;
						}

						.cls-49 {
							fill: #f7a28c;
						}

						.cls-50 {
							stroke: #fff;
							stroke-width: 6px;
						}

						.cls-50,
						.cls-51,
						.cls-52,
						.cls-54,
						.cls-56,
						.cls-58,
						.cls-60,
						.cls-61,
						.cls-66,
						.cls-68,
						.cls-69,
						.cls-70,
						.cls-75 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-51,
						.cls-52,
						.cls-54,
						.cls-56,
						.cls-57,
						.cls-58 {
							stroke: #000;
						}

						.cls-51,
						.cls-60 {
							stroke-width: 4px;
						}

						.cls-52,
						.cls-54,
						.cls-57,
						.cls-61,
						.cls-66,
						.cls-69,
						.cls-70,
						.cls-72 {
							stroke-width: 2px;
						}

						.cls-55,
						.cls-67 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-56,
						.cls-68 {
							stroke-width: 3.16px;
						}

						.cls-57,
						.cls-72 {
							stroke-miterlimit: 10;
						}

						.cls-58,
						.cls-75 {
							stroke-width: 1.5px;
						}

						.cls-60,
						.cls-61,
						.cls-66,
						.cls-68,
						.cls-69,
						.cls-70,
						.cls-72,
						.cls-75 {
							stroke: #1e1c1a;
						}

						.cls-62 {
							fill: #373126;
						}

						.cls-63 {
							fill: #c8c6be;
						}

						.cls-65 {
							fill: #eccda1;
						}

						.cls-70,
						.cls-76 {
							fill: #f24d49;
						}

						.cls-71 {
							fill: #bc7b3d;
						}

						.cls-72 {
							fill: #8f5131;
						}

						.cls-73 {
							fill: #2c2720;
						}

						.cls-74 {
							fill: #7b624b;
						}
					}
					.nest,
					.bee {
						@include Tree8Colors();
					}
				}
			}

			&9 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#oak_trunk,
					#white_OL {
						.cls-1,
						.cls-51,
						.cls-52,
						.cls-55,
						.cls-57,
						.cls-59,
						.cls-61,
						.cls-67,
						.cls-69,
						.cls-76 {
							fill: none;
						}

						.cls-2 {
							isolation: isolate;
						}

						.cls-3 {
							fill: #98e866;
						}

						.cls-4 {
							fill: #8ee164;
						}

						.cls-5 {
							fill: #7c5b40;
						}

						.cls-6 {
							clip-path: url(#clip-path);
						}

						.cls-65,
						.cls-7,
						.cls-78,
						.cls-9 {
							fill: #3b292d;
						}

						.cls-30,
						.cls-43,
						.cls-7,
						.cls-8 {
							opacity: 0.15;
						}

						.cls-19,
						.cls-21,
						.cls-30,
						.cls-54,
						.cls-60,
						.cls-65,
						.cls-7,
						.cls-78,
						.cls-8 {
							mix-blend-mode: multiply;
						}

						.cls-10 {
							fill: #654935;
						}

						.cls-11 {
							clip-path: url(#clip-path-2);
						}

						.cls-12 {
							clip-path: url(#clip-path-3);
						}

						.cls-13 {
							clip-path: url(#clip-path-4);
						}

						.cls-14 {
							fill: #1e1c1a;
						}

						.cls-15 {
							fill: #574545;
						}

						.cls-16 {
							fill: #ded5c0;
						}

						.cls-17,
						.cls-43,
						.cls-49,
						.cls-62 {
							fill: #fff;
						}

						.cls-18 {
							fill: #b9afa6;
						}

						.cls-19,
						.cls-22,
						.cls-30 {
							fill: #682e52;
						}

						.cls-19,
						.cls-21,
						.cls-60,
						.cls-78 {
							opacity: 0.2;
						}

						.cls-20 {
							fill: #c3beb9;
						}

						.cls-23 {
							fill: #605a55;
						}

						.cls-24 {
							fill: #807b77;
						}

						.cls-25,
						.cls-68 {
							fill: #fff8dc;
						}

						.cls-26 {
							fill: #bc7244;
						}

						.cls-27 {
							fill: #ffd346;
						}

						.cls-28 {
							fill: #ffea4d;
						}

						.cls-29 {
							fill: #947358;
						}

						.cls-31 {
							fill: #cea776;
						}

						.cls-32 {
							fill: #fcd8dd;
						}

						.cls-33 {
							fill: #82f0ff;
						}

						.cls-34 {
							fill: #a7f4ff;
						}

						.cls-35 {
							fill: #f9a136;
						}

						.cls-36 {
							fill: #735f50;
						}

						.cls-37 {
							fill: #967c6e;
						}

						.cls-38 {
							fill: #e65e49;
						}

						.cls-39 {
							fill: #199b32;
						}

						.cls-40,
						.cls-70 {
							fill: #43b649;
						}

						.cls-41 {
							fill: #69d746;
						}

						.cls-42 {
							fill: #f04e49;
						}

						.cls-43,
						.cls-49 {
							mix-blend-mode: screen;
						}

						.cls-44 {
							fill: #17c8bb;
						}

						.cls-45 {
							fill: #bc8040;
						}

						.cls-46 {
							fill: #acd149;
						}

						.cls-47 {
							fill: #e5be83;
						}

						.cls-48 {
							fill: #46d2fd;
						}

						.cls-49,
						.cls-54,
						.cls-65 {
							opacity: 0.3;
						}

						.cls-50 {
							fill: #f7a28c;
						}

						.cls-51 {
							stroke: #fff;
							stroke-width: 6px;
						}

						.cls-51,
						.cls-52,
						.cls-53,
						.cls-55,
						.cls-57,
						.cls-59,
						.cls-61,
						.cls-62,
						.cls-67,
						.cls-69,
						.cls-70,
						.cls-71,
						.cls-76 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-52,
						.cls-53,
						.cls-55,
						.cls-57,
						.cls-58,
						.cls-59 {
							stroke: #000;
						}

						.cls-52,
						.cls-61 {
							stroke-width: 4px;
						}

						.cls-53,
						.cls-55,
						.cls-58,
						.cls-62,
						.cls-67,
						.cls-70,
						.cls-71,
						.cls-73 {
							stroke-width: 2px;
						}

						.cls-56,
						.cls-68 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-57,
						.cls-69 {
							stroke-width: 3.16px;
						}

						.cls-58,
						.cls-73 {
							stroke-miterlimit: 10;
						}

						.cls-59,
						.cls-76 {
							stroke-width: 1.5px;
						}

						.cls-61,
						.cls-62,
						.cls-67,
						.cls-69,
						.cls-70,
						.cls-71,
						.cls-73,
						.cls-76 {
							stroke: #1e1c1a;
						}

						.cls-63 {
							fill: #373126;
						}

						.cls-64 {
							fill: #c8c6be;
						}

						.cls-66 {
							fill: #eccda1;
						}

						.cls-71,
						.cls-77 {
							fill: #f24d49;
						}

						.cls-72 {
							fill: #bc7b3d;
						}

						.cls-73 {
							fill: #8f5131;
						}

						.cls-74 {
							fill: #2c2720;
						}

						.cls-75 {
							fill: #7b624b;
						}
					}
					.nest,
					.bee {
						@include Tree9Colors();
					}
				}
			}

			&10 {
				.sprig-tree {
					#Aliet,
					#shadows,
					#oak_trunk,
					#white_OL {
						.cls-1,
						.cls-51,
						.cls-52,
						.cls-55,
						.cls-57,
						.cls-59,
						.cls-61,
						.cls-67,
						.cls-69,
						.cls-76 {
							fill: none;
						}

						.cls-2 {
							isolation: isolate;
						}

						.cls-3 {
							fill: #98e866;
						}

						.cls-4 {
							fill: #8ee164;
						}

						.cls-5 {
							fill: #654935;
						}

						.cls-30,
						.cls-43,
						.cls-6,
						.cls-9 {
							opacity: 0.15;
						}

						.cls-19,
						.cls-21,
						.cls-30,
						.cls-54,
						.cls-6,
						.cls-60,
						.cls-65,
						.cls-78,
						.cls-9 {
							mix-blend-mode: multiply;
						}

						.cls-65,
						.cls-7,
						.cls-78,
						.cls-9 {
							fill: #3b292d;
						}

						.cls-8 {
							clip-path: url(#clip-path);
						}

						.cls-10 {
							fill: #7c5b40;
						}

						.cls-11 {
							clip-path: url(#clip-path-2);
						}

						.cls-12 {
							clip-path: url(#clip-path-3);
						}

						.cls-13 {
							clip-path: url(#clip-path-4);
						}

						.cls-14 {
							fill: #1e1c1a;
						}

						.cls-15 {
							fill: #574545;
						}

						.cls-16 {
							fill: #ded5c0;
						}

						.cls-17,
						.cls-43,
						.cls-49,
						.cls-62 {
							fill: #fff;
						}

						.cls-18 {
							fill: #b9afa6;
						}

						.cls-19,
						.cls-22,
						.cls-30 {
							fill: #682e52;
						}

						.cls-19,
						.cls-21,
						.cls-60,
						.cls-78 {
							opacity: 0.2;
						}

						.cls-20 {
							fill: #c3beb9;
						}

						.cls-23 {
							fill: #605a55;
						}

						.cls-24 {
							fill: #807b77;
						}

						.cls-25,
						.cls-68 {
							fill: #fff8dc;
						}

						.cls-26 {
							fill: #bc7244;
						}

						.cls-27 {
							fill: #ffd346;
						}

						.cls-28 {
							fill: #ffea4d;
						}

						.cls-29 {
							fill: #947358;
						}

						.cls-31 {
							fill: #cea776;
						}

						.cls-32 {
							fill: #fcd8dd;
						}

						.cls-33 {
							fill: #82f0ff;
						}

						.cls-34 {
							fill: #a7f4ff;
						}

						.cls-35 {
							fill: #f9a136;
						}

						.cls-36 {
							fill: #735f50;
						}

						.cls-37 {
							fill: #967c6e;
						}

						.cls-38 {
							fill: #e65e49;
						}

						.cls-39 {
							fill: #199b32;
						}

						.cls-40,
						.cls-70 {
							fill: #43b649;
						}

						.cls-41 {
							fill: #69d746;
						}

						.cls-42 {
							fill: #f04e49;
						}

						.cls-43,
						.cls-49 {
							mix-blend-mode: screen;
						}

						.cls-44 {
							fill: #17c8bb;
						}

						.cls-45 {
							fill: #46d2fd;
						}

						.cls-46 {
							fill: #bc8040;
						}

						.cls-47 {
							fill: #acd149;
						}

						.cls-48 {
							fill: #e5be83;
						}

						.cls-49,
						.cls-54,
						.cls-65 {
							opacity: 0.3;
						}

						.cls-50 {
							fill: #f7a28c;
						}

						.cls-51 {
							stroke: #fff;
							stroke-width: 6px;
						}

						.cls-51,
						.cls-52,
						.cls-53,
						.cls-55,
						.cls-57,
						.cls-59,
						.cls-61,
						.cls-62,
						.cls-67,
						.cls-69,
						.cls-70,
						.cls-71,
						.cls-76 {
							stroke-linecap: round;
							stroke-linejoin: round;
						}

						.cls-52,
						.cls-53,
						.cls-55,
						.cls-57,
						.cls-58,
						.cls-59 {
							stroke: #000;
						}

						.cls-52,
						.cls-61 {
							stroke-width: 4px;
						}

						.cls-53,
						.cls-55,
						.cls-58,
						.cls-62,
						.cls-67,
						.cls-70,
						.cls-71,
						.cls-73 {
							stroke-width: 2px;
						}

						.cls-56,
						.cls-68 {
							opacity: 0.4;
							mix-blend-mode: overlay;
						}

						.cls-57,
						.cls-69 {
							stroke-width: 3.16px;
						}

						.cls-58,
						.cls-73 {
							stroke-miterlimit: 10;
						}

						.cls-59,
						.cls-76 {
							stroke-width: 1.5px;
						}

						.cls-61,
						.cls-62,
						.cls-67,
						.cls-69,
						.cls-70,
						.cls-71,
						.cls-73,
						.cls-76 {
							stroke: #1e1c1a;
						}

						.cls-63 {
							fill: #373126;
						}

						.cls-64 {
							fill: #c8c6be;
						}

						.cls-66 {
							fill: #eccda1;
						}

						.cls-71,
						.cls-77 {
							fill: #f24d49;
						}

						.cls-72 {
							fill: #bc7b3d;
						}

						.cls-73 {
							fill: #8f5131;
						}

						.cls-74 {
							fill: #2c2720;
						}

						.cls-75 {
							fill: #7b624b;
						}
					}
					.nest,
					.bee {
						@include Tree10Colors();
					}
				}
			}
		}
	}
}

@keyframes PulseTree {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}
@keyframes ScaleIn {
	0% {
		transform: translate(-50%, -25%) scale(0);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -25%) scale(1);
		opacity: 1;
	}
}
@keyframes ShakeTop {
	0%,
	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	10% {
		-webkit-transform: rotate(2deg);
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		-webkit-transform: rotate(-4deg);
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		-webkit-transform: rotate(4deg);
		transform: rotate(4deg);
	}

	80% {
		-webkit-transform: rotate(-2deg);
		transform: rotate(-2deg);
	}

	90% {
		-webkit-transform: rotate(2deg);
		transform: rotate(2deg);
	}
}
