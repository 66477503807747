.student-milestone-report .student-milestone-report-title {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}
.student-milestone-report .student-milestone-report-title h5 {
  margin-bottom: 0;
}
.student-milestone-report .student-milestone-chart {
  padding: 1rem;
  border-top: 1px solid #dee2e6;
}
@media (max-width: 599px) {
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item {
    padding-right: 15px;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label span {
    font-size: 0.8rem;
    padding-left: 5px;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color {
    height: 15px;
    width: 15px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color.above {
    background-color: #4fc7c9;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color.below {
    background-color: #ff8f1a;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color.expectation {
    background-color: #6c757d;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics {
    padding-left: calc(20px + 5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation .arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    margin-left: 3px;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation .arrow.below {
    border-top: 7px solid #ff8f1a;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation .arrow.above {
    border-bottom: 7px solid #4fc7c9;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .totals {
    font-size: 0.8rem;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item:last-child {
    margin-right: 0;
  }
}
@media (min-width: 600px) {
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item {
    margin-right: 20px;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label span {
    margin-left: 5px;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color.above {
    background-color: #4fc7c9;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color.below {
    background-color: #ff8f1a;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label .legend-item-color.expectation {
    background-color: #6c757d;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-label:last-child {
    margin-right: 0;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics {
    padding-left: calc(20px + 5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation .arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    margin-left: 3px;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation .arrow.below {
    border-top: 7px solid #ff8f1a;
  }
  .student-milestone-report .student-milestone-chart .student-milestone-chart-legend .legend-item .legend-item-statistics .expectation .arrow.above {
    border-bottom: 7px solid #4fc7c9;
  }
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area {
  height: 300px;
  width: 100%;
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area .line.above {
  stroke: #4fc7c9;
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area .line.below {
  stroke: #ff8f1a;
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area .__react_component_tooltip.place-bottom {
  background-color: #ff8f1a;
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area .__react_component_tooltip.place-bottom::after {
  border-bottom-color: #ff8f1a;
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area .__react_component_tooltip.place-top {
  background-color: #4fc7c9;
}
.student-milestone-report .student-milestone-chart .student-milestone-chart-area .__react_component_tooltip.place-top::after {
  border-top-color: #4fc7c9;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected {
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student:last-child {
  margin-bottom: 0;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-content {
  width: 100%;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-content .selected-student-label {
  color: #979797;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-actions .selected-student-link {
  padding: 0 0.25rem;
  cursor: pointer;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-actions .selected-student-link svg path {
  fill: #6c757d;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-actions .selected-student-link:hover svg path {
  fill: #4fc7c9;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-actions .selected-student-remove {
  padding: 0 0.25rem;
  cursor: pointer;
}
.student-milestone-report .student-milestone-students .student-milestone-students-selected .student-milestone-selected-student .selected-student-actions .selected-student-remove:hover svg path {
  fill: #23b9bb;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area {
  width: 100%;
  border-top: 1px solid #dee2e6;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area.expectations-2 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  grid-column-gap: auto;
  grid-row-gap: auto;
}
@media (max-width: 991.98px) {
  .student-milestone-report .student-milestone-students .student-milestone-students-area.expectations-2 {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: auto;
    grid-column-gap: auto;
    grid-row-gap: auto;
  }
}
.student-milestone-report .student-milestone-students .student-milestone-students-area.expectations-3 {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto;
  grid-column-gap: auto;
  grid-row-gap: auto;
}
@media (max-width: 991.98px) {
  .student-milestone-report .student-milestone-students .student-milestone-students-area.expectations-3 {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: auto;
    grid-column-gap: auto;
    grid-row-gap: auto;
  }
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation {
  border-right: 1px solid #dee2e6;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-header {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-header .expectation-color {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 5px;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-header .expectation-color.expectation-below {
  background-color: #ff8f1a;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-header .expectation-color.expectation-at {
  background-color: #6c757d;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-header .expectation-color.expectation-above {
  background-color: #4fc7c9;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-category-header {
  width: 100%;
  border-bottom: 1px solid #dee2e6;
  padding: 0.25rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-category-header .category-name {
  width: 50%;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-category-header .category-level {
  width: 30%;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows {
  padding: 0 0 0.5rem 0;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row {
  padding: 0.25rem 1rem;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row.selected {
  background-color: #dee2e6;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-information {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-information .expectation-row-name {
  width: 65%;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-information .expectation-row-level {
  width: 35%;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-row-link {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-row-link .student-link {
  padding: 0 0.25rem;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-row-link .student-link svg path {
  fill: #6c757d;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row .expectation-row-link .student-link:hover svg path {
  fill: #4fc7c9;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row:hover {
  background-color: #dee2e6;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation .expectation-rows .expectation-row:last-child {
  border-bottom: none;
}
.student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation:last-child {
  border-right: none;
}
@media (max-width: 991.98px) {
  .student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation {
    border-bottom: 3px solid #dee2e6;
  }
  .student-milestone-report .student-milestone-students .student-milestone-students-area .student-milestone-expectation:last-child {
    border-bottom: none;
  }
}

