.sticker-badge.rabbit .cls-1,
.sticker-badge.rabbit .cls-44,
.sticker-badge.rabbit .cls-45,
.sticker-badge.rabbit .cls-51,
.sticker-badge.rabbit .cls-52,
.sticker-badge.rabbit .cls-67, .sticker-badge.goose .cls-1,
.sticker-badge.goose .cls-44,
.sticker-badge.goose .cls-45,
.sticker-badge.goose .cls-51,
.sticker-badge.goose .cls-52,
.sticker-badge.goose .cls-67, .sticker-badge.butterfly .cls-1,
.sticker-badge.butterfly .cls-44,
.sticker-badge.butterfly .cls-45,
.sticker-badge.butterfly .cls-51,
.sticker-badge.butterfly .cls-52,
.sticker-badge.butterfly .cls-67, .sticker-badge.chickadee .cls-1,
.sticker-badge.chickadee .cls-44,
.sticker-badge.chickadee .cls-45,
.sticker-badge.chickadee .cls-51,
.sticker-badge.chickadee .cls-52,
.sticker-badge.chickadee .cls-67, .sticker-badge.leafpile .cls-1,
.sticker-badge.leafpile .cls-44,
.sticker-badge.leafpile .cls-45,
.sticker-badge.leafpile .cls-51,
.sticker-badge.leafpile .cls-52,
.sticker-badge.leafpile .cls-67, .sticker-badge.squirrel .cls-1,
.sticker-badge.squirrel .cls-44,
.sticker-badge.squirrel .cls-45,
.sticker-badge.squirrel .cls-51,
.sticker-badge.squirrel .cls-52,
.sticker-badge.squirrel .cls-67, .sticker-badge.sun .cls-1,
.sticker-badge.sun .cls-44,
.sticker-badge.sun .cls-45,
.sticker-badge.sun .cls-51,
.sticker-badge.sun .cls-52,
.sticker-badge.sun .cls-67 {
  fill: none;
}
.sticker-badge.rabbit .cls-2, .sticker-badge.goose .cls-2, .sticker-badge.butterfly .cls-2, .sticker-badge.chickadee .cls-2, .sticker-badge.leafpile .cls-2, .sticker-badge.squirrel .cls-2, .sticker-badge.sun .cls-2 {
  isolation: isolate;
}
.sticker-badge.rabbit .cls-3, .sticker-badge.goose .cls-3, .sticker-badge.butterfly .cls-3, .sticker-badge.chickadee .cls-3, .sticker-badge.leafpile .cls-3, .sticker-badge.squirrel .cls-3, .sticker-badge.sun .cls-3 {
  fill: #98e866;
}
.sticker-badge.rabbit .cls-4, .sticker-badge.goose .cls-4, .sticker-badge.butterfly .cls-4, .sticker-badge.chickadee .cls-4, .sticker-badge.leafpile .cls-4, .sticker-badge.squirrel .cls-4, .sticker-badge.sun .cls-4 {
  fill: #8ee164;
}
.sticker-badge.rabbit .cls-5, .sticker-badge.goose .cls-5, .sticker-badge.butterfly .cls-5, .sticker-badge.chickadee .cls-5, .sticker-badge.leafpile .cls-5, .sticker-badge.squirrel .cls-5, .sticker-badge.sun .cls-5 {
  fill: #f5f0f0;
}
.sticker-badge.rabbit .cls-6, .sticker-badge.goose .cls-6, .sticker-badge.butterfly .cls-6, .sticker-badge.chickadee .cls-6, .sticker-badge.leafpile .cls-6, .sticker-badge.squirrel .cls-6, .sticker-badge.sun .cls-6 {
  clip-path: url(#clip-path);
}
.sticker-badge.rabbit .cls-7, .sticker-badge.goose .cls-7, .sticker-badge.butterfly .cls-7, .sticker-badge.chickadee .cls-7, .sticker-badge.leafpile .cls-7, .sticker-badge.squirrel .cls-7, .sticker-badge.sun .cls-7 {
  fill: #bba290;
}
.sticker-badge.rabbit .cls-50,
.sticker-badge.rabbit .cls-53,
.sticker-badge.rabbit .cls-8, .sticker-badge.goose .cls-50,
.sticker-badge.goose .cls-53,
.sticker-badge.goose .cls-8, .sticker-badge.butterfly .cls-50,
.sticker-badge.butterfly .cls-53,
.sticker-badge.butterfly .cls-8, .sticker-badge.chickadee .cls-50,
.sticker-badge.chickadee .cls-53,
.sticker-badge.chickadee .cls-8, .sticker-badge.leafpile .cls-50,
.sticker-badge.leafpile .cls-53,
.sticker-badge.leafpile .cls-8, .sticker-badge.squirrel .cls-50,
.sticker-badge.squirrel .cls-53,
.sticker-badge.squirrel .cls-8, .sticker-badge.sun .cls-50,
.sticker-badge.sun .cls-53,
.sticker-badge.sun .cls-8 {
  fill: #3b292d;
}
.sticker-badge.rabbit .cls-8, .sticker-badge.goose .cls-8, .sticker-badge.butterfly .cls-8, .sticker-badge.chickadee .cls-8, .sticker-badge.leafpile .cls-8, .sticker-badge.squirrel .cls-8, .sticker-badge.sun .cls-8 {
  opacity: 0.1;
}
.sticker-badge.rabbit .cls-19,
.sticker-badge.rabbit .cls-21,
.sticker-badge.rabbit .cls-33,
.sticker-badge.rabbit .cls-39,
.sticker-badge.rabbit .cls-43,
.sticker-badge.rabbit .cls-46,
.sticker-badge.rabbit .cls-50,
.sticker-badge.rabbit .cls-53,
.sticker-badge.rabbit .cls-55,
.sticker-badge.rabbit .cls-8, .sticker-badge.goose .cls-19,
.sticker-badge.goose .cls-21,
.sticker-badge.goose .cls-33,
.sticker-badge.goose .cls-39,
.sticker-badge.goose .cls-43,
.sticker-badge.goose .cls-46,
.sticker-badge.goose .cls-50,
.sticker-badge.goose .cls-53,
.sticker-badge.goose .cls-55,
.sticker-badge.goose .cls-8, .sticker-badge.butterfly .cls-19,
.sticker-badge.butterfly .cls-21,
.sticker-badge.butterfly .cls-33,
.sticker-badge.butterfly .cls-39,
.sticker-badge.butterfly .cls-43,
.sticker-badge.butterfly .cls-46,
.sticker-badge.butterfly .cls-50,
.sticker-badge.butterfly .cls-53,
.sticker-badge.butterfly .cls-55,
.sticker-badge.butterfly .cls-8, .sticker-badge.chickadee .cls-19,
.sticker-badge.chickadee .cls-21,
.sticker-badge.chickadee .cls-33,
.sticker-badge.chickadee .cls-39,
.sticker-badge.chickadee .cls-43,
.sticker-badge.chickadee .cls-46,
.sticker-badge.chickadee .cls-50,
.sticker-badge.chickadee .cls-53,
.sticker-badge.chickadee .cls-55,
.sticker-badge.chickadee .cls-8, .sticker-badge.leafpile .cls-19,
.sticker-badge.leafpile .cls-21,
.sticker-badge.leafpile .cls-33,
.sticker-badge.leafpile .cls-39,
.sticker-badge.leafpile .cls-43,
.sticker-badge.leafpile .cls-46,
.sticker-badge.leafpile .cls-50,
.sticker-badge.leafpile .cls-53,
.sticker-badge.leafpile .cls-55,
.sticker-badge.leafpile .cls-8, .sticker-badge.squirrel .cls-19,
.sticker-badge.squirrel .cls-21,
.sticker-badge.squirrel .cls-33,
.sticker-badge.squirrel .cls-39,
.sticker-badge.squirrel .cls-43,
.sticker-badge.squirrel .cls-46,
.sticker-badge.squirrel .cls-50,
.sticker-badge.squirrel .cls-53,
.sticker-badge.squirrel .cls-55,
.sticker-badge.squirrel .cls-8, .sticker-badge.sun .cls-19,
.sticker-badge.sun .cls-21,
.sticker-badge.sun .cls-33,
.sticker-badge.sun .cls-39,
.sticker-badge.sun .cls-43,
.sticker-badge.sun .cls-46,
.sticker-badge.sun .cls-50,
.sticker-badge.sun .cls-53,
.sticker-badge.sun .cls-55,
.sticker-badge.sun .cls-8 {
  mix-blend-mode: multiply;
}
.sticker-badge.rabbit .cls-9, .sticker-badge.goose .cls-9, .sticker-badge.butterfly .cls-9, .sticker-badge.chickadee .cls-9, .sticker-badge.leafpile .cls-9, .sticker-badge.squirrel .cls-9, .sticker-badge.sun .cls-9 {
  clip-path: url(#clip-path-2);
}
.sticker-badge.rabbit .cls-10, .sticker-badge.goose .cls-10, .sticker-badge.butterfly .cls-10, .sticker-badge.chickadee .cls-10, .sticker-badge.leafpile .cls-10, .sticker-badge.squirrel .cls-10, .sticker-badge.sun .cls-10 {
  fill: #d1c1b5;
}
.sticker-badge.rabbit .cls-11, .sticker-badge.goose .cls-11, .sticker-badge.butterfly .cls-11, .sticker-badge.chickadee .cls-11, .sticker-badge.leafpile .cls-11, .sticker-badge.squirrel .cls-11, .sticker-badge.sun .cls-11 {
  fill: #a4836b;
}
.sticker-badge.rabbit .cls-12, .sticker-badge.goose .cls-12, .sticker-badge.butterfly .cls-12, .sticker-badge.chickadee .cls-12, .sticker-badge.leafpile .cls-12, .sticker-badge.squirrel .cls-12, .sticker-badge.sun .cls-12 {
  clip-path: url(#clip-path-3);
}
.sticker-badge.rabbit .cls-13,
.sticker-badge.rabbit .cls-60,
.sticker-badge.rabbit .cls-65, .sticker-badge.goose .cls-13,
.sticker-badge.goose .cls-60,
.sticker-badge.goose .cls-65, .sticker-badge.butterfly .cls-13,
.sticker-badge.butterfly .cls-60,
.sticker-badge.butterfly .cls-65, .sticker-badge.chickadee .cls-13,
.sticker-badge.chickadee .cls-60,
.sticker-badge.chickadee .cls-65, .sticker-badge.leafpile .cls-13,
.sticker-badge.leafpile .cls-60,
.sticker-badge.leafpile .cls-65, .sticker-badge.squirrel .cls-13,
.sticker-badge.squirrel .cls-60,
.sticker-badge.squirrel .cls-65, .sticker-badge.sun .cls-13,
.sticker-badge.sun .cls-60,
.sticker-badge.sun .cls-65 {
  fill: #fff;
}
.sticker-badge.rabbit .cls-14, .sticker-badge.goose .cls-14, .sticker-badge.butterfly .cls-14, .sticker-badge.chickadee .cls-14, .sticker-badge.leafpile .cls-14, .sticker-badge.squirrel .cls-14, .sticker-badge.sun .cls-14 {
  clip-path: url(#clip-path-4);
}
.sticker-badge.rabbit .cls-15, .sticker-badge.goose .cls-15, .sticker-badge.butterfly .cls-15, .sticker-badge.chickadee .cls-15, .sticker-badge.leafpile .cls-15, .sticker-badge.squirrel .cls-15, .sticker-badge.sun .cls-15 {
  fill: #1e1c1a;
}
.sticker-badge.rabbit .cls-16, .sticker-badge.goose .cls-16, .sticker-badge.butterfly .cls-16, .sticker-badge.chickadee .cls-16, .sticker-badge.leafpile .cls-16, .sticker-badge.squirrel .cls-16, .sticker-badge.sun .cls-16 {
  fill: #574545;
}
.sticker-badge.rabbit .cls-17, .sticker-badge.goose .cls-17, .sticker-badge.butterfly .cls-17, .sticker-badge.chickadee .cls-17, .sticker-badge.leafpile .cls-17, .sticker-badge.squirrel .cls-17, .sticker-badge.sun .cls-17 {
  fill: #ded5c0;
}
.sticker-badge.rabbit .cls-18, .sticker-badge.goose .cls-18, .sticker-badge.butterfly .cls-18, .sticker-badge.chickadee .cls-18, .sticker-badge.leafpile .cls-18, .sticker-badge.squirrel .cls-18, .sticker-badge.sun .cls-18 {
  fill: #b9afa6;
}
.sticker-badge.rabbit .cls-19,
.sticker-badge.rabbit .cls-22,
.sticker-badge.rabbit .cls-33, .sticker-badge.goose .cls-19,
.sticker-badge.goose .cls-22,
.sticker-badge.goose .cls-33, .sticker-badge.butterfly .cls-19,
.sticker-badge.butterfly .cls-22,
.sticker-badge.butterfly .cls-33, .sticker-badge.chickadee .cls-19,
.sticker-badge.chickadee .cls-22,
.sticker-badge.chickadee .cls-33, .sticker-badge.leafpile .cls-19,
.sticker-badge.leafpile .cls-22,
.sticker-badge.leafpile .cls-33, .sticker-badge.squirrel .cls-19,
.sticker-badge.squirrel .cls-22,
.sticker-badge.squirrel .cls-33, .sticker-badge.sun .cls-19,
.sticker-badge.sun .cls-22,
.sticker-badge.sun .cls-33 {
  fill: #682e52;
}
.sticker-badge.rabbit .cls-19,
.sticker-badge.rabbit .cls-21,
.sticker-badge.rabbit .cls-46,
.sticker-badge.rabbit .cls-53,
.sticker-badge.rabbit .cls-55, .sticker-badge.goose .cls-19,
.sticker-badge.goose .cls-21,
.sticker-badge.goose .cls-46,
.sticker-badge.goose .cls-53,
.sticker-badge.goose .cls-55, .sticker-badge.butterfly .cls-19,
.sticker-badge.butterfly .cls-21,
.sticker-badge.butterfly .cls-46,
.sticker-badge.butterfly .cls-53,
.sticker-badge.butterfly .cls-55, .sticker-badge.chickadee .cls-19,
.sticker-badge.chickadee .cls-21,
.sticker-badge.chickadee .cls-46,
.sticker-badge.chickadee .cls-53,
.sticker-badge.chickadee .cls-55, .sticker-badge.leafpile .cls-19,
.sticker-badge.leafpile .cls-21,
.sticker-badge.leafpile .cls-46,
.sticker-badge.leafpile .cls-53,
.sticker-badge.leafpile .cls-55, .sticker-badge.squirrel .cls-19,
.sticker-badge.squirrel .cls-21,
.sticker-badge.squirrel .cls-46,
.sticker-badge.squirrel .cls-53,
.sticker-badge.squirrel .cls-55, .sticker-badge.sun .cls-19,
.sticker-badge.sun .cls-21,
.sticker-badge.sun .cls-46,
.sticker-badge.sun .cls-53,
.sticker-badge.sun .cls-55 {
  opacity: 0.2;
}
.sticker-badge.rabbit .cls-20, .sticker-badge.goose .cls-20, .sticker-badge.butterfly .cls-20, .sticker-badge.chickadee .cls-20, .sticker-badge.leafpile .cls-20, .sticker-badge.squirrel .cls-20, .sticker-badge.sun .cls-20 {
  fill: #199b32;
}
.sticker-badge.rabbit .cls-23, .sticker-badge.goose .cls-23, .sticker-badge.butterfly .cls-23, .sticker-badge.chickadee .cls-23, .sticker-badge.leafpile .cls-23, .sticker-badge.squirrel .cls-23, .sticker-badge.sun .cls-23 {
  fill: #43b649;
}
.sticker-badge.rabbit .cls-24, .sticker-badge.goose .cls-24, .sticker-badge.butterfly .cls-24, .sticker-badge.chickadee .cls-24, .sticker-badge.leafpile .cls-24, .sticker-badge.squirrel .cls-24, .sticker-badge.sun .cls-24 {
  fill: #69d746;
}
.sticker-badge.rabbit .cls-25, .sticker-badge.goose .cls-25, .sticker-badge.butterfly .cls-25, .sticker-badge.chickadee .cls-25, .sticker-badge.leafpile .cls-25, .sticker-badge.squirrel .cls-25, .sticker-badge.sun .cls-25 {
  fill: #c3beb9;
}
.sticker-badge.rabbit .cls-26, .sticker-badge.goose .cls-26, .sticker-badge.butterfly .cls-26, .sticker-badge.chickadee .cls-26, .sticker-badge.leafpile .cls-26, .sticker-badge.squirrel .cls-26, .sticker-badge.sun .cls-26 {
  fill: #605a55;
}
.sticker-badge.rabbit .cls-27, .sticker-badge.goose .cls-27, .sticker-badge.butterfly .cls-27, .sticker-badge.chickadee .cls-27, .sticker-badge.leafpile .cls-27, .sticker-badge.squirrel .cls-27, .sticker-badge.sun .cls-27 {
  fill: #807b77;
}
.sticker-badge.rabbit .cls-28,
.sticker-badge.rabbit .cls-57, .sticker-badge.goose .cls-28,
.sticker-badge.goose .cls-57, .sticker-badge.butterfly .cls-28,
.sticker-badge.butterfly .cls-57, .sticker-badge.chickadee .cls-28,
.sticker-badge.chickadee .cls-57, .sticker-badge.leafpile .cls-28,
.sticker-badge.leafpile .cls-57, .sticker-badge.squirrel .cls-28,
.sticker-badge.squirrel .cls-57, .sticker-badge.sun .cls-28,
.sticker-badge.sun .cls-57 {
  fill: #fff8dc;
}
.sticker-badge.rabbit .cls-29, .sticker-badge.goose .cls-29, .sticker-badge.butterfly .cls-29, .sticker-badge.chickadee .cls-29, .sticker-badge.leafpile .cls-29, .sticker-badge.squirrel .cls-29, .sticker-badge.sun .cls-29 {
  fill: #bc7244;
}
.sticker-badge.rabbit .cls-30, .sticker-badge.goose .cls-30, .sticker-badge.butterfly .cls-30, .sticker-badge.chickadee .cls-30, .sticker-badge.leafpile .cls-30, .sticker-badge.squirrel .cls-30, .sticker-badge.sun .cls-30 {
  fill: #ffd346;
}
.sticker-badge.rabbit .cls-31, .sticker-badge.goose .cls-31, .sticker-badge.butterfly .cls-31, .sticker-badge.chickadee .cls-31, .sticker-badge.leafpile .cls-31, .sticker-badge.squirrel .cls-31, .sticker-badge.sun .cls-31 {
  fill: #ffea4d;
}
.sticker-badge.rabbit .cls-32, .sticker-badge.goose .cls-32, .sticker-badge.butterfly .cls-32, .sticker-badge.chickadee .cls-32, .sticker-badge.leafpile .cls-32, .sticker-badge.squirrel .cls-32, .sticker-badge.sun .cls-32 {
  fill: #947358;
}
.sticker-badge.rabbit .cls-33,
.sticker-badge.rabbit .cls-39,
.sticker-badge.rabbit .cls-60, .sticker-badge.goose .cls-33,
.sticker-badge.goose .cls-39,
.sticker-badge.goose .cls-60, .sticker-badge.butterfly .cls-33,
.sticker-badge.butterfly .cls-39,
.sticker-badge.butterfly .cls-60, .sticker-badge.chickadee .cls-33,
.sticker-badge.chickadee .cls-39,
.sticker-badge.chickadee .cls-60, .sticker-badge.leafpile .cls-33,
.sticker-badge.leafpile .cls-39,
.sticker-badge.leafpile .cls-60, .sticker-badge.squirrel .cls-33,
.sticker-badge.squirrel .cls-39,
.sticker-badge.squirrel .cls-60, .sticker-badge.sun .cls-33,
.sticker-badge.sun .cls-39,
.sticker-badge.sun .cls-60 {
  opacity: 0.15;
}
.sticker-badge.rabbit .cls-34, .sticker-badge.goose .cls-34, .sticker-badge.butterfly .cls-34, .sticker-badge.chickadee .cls-34, .sticker-badge.leafpile .cls-34, .sticker-badge.squirrel .cls-34, .sticker-badge.sun .cls-34 {
  fill: #cea776;
}
.sticker-badge.rabbit .cls-35, .sticker-badge.goose .cls-35, .sticker-badge.butterfly .cls-35, .sticker-badge.chickadee .cls-35, .sticker-badge.leafpile .cls-35, .sticker-badge.squirrel .cls-35, .sticker-badge.sun .cls-35 {
  fill: #fcd8dd;
}
.sticker-badge.rabbit .cls-36, .sticker-badge.goose .cls-36, .sticker-badge.butterfly .cls-36, .sticker-badge.chickadee .cls-36, .sticker-badge.leafpile .cls-36, .sticker-badge.squirrel .cls-36, .sticker-badge.sun .cls-36 {
  fill: #82f0ff;
}
.sticker-badge.rabbit .cls-37, .sticker-badge.goose .cls-37, .sticker-badge.butterfly .cls-37, .sticker-badge.chickadee .cls-37, .sticker-badge.leafpile .cls-37, .sticker-badge.squirrel .cls-37, .sticker-badge.sun .cls-37 {
  fill: #a7f4ff;
}
.sticker-badge.rabbit .cls-38, .sticker-badge.goose .cls-38, .sticker-badge.butterfly .cls-38, .sticker-badge.chickadee .cls-38, .sticker-badge.leafpile .cls-38, .sticker-badge.squirrel .cls-38, .sticker-badge.sun .cls-38 {
  fill: #f9a136;
}
.sticker-badge.rabbit .cls-40, .sticker-badge.goose .cls-40, .sticker-badge.butterfly .cls-40, .sticker-badge.chickadee .cls-40, .sticker-badge.leafpile .cls-40, .sticker-badge.squirrel .cls-40, .sticker-badge.sun .cls-40 {
  fill: #735f50;
}
.sticker-badge.rabbit .cls-41, .sticker-badge.goose .cls-41, .sticker-badge.butterfly .cls-41, .sticker-badge.chickadee .cls-41, .sticker-badge.leafpile .cls-41, .sticker-badge.squirrel .cls-41, .sticker-badge.sun .cls-41 {
  fill: #967c6e;
}
.sticker-badge.rabbit .cls-42, .sticker-badge.goose .cls-42, .sticker-badge.butterfly .cls-42, .sticker-badge.chickadee .cls-42, .sticker-badge.leafpile .cls-42, .sticker-badge.squirrel .cls-42, .sticker-badge.sun .cls-42 {
  fill: #e65e49;
}
.sticker-badge.rabbit .cls-43,
.sticker-badge.rabbit .cls-50,
.sticker-badge.rabbit .cls-65, .sticker-badge.goose .cls-43,
.sticker-badge.goose .cls-50,
.sticker-badge.goose .cls-65, .sticker-badge.butterfly .cls-43,
.sticker-badge.butterfly .cls-50,
.sticker-badge.butterfly .cls-65, .sticker-badge.chickadee .cls-43,
.sticker-badge.chickadee .cls-50,
.sticker-badge.chickadee .cls-65, .sticker-badge.leafpile .cls-43,
.sticker-badge.leafpile .cls-50,
.sticker-badge.leafpile .cls-65, .sticker-badge.squirrel .cls-43,
.sticker-badge.squirrel .cls-50,
.sticker-badge.squirrel .cls-65, .sticker-badge.sun .cls-43,
.sticker-badge.sun .cls-50,
.sticker-badge.sun .cls-65 {
  opacity: 0.3;
}
.sticker-badge.rabbit .cls-44,
.sticker-badge.rabbit .cls-45, .sticker-badge.goose .cls-44,
.sticker-badge.goose .cls-45, .sticker-badge.butterfly .cls-44,
.sticker-badge.butterfly .cls-45, .sticker-badge.chickadee .cls-44,
.sticker-badge.chickadee .cls-45, .sticker-badge.leafpile .cls-44,
.sticker-badge.leafpile .cls-45, .sticker-badge.squirrel .cls-44,
.sticker-badge.squirrel .cls-45, .sticker-badge.sun .cls-44,
.sticker-badge.sun .cls-45 {
  stroke: #000;
}
.sticker-badge.rabbit .cls-44,
.sticker-badge.rabbit .cls-45,
.sticker-badge.rabbit .cls-51,
.sticker-badge.rabbit .cls-52,
.sticker-badge.rabbit .cls-67, .sticker-badge.goose .cls-44,
.sticker-badge.goose .cls-45,
.sticker-badge.goose .cls-51,
.sticker-badge.goose .cls-52,
.sticker-badge.goose .cls-67, .sticker-badge.butterfly .cls-44,
.sticker-badge.butterfly .cls-45,
.sticker-badge.butterfly .cls-51,
.sticker-badge.butterfly .cls-52,
.sticker-badge.butterfly .cls-67, .sticker-badge.chickadee .cls-44,
.sticker-badge.chickadee .cls-45,
.sticker-badge.chickadee .cls-51,
.sticker-badge.chickadee .cls-52,
.sticker-badge.chickadee .cls-67, .sticker-badge.leafpile .cls-44,
.sticker-badge.leafpile .cls-45,
.sticker-badge.leafpile .cls-51,
.sticker-badge.leafpile .cls-52,
.sticker-badge.leafpile .cls-67, .sticker-badge.squirrel .cls-44,
.sticker-badge.squirrel .cls-45,
.sticker-badge.squirrel .cls-51,
.sticker-badge.squirrel .cls-52,
.sticker-badge.squirrel .cls-67, .sticker-badge.sun .cls-44,
.sticker-badge.sun .cls-45,
.sticker-badge.sun .cls-51,
.sticker-badge.sun .cls-52,
.sticker-badge.sun .cls-67 {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.sticker-badge.rabbit .cls-44,
.sticker-badge.rabbit .cls-51, .sticker-badge.goose .cls-44,
.sticker-badge.goose .cls-51, .sticker-badge.butterfly .cls-44,
.sticker-badge.butterfly .cls-51, .sticker-badge.chickadee .cls-44,
.sticker-badge.chickadee .cls-51, .sticker-badge.leafpile .cls-44,
.sticker-badge.leafpile .cls-51, .sticker-badge.squirrel .cls-44,
.sticker-badge.squirrel .cls-51, .sticker-badge.sun .cls-44,
.sticker-badge.sun .cls-51 {
  stroke-width: 2px;
}
.sticker-badge.rabbit .cls-45,
.sticker-badge.rabbit .cls-52, .sticker-badge.goose .cls-45,
.sticker-badge.goose .cls-52, .sticker-badge.butterfly .cls-45,
.sticker-badge.butterfly .cls-52, .sticker-badge.chickadee .cls-45,
.sticker-badge.chickadee .cls-52, .sticker-badge.leafpile .cls-45,
.sticker-badge.leafpile .cls-52, .sticker-badge.squirrel .cls-45,
.sticker-badge.squirrel .cls-52, .sticker-badge.sun .cls-45,
.sticker-badge.sun .cls-52 {
  stroke-width: 4px;
}
.sticker-badge.rabbit .cls-47,
.sticker-badge.rabbit .cls-57, .sticker-badge.goose .cls-47,
.sticker-badge.goose .cls-57, .sticker-badge.butterfly .cls-47,
.sticker-badge.butterfly .cls-57, .sticker-badge.chickadee .cls-47,
.sticker-badge.chickadee .cls-57, .sticker-badge.leafpile .cls-47,
.sticker-badge.leafpile .cls-57, .sticker-badge.squirrel .cls-47,
.sticker-badge.squirrel .cls-57, .sticker-badge.sun .cls-47,
.sticker-badge.sun .cls-57 {
  opacity: 0.4;
  mix-blend-mode: overlay;
}
.sticker-badge.rabbit .cls-48, .sticker-badge.goose .cls-48, .sticker-badge.butterfly .cls-48, .sticker-badge.chickadee .cls-48, .sticker-badge.leafpile .cls-48, .sticker-badge.squirrel .cls-48, .sticker-badge.sun .cls-48 {
  fill: #c39c79;
}
.sticker-badge.rabbit .cls-49, .sticker-badge.goose .cls-49, .sticker-badge.butterfly .cls-49, .sticker-badge.chickadee .cls-49, .sticker-badge.leafpile .cls-49, .sticker-badge.squirrel .cls-49, .sticker-badge.sun .cls-49 {
  fill: #373126;
}
.sticker-badge.rabbit .cls-51,
.sticker-badge.rabbit .cls-52, .sticker-badge.goose .cls-51,
.sticker-badge.goose .cls-52, .sticker-badge.butterfly .cls-51,
.sticker-badge.butterfly .cls-52, .sticker-badge.chickadee .cls-51,
.sticker-badge.chickadee .cls-52, .sticker-badge.leafpile .cls-51,
.sticker-badge.leafpile .cls-52, .sticker-badge.squirrel .cls-51,
.sticker-badge.squirrel .cls-52, .sticker-badge.sun .cls-51,
.sticker-badge.sun .cls-52 {
  stroke: #1e1c1a;
}
.sticker-badge.rabbit .cls-54, .sticker-badge.goose .cls-54, .sticker-badge.butterfly .cls-54, .sticker-badge.chickadee .cls-54, .sticker-badge.leafpile .cls-54, .sticker-badge.squirrel .cls-54, .sticker-badge.sun .cls-54 {
  fill: #17c8bb;
}
.sticker-badge.rabbit .cls-55, .sticker-badge.goose .cls-55, .sticker-badge.butterfly .cls-55, .sticker-badge.chickadee .cls-55, .sticker-badge.leafpile .cls-55, .sticker-badge.squirrel .cls-55, .sticker-badge.sun .cls-55 {
  fill: #654935;
}
.sticker-badge.rabbit .cls-56, .sticker-badge.goose .cls-56, .sticker-badge.butterfly .cls-56, .sticker-badge.chickadee .cls-56, .sticker-badge.leafpile .cls-56, .sticker-badge.squirrel .cls-56, .sticker-badge.sun .cls-56 {
  fill: #7c5b40;
}
.sticker-badge.rabbit .cls-58, .sticker-badge.goose .cls-58, .sticker-badge.butterfly .cls-58, .sticker-badge.chickadee .cls-58, .sticker-badge.leafpile .cls-58, .sticker-badge.squirrel .cls-58, .sticker-badge.sun .cls-58 {
  fill: #533b26;
}
.sticker-badge.rabbit .cls-59, .sticker-badge.goose .cls-59, .sticker-badge.butterfly .cls-59, .sticker-badge.chickadee .cls-59, .sticker-badge.leafpile .cls-59, .sticker-badge.squirrel .cls-59, .sticker-badge.sun .cls-59 {
  fill: #f04e49;
}
.sticker-badge.rabbit .cls-60,
.sticker-badge.rabbit .cls-65, .sticker-badge.goose .cls-60,
.sticker-badge.goose .cls-65, .sticker-badge.butterfly .cls-60,
.sticker-badge.butterfly .cls-65, .sticker-badge.chickadee .cls-60,
.sticker-badge.chickadee .cls-65, .sticker-badge.leafpile .cls-60,
.sticker-badge.leafpile .cls-65, .sticker-badge.squirrel .cls-60,
.sticker-badge.squirrel .cls-65, .sticker-badge.sun .cls-60,
.sticker-badge.sun .cls-65 {
  mix-blend-mode: screen;
}
.sticker-badge.rabbit .cls-61, .sticker-badge.goose .cls-61, .sticker-badge.butterfly .cls-61, .sticker-badge.chickadee .cls-61, .sticker-badge.leafpile .cls-61, .sticker-badge.squirrel .cls-61, .sticker-badge.sun .cls-61 {
  fill: #46d2fd;
}
.sticker-badge.rabbit .cls-62, .sticker-badge.goose .cls-62, .sticker-badge.butterfly .cls-62, .sticker-badge.chickadee .cls-62, .sticker-badge.leafpile .cls-62, .sticker-badge.squirrel .cls-62, .sticker-badge.sun .cls-62 {
  fill: #bc8040;
}
.sticker-badge.rabbit .cls-63, .sticker-badge.goose .cls-63, .sticker-badge.butterfly .cls-63, .sticker-badge.chickadee .cls-63, .sticker-badge.leafpile .cls-63, .sticker-badge.squirrel .cls-63, .sticker-badge.sun .cls-63 {
  fill: #acd149;
}
.sticker-badge.rabbit .cls-64, .sticker-badge.goose .cls-64, .sticker-badge.butterfly .cls-64, .sticker-badge.chickadee .cls-64, .sticker-badge.leafpile .cls-64, .sticker-badge.squirrel .cls-64, .sticker-badge.sun .cls-64 {
  fill: #e5be83;
}
.sticker-badge.rabbit .cls-66, .sticker-badge.goose .cls-66, .sticker-badge.butterfly .cls-66, .sticker-badge.chickadee .cls-66, .sticker-badge.leafpile .cls-66, .sticker-badge.squirrel .cls-66, .sticker-badge.sun .cls-66 {
  fill: #f7a28c;
}
.sticker-badge.rabbit .cls-67, .sticker-badge.goose .cls-67, .sticker-badge.butterfly .cls-67, .sticker-badge.chickadee .cls-67, .sticker-badge.leafpile .cls-67, .sticker-badge.squirrel .cls-67, .sticker-badge.sun .cls-67 {
  stroke: #fff;
  stroke-width: 6px;
}

