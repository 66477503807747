@import "../../../../../module";

.assessment-question-row {
	@include Grid(grid, 1fr, calc((100% + 25px) * 0.4) auto);

	grid-auto-flow: column;
	border-bottom: 1px solid $border-grey;
	height: 75px;

	.assessment-question-img-label {
		@include Flexbox(row, center);

		height: 100%;
		padding-left: 0.5rem;
	}

	.assessment-question-row-option {
		position: relative;
		@include Flexbox(row, center, center);

		svg {
			position: absolute;
			@include Flexbox(row, center, center);
		}

		&:nth-child(even) {
			background-color: rgba(222, 226, 230, 0.2);
		}
	}
}
