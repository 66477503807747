.survey-end {
  padding: 50px 15% 40px 15%;
}
.survey-end .survey-end-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.survey-end .survey-end-content .survey-end-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.survey-end .survey-end-content .survey-end-text .survey-split-content {
  text-align: center;
  padding: 10px 0;
}
.survey-end .survey-end-content .survey-submit-error {
  color: #d9534f;
}
.survey-end .survey-end-content .btn {
  margin-top: 20px;
}

