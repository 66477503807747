.d3-tip {
  pointer-events: none !important;
}
.d3-tip .d3-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px;
  margin-left: 5px;
  color: white;
  border-radius: 5px;
  position: relative;
  pointer-events: none;
  min-width: 100px;
  text-align: center;
}
.d3-tip .d3-tooltip .tooltip-arrow {
  position: absolute;
  top: calc(50% - 5px);
  left: -5px;
  content: "";
  width: 0;
  height: 0;
  border-right: solid 5px rgba(0, 0, 0, 0.8);
  border-bottom: solid 5px transparent;
  border-top: solid 5px transparent;
}

