@import "../module";

.btn-clickable {
	cursor: pointer;
}

.adm-btn-primary {
	background-color: #8f3d6b !important;
	border-color: #8f3d6b !important;
	color: #fff !important;

	&:hover:enabled {
		background-color: #682e52 !important;
		border-color: #682e52 !important;
	}
}

.adm-btn-outline-primary {
	color: #8f3d6b !important;
	border-color: #8f3d6b !important;
	border-width: 1px !important;
	background-color: #fff !important;
	cursor: pointer;

	&.active {
		background-color: #8f3d6b !important;
		color: rgba(255, 255, 255, 0.068) !important;
	}

	&:hover {
		background-color: #8f3d6b !important;
		color: #fff !important;
	}
}

.adm-btn-alt {
	color: $label-dark-grey;
	cursor: pointer;
}

.adm-btn-alt:hover {
	color: #8f3d6b !important;
}

.adm-btn-alt-border:hover {
	cursor: pointer;
	background-color: #dee2e6;
}

.adm-btn-domain-outline-primary {
	border-width: 2px;
	opacity: 0.5;

	&.active {
		border-color: #8f3d6b !important;
		color: #fff !important;
		opacity: 1;
	}
}

.edu-btn-primary {
	background-color: $primary-educator !important;
	border-color: $primary-educator !important;
	color: #fff !important;

	&:hover {
		background-color: #10a8aa !important;
	}
}

.accent-btn {
	background-color: $primary-educator;
	border-color: $primary-educator;
	color: #fff;

	&:hover {
		background-color: $secondary-educator;
		border-color: $secondary-educator;
		color: #fff;
	}
}

.reg-btn-link {
	color: $primary-educator !important;
	background-color: transparent !important;
}

.reg-btn-outline-primary {
	color: black !important;
	border-color: #addf25 !important;
	border-width: 2px !important;
	background-color: #fff !important;

	&.active {
		background-color: #addf25 !important;
		color: black !important;
	}

	&:hover {
		background-color: #addf25 !important;
		color: black !important;
	}
}

.bg-white-button {
	background-color: #fff;
}

.nav-page-btn-left {
	left: 0;
	align-self: center;
	margin: 0;
}

.nav-page-btn-right {
	right: 0;
	margin: 0;
	float: right;
}

.no-default-style {
	border: 0;
	padding: 0;
	background-color: #fff;

	&:focus {
		outline: none;
	}
}

.text-capitalize::first-letter {
	text-transform: capitalize;
}

.role-toggle-icon {
	display: inline-block;
	width: 1rem;
}

.text-btn {
	@include TextButton($primary-caregiver);

	&-edu {
		@include TextButton($primary-educator);
	}

	&-adm {
		@include TextButton($primary-admin);
	}
}
