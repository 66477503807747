@import "../../../../module";

.menu-hamburger {
	height: 20px;
	width: 20px;
	position: relative;
	cursor: pointer;

	.menu-hamburger-line {
		position: absolute;
		left: 0;
		height: 5px;
		width: 100%;
		background-color: white;
		border-radius: 5px;
		transition: all 0.2s ease;

		&:first-child {
			top: 0;
		}

		&:nth-child(2) {
			top: 50%;
			transform: translate(0, -50%);
		}

		&:last-child {
			bottom: 0;
		}
	}

	&.open {
		.menu-hamburger-line {
			&:first-child {
				top: 50%;
				transform: translate(0, -50%) rotate(45deg);
			}

			&:nth-child(2) {
				top: 50%;
				transform: translate(0, -50%) rotate(-45deg);
			}

			&:last-child {
				top: 50%;
				transform: translate(0, -50%) rotate(45deg);
			}
		}
	}
}
