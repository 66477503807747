.radio {
  height: 20px;
  width: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.radio svg {
  height: 100%;
  width: 100% !important;
}
.radio svg path {
  fill: #199b32;
}
.radio .empty-radio {
  border: 1px solid #dee2e6;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

