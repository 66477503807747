@import "../../../../module";

.sticker-toggle {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid #fff;
	@include Flexbox(row, center, center);

	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.7);

	&.hide {
		background-color: transparent;
	}

	svg {
		height: 25px;
		width: 25px;

		path { fill: white; }
	}
}
