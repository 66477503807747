@import "../../../../../module";

.survey-start {
	width: 100%;
	@include Flexbox(row);

	.survey-start-asset {
		width: 50%;
		padding: 0 40px;

		object {
			max-width: 100%;
		}
	}

	.survey-start-content {
		width: 50%;
		padding: 0 20px 35px 0;
		@include Flexbox(column, flex-start, flex-end);

		.survey-start-text {
			font-size: 14px;
			padding-bottom: 10px;

			.survey-split-content {
				padding-bottom: 10px;
			}
		}

		.btn {
			width: 150px;
		}
	}
}
