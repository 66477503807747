@import "../../../../module";

.portal-activity-monitor {
	position: fixed;
	top: 0;
	right: calc(50% - 210px);
	height: 100vh;
	pointer-events: none;
	z-index: 8999;
	width: 420px;
	padding: 20px 0;
	@include Flexbox(row);
	@include SetMediaQuery(bootstrap-sm) {
		width: 100%;
		padding-left: 20px;
	}
	@include SetMediaQuery(small-average-phone) {
		width: 100%;
		padding-left: 0;
		right: 0;
	}

	.portal-activity-monitor-toasts {
		@include Flexbox(column, stretch, flex-end);
		@include DisablePointerEventsInElementOnly();

		width: 100%;
		height: 100%;
		overflow: hidden;
		padding: 0 10px;

		.toast-notification,
		.toast-notification-show-full-message {
			margin-bottom: 10px;

			&:last-child {
				animation: grow 0.2s 1;
			}

			.toast-notification-message {
				animation: show 0.4s 1;
			}
		}
	}

	&.reading-toast-monitor {
		right: 10px;
		width: 329px;
	}
}

@keyframes grow {
	0% {
		height: 0;
	}

	100% {
		height: $toastNotificationHeight;
	}
}
@keyframes show {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
