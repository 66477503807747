.survey-grid {
  padding: 30px 0;
}
.survey-grid .survey-grid-question .survey-grid-question-title {
  font-size: 14px;
  padding: 0 20px;
}
.survey-grid .survey-grid-question .survey-grid-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-questions {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding-top: 22px;
  min-width: 30%;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-questions .survey-grid-sub-question {
  height: 35px;
  padding: 0 20px;
  font-size: 12px;
  font-family: "nunitosemibold";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-questions .survey-grid-sub-question .survey-grid-sub-question-text {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-questions .survey-grid-sub-question .survey-grid-editable-input {
  border: none;
  background-color: transparent;
  font-family: "nunitosemibold";
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-questions .survey-grid-sub-question .survey-grid-editable-input:focus {
  outline: none;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-questions .survey-grid-sub-question:nth-child(odd) {
  background-color: rgba(222, 226, 230, 0.2);
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options {
  max-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options .survey-grid-option-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options .survey-grid-option-group .survey-grid-question-option {
  height: 22px;
  white-space: nowrap;
  padding: 0 5px;
  font-size: 12px;
  font-family: "nunitosemibold";
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options .survey-grid-option-group .survey-grid-option {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options .survey-grid-option-group .survey-grid-option:nth-child(even) {
  background-color: rgba(222, 226, 230, 0.2);
}
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options .survey-grid-option-group:last-child .survey-grid-option,
.survey-grid .survey-grid-question .survey-grid-content .survey-grid-question-options .survey-grid-option-group:last-child .survey-grid-question-option {
  padding-right: 15px;
}

