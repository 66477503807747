@font-face {
  font-family: "nunitobold";
  src: url("../nunito-bold-webfont.woff2") format("woff2"), url("../nunito-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nunitosansregular";
  src: url("../nunitosans-regular-webfont.woff2") format("woff2"), url("../nunitosans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nunito_sanssemibold";
  src: url("../nunitosans-semibold-webfont.woff2") format("woff2"), url("../nunitosans-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nunitosemibold";
  src: url("../nunito-semibold-webfont.woff2") format("woff2"), url("../nunito-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FontAwesome-S";
  font-style: normal;
  font-weight: 900;
  src: url("../../css/webfonts/fa-solid-900.eot");
  src: url("../../css/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../../css/webfonts/fa-solid-900.woff2") format("woff2"), url("../../css/webfonts/fa-solid-900.woff") format("woff"), url("../../css/webfonts/fa-solid-900.ttf") format("truetype"), url("../../css/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "FontAwesome-R";
  font-style: normal;
  font-weight: 400;
  src: url("../../css/webfonts/fa-regular-400.eot");
  src: url("../../css/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../../css/webfonts/fa-regular-400.woff2") format("woff2"), url("../../css/webfonts/fa-regular-400.woff") format("woff"), url("../../css/webfonts/fa-regular-400.ttf") format("truetype"), url("../../css/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}
* {
  letter-spacing: 0.2px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1025px) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-none {
    display: none !important;
  }
}
.row {
  margin-right: 0;
  margin-left: 0;
}

.nun-semibold {
  font-family: "nunitosemibold" !important;
}

.sticky-top {
  top: 56px !important;
}

.content-wrapper {
  max-width: 1250px;
  margin: 0 auto;
}

.btn-wrap {
  max-width: 500px;
  margin: 0 auto;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 7px;
  padding-right: 7px;
}

.main-content {
  overflow: hidden;
}

.min-h {
  min-height: 100%;
}

.rounded {
  border-radius: 1rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-top-right {
  border-top-right-radius: 1rem !important;
}

.rounded-top-left {
  border-top-left-radius: 1rem !important;
}

.min-height-full {
  min-height: 100vh;
}

.m-special {
  margin-top: 2rem !important;
}

.primary-navbar {
  position: fixed;
  z-index: 3001;
  width: 100%;
}

.secondary-nav {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 56px;
}

.notification-header {
  position: fixed;
  background-color: #fff;
  width: 300px;
  top: 0;
}

.menu-side-hidden {
  display: none;
  transition: display 0.4s;
}

.menu-side-show {
  display: block;
  transition: display 0.4s;
}

.notification-btn.dropdown-toggle::after {
  display: none;
}

.badge {
  font-size: 75% !important;
}

.nav-item .act-nav {
  color: #6c757d !important;
  border: none !important;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
}

.nav-item:hover .act-nav {
  color: #199b32 !important;
  background-color: #fff;
  border: none !important;
}

.assessment-completions img {
  width: 30px;
  margin-right: 0.5rem;
}

.educator-classrooms {
  margin-top: 130px;
  padding-bottom: 20px;
}

.dropdown-toggle {
  text-align: left !important;
}
.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}

.edu-text-complete {
  color: #ff8f1a !important;
}

.edu-bg-alt {
  background-color: #ff8f1a;
}

.edu-text-null {
  color: #dedede !important;
}

.dd-special {
  position: relative;
  z-index: 500;
}

.card-img-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.cl-border {
  width: 100%;
  left: 0;
  position: absolute;
  height: 1px;
  background-color: #dee2e6;
}

.text-primary {
  color: #199b32 !important;
}

/* BUTTON STYLES CAREGIVER */
div {
  font-family: "nunitobold";
}

.btn {
  font-family: "nunitobold";
  line-height: 26px;
  letter-spacing: 0.2px !important;
  border-radius: 0.5em;
}
.btn:focus {
  box-shadow: none;
}

.btn:hover:disabled {
  color: #6c757d;
}

.hide-username {
  display: none !important;
}

.caregiver-portal {
  min-height: 100vh;
  background-color: rgb(249, 249, 249);
}

.pnav-clear {
  position: relative;
  top: 52px;
  overflow: hidden;
}

.form-control {
  height: 2.5rem;
  letter-spacing: 0.2px !important;
  font-size: 0.9rem;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #fff;
}

.btn-primary {
  background-color: #199b32 !important;
  border-color: #199b32 !important;
  border: 0;
  color: #fff !important;
}

.btn-primary:hover {
  background-color: #036627 !important;
}

.btn-primary:disabled:hover {
  background-color: #199b32 !important;
}

.btn-light-bg {
  background-color: #fff;
  color: #6c757d !important;
}

.btn-light-bg.active {
  color: #199b32 !important;
  background-color: #f8f9fa;
}

.btn-light-bg:hover {
  color: #199b32 !important;
  background-color: #f8f9fa;
}

.edu-btn-light-bg {
  color: #6c757d;
  background-color: #fff;
}

.edu-btn-light-bg.active {
  color: #23b9bb;
  background-color: #f8f9fa;
}

.edu-btn-light-bg:hover:enabled {
  background-color: #f8f9fa;
  color: #23b9bb;
}

.btn-dropdown {
  color: #232323;
  border-color: #dee2e6;
  border-width: 1px !important;
  background-color: #fff !important;
}

.btn-dropdown.active {
  background-color: #dee2e6 !important;
  color: #232323 !important;
  border-color: #dee2e6;
}

.btn-dropdown:hover {
  background-color: #dee2e6 !important;
  color: #232323 !important;
  border-color: #dee2e6;
}

.btn-outline-primary {
  color: #199b32 !important;
  border-color: #199b32 !important;
  border-width: 1px !important;
}

.btn-outline-primary.active {
  background-color: #199b32 !important;
  color: #fff !important;
}

.btn-outline-primary:hover {
  background-color: #199b32 !important;
  color: #fff !important;
}

.activity-nav li {
  width: 33.3333333333%;
}

.list-group-item.active {
  background-color: #fff !important;
  color: #495057 !important;
}

[type=radio] {
  opacity: 0;
  position: absolute;
}

/* BUTTON STYLES EDUCATOR */
.edu-text-primary {
  color: #23b9bb !important;
}

.edu-border-primary {
  border-color: #23b9bb !important;
}

.edu-border-primary-alt::after {
  font-family: "FontAwesome-S";
  content: "\f058";
  position: absolute;
  top: calc(50% - 13px);
  left: 15px;
  font-size: 1.2rem;
  z-index: 2;
  color: #23b9bb;
}

.btn-alt {
  color: #6c757d;
  cursor: pointer;
}
.btn-alt:hover {
  color: #199b32 !important;
}
.btn-alt.active {
  color: #199b32 !important;
}

.edu-btn-alt {
  color: #6c757d !important;
  cursor: pointer;
}
.edu-btn-alt:hover {
  color: #23b9bb !important;
}

.edu-btn-outline-primary {
  color: #23b9bb !important;
  border-color: #23b9bb !important;
  border-width: 1px !important;
  background-color: #fff !important;
}

.edu-btn-outline-primary.active {
  background-color: #23b9bb !important;
  color: #fff !important;
}

.edu-btn-outline-primary:hover {
  background-color: #23b9bb !important;
  color: #fff !important;
}

.edu-btn-outline-secondary {
  color: #fff !important;
  border-color: #10a8aa !important;
  border-width: 3px !important;
  background-color: #23b9bb !important;
}

.edu-btn-outline-secondary.active {
  background-color: #10a8aa !important;
  color: #fff !important;
}

.edu-btn-outline-secondary:hover {
  background-color: #10a8aa !important;
  color: #fff !important;
}

.edu-btn-domain-outline-primary {
  border-width: 2px;
  opacity: 0.5;
}

.edu-btn-domain-outline-primary.active {
  border-color: #23b9bb !important;
  color: #fff !important;
  opacity: 1;
}

.educator-nav.nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
  border: none;
}

.educator-nav.nav-link:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #23b9bb !important;
}

.educator-nav.nav-link.active {
  color: rgb(255, 255, 255) !important;
  border: none !important;
  background-color: #23b9bb !important;
}

.nav-tabs li {
  display: inline-block;
}

/* TEXT STYLES CAREGIVER */
h1,
h2,
h3,
h4,
h5 {
  font-family: "nunitobold";
}

h5 {
  font-size: 1.2rem !important;
}

h4 {
  font-size: 1.4rem;
}

p {
  font-family: "nunitobold";
}

a {
  font-family: "nunitobold";
}

.nun-bold {
  font-family: "nunitobold" !important;
}

.overflow-hide {
  overflow: hidden;
}

.sidenav {
  height: 60%;
  width: 350px;
  position: fixed;
  z-index: 9999;
  top: 54px;
  right: 8rem;
  background-color: #fff;
  overflow-x: hidden;
}
@media (max-width: 767.98px) {
  .sidenav {
    width: 100%;
    right: 0;
  }
}
.sidenav .border-top-rounded {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-top: 1rem;
}

/* GENERAL STYLES */
body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: sans-serif;
  color: #1e1c1a;
  background-color: #f8f9fa !important;
}

.rec-dd {
  border: none !important;
  border-radius: 0 !important;
}

.rec-dd:hover {
  background-color: #f8f9fa !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* CAREGIVER MAIN CONTENT STYLES */
.assessment-img-count {
  border-radius: 15px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
  top: 23px;
  left: 9px;
}

.line-height-def {
  line-height: initial;
}

.hide-overflow {
  overflow: hidden;
}

.login-bg {
  background-image: url("../../../Assets/Login/river_teal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.forgot-button:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.login-holder {
  max-width: 500px;
  margin: 0 auto;
}

.login-holder-lg {
  max-width: 750px;
  margin: 0 auto;
}

.tooltip .arrow::before {
  border-top-color: #1e1c1a;
}

.btn-underline:hover, .btn-underline.active {
  cursor: pointer;
  text-decoration: underline !important;
}

.login-content {
  height: 100vh;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
}

.table thead th {
  border-bottom: none !important;
  border-top: none;
}

.table td {
  vertical-align: middle !important;
}

.table-hover tbody tr:hover {
  background-color: #f8f9fa !important;
}

.notif-card:hover {
  background-color: #f8f9fa !important;
  cursor: pointer;
}

#sprig-tree-holder {
  overflow: hidden;
}
@media (max-width: 767.98px) {
  #sprig-tree-holder {
    background-image: url("../../../Assets/Caregiver/Backgrounds/grass-sky.svg");
    background-size: cover;
  }
}

.report-head {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 16px;
  padding-bottom: 9px;
  top: 0;
}

#storytelling-table {
  line-height: 1.5;
}
#storytelling-table thead th {
  border-bottom: 3px solid rgba(60, 149, 43, 0.1);
  text-align: left;
}
#storytelling-table tbody {
  border-bottom: 3px solid rgba(60, 149, 43, 0.1);
}
#storytelling-table tbody th {
  border-top: 2px solid rgba(60, 149, 43, 0.1);
  vertical-align: middle;
  text-align: left;
}
#storytelling-table tbody td {
  border-top: 2px solid rgba(60, 149, 43, 0.1);
  vertical-align: middle;
  text-align: left;
}

.story-images {
  height: auto;
}

.storytelling-th {
  width: 25%;
}
.storytelling-th audio {
  width: 100%;
}
.storytelling-th audio::-webkit-media-controls-panel {
  background-color: #199b32 !important;
}

.bg-primary-header {
  background-color: rgba(60, 149, 43, 0.1);
}

.assessment-paragraph {
  line-height: 1.5 !important;
}

/* MODAL STYLES */
.modal {
  z-index: 9999 !important;
}

.modal-dialog {
  margin: 0;
}

.modal-content iframe {
  height: auto !important;
  width: 100% !important;
  min-height: 400px !important;
}

.modal-dialog .modal-content {
  height: auto;
  width: 100%;
  margin: 0 auto;
  border: none;
}

.card-body iframe {
  height: auto !important;
  width: 100% !important;
  min-height: 400px !important;
}

#all_groups_complete.active {
  border: none !important;
}

.user-roles:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #fff;
}
.user-roles .roles .current-role button {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  width: 100%;
}
.user-roles .roles .current-role button:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.user-roles .roles .edu button {
  text-align: left;
  width: 100%;
}
.user-roles .roles .edu button:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-menu {
  overflow: hidden;
  border: 1px solid #dee2e6;
}

.dd-overflow-auto {
  overflow: auto !important;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-item:active {
  background-color: #dee2e6 !important;
  color: #1e1c1a !important;
}

.card-class-tree {
  position: relative;
  overflow: hidden;
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.no-link-decoration {
  color: black;
}
.no-link-decoration:hover {
  text-decoration: none;
  color: black;
}

.controller-nav {
  text-align: center;
}
.controller-nav .controller-unit {
  display: inline-block;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #fff;
  color: #fff;
  border-radius: 50%;
  border: 3px solid #dee2e6;
  transform: scale(0.5);
}
.controller-nav .controller-unit .is-clicked {
  width: 50%;
  height: 50%;
  background-color: #aaa;
  position: relative;
  top: 1px;
  display: inline-block;
  border-radius: 50%;
}
.controller-nav .controller-unit .is-clicked::after {
  color: #fff;
  font-size: 80%;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: greyscale;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.text-uppercase {
  text-transform: uppercase;
}

.z-1 {
  position: relative;
  z-index: 1;
}
.z-2 {
  position: relative;
  z-index: 2;
}
.z-3 {
  position: relative;
  z-index: 3;
}
.z-4 {
  position: relative;
  z-index: 4;
}

.completed-saved-button {
  margin-left: 1rem;
}
@media (max-width: 375px) {
  .completed-saved-button {
    margin-left: 0.5rem;
  }
}

/* TABLET PORTRAIT BREAKPOINT */
@media (min-width: 576px) {
  .modal-lg {
    max-width: 1000px !important;
  }
  .modal-dialog {
    max-width: 440px;
  }
}
@media (min-width: 768px) {
  .primary-nav {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .hide-username {
    display: inline-block !important;
  }
  .modal-dialog {
    margin: 0 auto;
  }
  .modal-dialog .modal-content {
    height: auto;
    min-height: 0;
    margin-bottom: 60px;
    margin-top: 60px;
    width: 100%;
  }
}
/* DESKTOP SMALL BREAKPOINT */
@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px !important;
  }
  .modal-xlg {
    max-width: 1000px;
  }
}

