@mixin SetMediaQuery($type) {
	@if $type == extra-small-phone {
		@media (max-width: 320px) {
			@content;
		}
	} @else if $type == small-average-phone {
		@media (max-width: 375px) {
			@content;
		}
	} @else if $type == phone {
		@media (max-width: 599px) {
			@content;
		}
	} @else if $type == tablet-portrait {
		@media (min-width: 600px) {
			@content;
		}
	} @else if $type == tablet-landscape {
		@media (min-width: 900px) {
			@content;
		}
	} @else if $type == desktop {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $type == desktop-big {
		@media (min-width: 1800px) {
			@content;
		}
	} @else if $type == bootstrap-sm {
		@media (max-width: 767.98px) {
			@content;
		}
	} @else if $type == bootstrap-md {
		@media (min-width: 768px) and (max-width: 991.98px) {
			@content;
		}
	} @else if $type == bootstrap-sm-md {
		@media (max-width: 991.98px) {
			@content;
		}
	} @else if $type == bootstrap-lg {
		@media (min-width: 992px) and (max-width: 1199.98px) {
			@content;
		}
	} @else if $type == bootstrap-xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else {
		@error "Unknown media query #{$type}.";
	}
}
@mixin NavLink($color) {
	color: rgba(255, 255, 255, 0.8) !important;
	border: none;

	&:hover {
		color: #fff;
		background-color: $color !important;
		border: none !important;
	}

	&.active {
		background-color: $color !important;
		color: #fff !important;
		border: none !important;
	}
}
@mixin Flexbox(
	$direction: row,
	$align: stretch,
	$justify: flex-start,
	$wrap: nowrap
) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;
	flex-wrap: $wrap;
}
@mixin DisablePointerEventsInElementOnly() {
	pointer-events: none !important;

	& > * {
		pointer-events: auto;
	}
}
@mixin Datepicker($primary-color, $secondary-color) {
	.react-datepicker-wrapper {
		width: auto;
		flex: 1;

		.react-datepicker__input-container {
			width: 100%;

			input {
				border-top-right-radius: 0.5rem;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0.5rem;
			}
		}
	}

	.react-datepicker__ {
		&header {
			background-color: #fff;
		}

		&day--selected,
		&day--in-selecting-range,
		&day--in-range,
		&month-text--selected,
		&month-text--in-selecting-range,
		&month-text--in-range,
		&day--keyboard-selected,
		&month-text--keyboard-selected {
			background-color: $primary-color;

			&:hover {
				background-color: $secondary-color;
			}
		}

		&close-icon {
			&::after {
				background-color: $primary-color;
			}
		}
	}
}
@mixin UnreadNotificationCountsBadge() {
	.badge-pill {
		position: absolute;
		z-index: 9999;
		right: 0;
		top: 0;
		background-color: $badge-background;
		border-radius: 50%;
		width: 20px;
		height: 20px;

		.unread-count {
			position: relative;
			left: -3px;
			top: 1px;
			right: 0;
			bottom: 0;
		}

		.unread-count-less-ten {
			position: relative;
			left: -1px;
			top: 1px;
			right: 0;
			bottom: 0;
		}
	}
}
@mixin EditorActivityForm() {
	.new-activity-form {
		padding: 2rem 2rem 2rem;
		margin-bottom: 0;
		border-bottom: 1px solid #dee2e6;

		input[type="radio"] + label {
			cursor: pointer;
		}

		input[type="radio"] + label::after {
			width: 20px;
			height: 20px;
			border-radius: 20px;
			top: 5px;
			left: 2px;
			position: relative;
			margin: 0 20px 0 0;
			content: "";
			display: inline-block;
			visibility: visible;
			border: 1px solid $border-grey;
		}

		input[type="radio"] + label:hover::after {
			background: $primary-educator;
		}

		input[type="radio"]:checked + label::after {
			width: 20px;
			height: 20px;
			border-radius: 20px;
			top: 5px;
			left: 2px;
			position: relative;
			margin: 0 20px 0 0;
			background-color: $primary-educator;
			content: "";
			display: inline-block;
			visibility: visible;
			border: 1px solid $border-grey;
		}
	}
}
@mixin MultiSelectItem($float-value) {
	.multi-select-item {
		@include MutilSelectItemStyle($float-value, $label-dark-grey);

		&:not(:last-child) {
			margin-bottom: 3px;
		}

		&.published-status {
			background-color: $primary-educator;
		}

		&-contrast {
			background-color: white;
			color: $primary-educator;
			border: 1px solid $primary-educator;
			padding: 0 3px;
			margin-right: 3px;
			border-radius: 4px;
			float: $float-value;
			font-size: 80%;

			&:not(:last-child) {
				margin-bottom: 3px;
			}
		}

		&-bg-dark-grey {
			@include MutilSelectItemStyle($float-value, $label-dark-grey);
		}
	}
}
@mixin MutilSelectItemStyle($float-value, $bg-color) {
	background-color: $bg-color;
	padding: 0 3px;
	margin-right: 3px;
	border-radius: 4px;
	float: $float-value;
	font-size: 80%;
}
@mixin ButtonColorAndHover($primary-color, $secondary-color) {
	cursor: pointer;
	color: $primary-color;

	&:hover {
		color: $secondary-color;
	}
}
@mixin EditActivityOutcomes() {
	.outcomes {
		padding-top: 1rem;

		.curriculum-learning-outcomes {
			border: 1px solid #dee2e6;
			padding: 1rem 1rem;
			margin-bottom: 0.8rem;
			border-radius: 1rem;

			.learning-outcomes {
				width: 100%;

				.learning-outcome-button {
					position: relative;
					top: 1.5rem;

					.fa-chevron-up {
						color: $primary-educator;
					}

					.fa-chevron-down {
						color: $primary-educator;
						opacity: 0.5;
					}
				}
			}

			.general-outcomes {
				display: flex;
				flex-wrap: wrap;
				margin-top: 0.2rem;
			}
		}

		.general-curriculum-outcomes {
			width: 100%;
			position: relative;
			padding-right: 10px;
			padding-left: 10px;

			.row {
				.small-col-md {
					position: absolute;
					left: 0;

					.small-row {
						width: 15px;
						height: 22px;
					}
				}

				.small-col-sm,
				.small-col-lg,
				.small-col-xl {
					position: absolute;
					left: 0;

					.small-row {
						width: 15px;
						height: 24px;
					}
				}

				.small-col-xs {
					position: absolute;
					left: 0;

					.small-row {
						width: 15px;
						height: 28px;
					}
				}

				#row-2-right {
					border-bottom: 1px solid #dee2e6;
				}

				#row-5-right {
					border-bottom: 1px solid #dee2e6;
				}

				#row-3-right,
				#row-4-right,
				#row-5-right {
					border-left: 1px solid #dee2e6;
				}

				.big-col {
					width: 18rem;
				}

				.delete-outcome-button {
					position: absolute;
					top: 3px;
					line-height: 0;
					left: 12.5rem;
					padding: 1px 5px;
				}
			}
		}

		.add-learning-outcome {
			position: relative;
			margin-top: 10px;
		}

		.add-learning-outcome-button {
			margin-top: 1rem;
			display: block;
		}
	}
}
@mixin collapseTransition() {
	.collapse-css-transition {
		transition: height 300ms linear;
	}
}
@mixin editActivityModal() {
	@include MultiSelectItem(left);
	@include EditorActivityForm();

	.activity-curriculum {
		padding: 1rem 2rem 2rem;
		@include collapseTransition();

		.fa-chevron-up {
			color: $primary-educator;
		}

		.fa-chevron-down {
			color: $primary-educator;
			opacity: 0.5;
		}
	}

	.competencies {
		padding: 1rem 0 0 0;
	}

	.outcomes-dropdown {
		max-height: 20rem;
		overflow-y: scroll;
		overflow-x: scroll;
	}
	@include EditActivityOutcomes();

	.add-curriculum {
		padding: 2rem 2rem 2rem;
		text-align: center;
	}
}
@mixin ellipsis($width: 100%, $display: inline-block) {
	display: $display;
	width: $width;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1) {
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	text-align: start;
	margin-right: -1em;
	padding-right: 1em;

	&::before {
		content: "...";
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
	}
}
@mixin activityCard() {
	&:hover {
		background-color: #f8f9fa !important;
	}
}
@mixin DropdownItemHover($backgroundColor) {
	&:hover {
		color: #16181b;
		text-decoration: none;
		background-color: $backgroundColor;
	}
}
@mixin SetZIndex($value) {
	position: relative;
	z-index: $value;
}
@mixin SetFlex($value) {
	flex: $value;
}
@mixin SprigTreeSlice() {
	.slice {
		cursor: pointer;
		$leaf-colors: $leaf-green-darkest $leaf-green-bold $leaf-green-semi-bold
			$leaf-green-medium $leaf-green-base $leaf-green-base $leaf-green-lightest;
		$leaf-colors-hover: $leaf-green-darkest-hover $leaf-green-bold-hover
			$leaf-green-semi-bold-hover $leaf-green-medium-hover
			$leaf-green-base-hover $leaf-green-base-hover $leaf-green-lightest-hover;
		@for $i from 0 through 6 {
			&#{$i} {
				fill: nth($leaf-colors, $i + 1);

				&:hover {
					fill: lighten(nth($leaf-colors-hover, $i + 1), 10%);
				}

				&.outcome-selected {
					fill: nth($leaf-colors-hover, $i + 1);
				}
			}
		}
	}
}
@mixin TableSortable() {
	&.sortable {
		cursor: pointer;
		position: relative;
		user-select: none;

		& > * {
			max-width: calc(100% - 10px);
		}

		&.not-sorted {
			&::after {
				content: "";
				position: absolute;
				right: 10px;
				top: calc(50% - 5px);
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 8px solid $label-dark-grey;
			}

			&:hover {
				background-color: $border-grey;

				&::after {
					content: "";
					position: absolute;
					right: 10px;
					top: calc(50% - 5px);
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 8px solid $label-dark-grey;
				}
			}
		}

		&.sorted {
			background-color: $border-grey;

			&.asc {
				&::after {
					content: "";
					position: absolute;
					right: 10px;
					top: calc(50% - 5px);
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 8px solid $label-dark-grey;
				}
			}

			&.dsc {
				&::after {
					content: "";
					position: absolute;
					right: 10px;
					top: calc(50% - 5px);
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-bottom: 8px solid $label-dark-grey;
				}
			}
		}
	}
}
@mixin TextButton($color) {
	color: $label-dark-grey;

	&:hover,
	&.active {
		cursor: pointer;
		color: $color;
		text-decoration: none;
	}
}
@mixin EditorActivityModal() {
	.message-duplicate {
		padding: 1rem 2rem 0;
	}

	.mandatory-asterisk {
		width: 0.5em;
		height: 0.5em;
		color: $brand-danger;
		vertical-align: top;
	}

	.mandatory-missing {
		-webkit-box-shadow: 0 0 10px 0 $brand-danger;
		-moz-box-shadow: 0 0 10px 0 $brand-danger;
		box-shadow: 0 0 10px 0 $brand-danger;
	}

	.mandatory-missing-text {
		text-shadow: 0 0 6px $brand-danger;
	}

	.editor-outcome-tooltip {
		max-width: 240px;
	}

	.share-permission {
		padding: 1rem 2rem 2rem;

		.switch-container {
			position: relative;
			bottom: -0.5rem;
			display: inline;
		}

		input[type="checkbox"] {
			&.switch {
				position: absolute;
				opacity: 0;

				+ div {
					vertical-align: middle;
					width: 20px;
					height: 10px;
					border-radius: 999px;
					background-color: $primary-educator;
					-webkit-transition-duration: 0.4s;
					transition-duration: 0.4s;
					-webkit-transition-property: background-color, box-shadow;
					transition-property: background-color, box-shadow;
					cursor: pointer;
				}

				&:checked {
					+ div {
						background-color: $primary-educator;
					}

					+ div > div {
						-webkit-transform: translate3d(20px, 0, 0);
						transform: translate3d(20px, 0, 0);
						background-color: #fff;
						margin-top: 3px;
						margin-left: 3px;
					}
				}

				+ div {
					width: 47px;
					height: 24px;
				}

				+ div > div {
					float: left;
					width: 18px;
					height: 18px;
					border-radius: inherit;
					background: #fff;
					-webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
					transition-timing-function: cubic-bezier(1, 0, 0, 1);
					-webkit-transition-duration: 0.4s;
					transition-duration: 0.4s;
					-webkit-transition-property: transform, background-color;
					transition-property: transform, background-color;
					pointer-events: none;
					margin-top: 3px;
					margin-left: 3px;
				}
			}

			&.switch:checked + div > div {
				-webkit-transform: translate3d(22px, 0, 0);
				transform: translate3d(22px, 0, 0);
			}
		}
	}
}
@mixin EditorHomePage() {
	.secondary-nav {
		position: fixed;
		top: 54px;
		z-index: 1;
		overflow: visible;

		.search-activity-input-group {
			height: 100%;

			.search-activity-input {
				height: 100%;
			}
		}

		.dropdown {
			display: flex;

			.domain-dropdown {
				border: 1px solid #ced4da;
			}
		}
	}

	.editor-activities-list {
		.list-panel {
			background: #fff;

			table {
				thead {
					tr {
						th {
							@include TableSortable();
						}
					}
				}
			}
		}

		position: relative;
		padding-top: calc(#{$header-height} + #{$secondary-header-height});
		padding-bottom: 20px;
		@include MultiSelectItem(none);
	}

	.message-box {
		align-items: center;
		display: flex;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: rgba(206, 212, 218, 0.4);
		border-radius: 1rem;
		padding: 15px;

		button {
			background-color: transparent;
			margin-left: 1rem;
		}
	}
}
@mixin Grid(
	$grid: grid,
	$row: auto,
	$column: auto,
	$rowGap: auto,
	$columnGap: auto
) {
	display: $grid;
	grid-template-columns: $column;
	grid-template-rows: $row;
	grid-column-gap: $columnGap;
	grid-row-gap: $rowGap;
}
@mixin Scrollbar($trackColor: auto, $thumbColor: auto, $trackWidth: 10px) {
	scrollbar-color: $trackColor $thumbColor;

	&::-webkit-scrollbar {
		background-color: $trackColor;
		width: $trackWidth;
	}

	&::-webkit-scrollbar-track {
		background-color: $trackColor;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: 10px;
	}
}
@mixin WordBreak($value) {
	word-break: $value;
}
@mixin ActivityItemCardWrapper {
	.activity-card-wrapper {
		flex: 0 0 100%;
		max-width: 100%;
		min-width: 298px;
		padding: 0.75rem;

		.activity-card {
			background-color: #fff;
			border-radius: 22px;
			@include activityCard();

			&:hover {
				cursor: pointer;
			}
		}
		$card-grid-width: 100% 50% 33.33% 33.33%;
		$media-size: bootstrap-sm bootstrap-md bootstrap-lg bootstrap-xl;
		@for $i from 1 through 4 {
			@include SetMediaQuery(nth($media-size, $i)) {
				flex: 0 0 nth($card-grid-width, $i);
				max-width: nth($card-grid-width, $i);
			}
		}
	}
}
@mixin ActivityCardResponsiveWidth {
	$card-width: 100% 375px 375px 400px;
	$media-size: bootstrap-sm bootstrap-md bootstrap-lg bootstrap-xl;
	@for $i from 1 through 4 {
		@include SetMediaQuery(nth($media-size, $i)) {
			max-width: nth($card-width, $i);
		}
	}
}

@mixin CircleChartColourCode {
	.previous {
		svg {
			&.mastered {
				fill: $circle-chart-color-code-previous;
			}
			&.need-explicit {
				stroke: $circle-chart-color-code-previous;
				fill: #fff;
			}

			&.needs-more {
				fill: none;

				.half-circle {
					fill: $circle-chart-color-code-previous;
				}

				.stroke {
					stroke: $circle-chart-color-code-previous;
				}
			}
		}
	}

	.sep-nov {
		svg {
			&.mastered {
				fill: $circle-chart-color-code-sep-nov;
			}
			&.need-explicit {
				stroke: $circle-chart-color-code-sep-nov;
				fill: #fff;
			}

			&.needs-more {
				fill: none;

				.half-circle {
					fill: $circle-chart-color-code-sep-nov;
				}

				.stroke {
					stroke: $circle-chart-color-code-sep-nov;
				}
			}
		}
	}

	.dec-jan {
		svg {
			&.mastered {
				fill: $circle-chart-color-code-dec-jan;
			}
			&.need-explicit {
				stroke: $circle-chart-color-code-dec-jan;
				fill: #fff;
			}

			&.needs-more {
				fill: none;

				.half-circle {
					fill: $circle-chart-color-code-dec-jan;
				}

				.stroke {
					stroke: $circle-chart-color-code-dec-jan;
				}
			}
		}
	}

	.feb-mar {
		svg {
			&.mastered {
				fill: $circle-chart-color-code-feb-mar;
			}
			&.need-explicit {
				stroke: $circle-chart-color-code-feb-mar;
				fill: #fff;
			}

			&.needs-more {
				fill: none;

				.half-circle {
					fill: $circle-chart-color-code-feb-mar;
				}

				.stroke {
					stroke: $circle-chart-color-code-feb-mar;
				}
			}
		}
	}

	.apr-jun {
		svg {
			&.mastered {
				fill: $circle-chart-color-code-apr-jun;
			}
			&.need-explicit {
				stroke: $circle-chart-color-code-apr-jun;
				fill: #fff;
			}

			&.needs-more {
				fill: none;

				.half-circle {
					fill: $circle-chart-color-code-apr-jun;
				}

				.stroke {
					stroke: $circle-chart-color-code-apr-jun;
				}
			}
		}
	}
}

@mixin CircleChartBlack {
	svg {
		&.mastered {
			fill: #000;
		}
		&.need-explicit {
			stroke: #000;
			fill: #fff;
		}

		&.needs-more {
			fill: none;

			.half-circle {
				fill: #000;
			}

			.stroke {
				stroke: #000;
			}
		}
	}
}

@mixin StatCard {
	.text {
		width: 110px;
		text-align: center;
		font-size: 15px;
		margin: 0 0 8px;
		color: $default-text-color;
		line-height: 1;
	}

	.count {
		font-size: 29px;
	}

	&.skill-mastered {
		.text {
			width: 90px;
		}
		.count {
			color: $reading-mastered;
		}
	}

	&.needs-more {
		.count {
			color: $reading-more-practice;
		}
	}

	&.needs-explicit {
		.count {
			color: $reading-need-instruction;
		}
	}

	&.not-yet-assessed {
		.count {
			color: $reading-not-assessed;
		}
	}
}

@mixin PlayerSize {
	@include SetMediaQuery(bootstrap-sm) {
		width: 320px;
	}
	@include SetMediaQuery(bootstrap-md) {
		width: 389px;
	}
	@include SetMediaQuery(bootstrap-lg) {
		width: 425px;
	}
	@include SetMediaQuery(bootstrap-xl) {
		width: 425px;
	}
}
