@import "../../../../module";

.component-checkbox_dropdown {
	.dropdown-menu {
		max-height: 200px;
		overflow: auto;
	}

	.component-dropdown_checkbox {
		input[type=checkbox] {
			cursor: pointer;
		}

		label {
			margin: 0;
		}

		&.dropdown-item {
			cursor: default;
		}

		&.dd-master {
			border-bottom: 1px solid $border-grey;

			input[type=checkbox] {
				cursor: not-allowed;
			}
		}
	}
}
