.flyout-navigation-component {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: -120%;
  top: 56px;
  background-color: white;
  border-right: 1px solid #dee2e6;
  padding: 0;
  transition: left 0.5s ease;
}
.flyout-navigation-component.open {
  left: 0;
}
.flyout-navigation-component .flyout-navigation-header {
  width: 100%;
  height: 13%;
  padding-left: 40px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.flyout-navigation-component .flyout-navigation-header svg,
.flyout-navigation-component .flyout-navigation-header h3 {
  color: #6c757d;
  margin: 0 0 0 5px;
}
.flyout-navigation-component .flyout-navigation-header svg {
  width: 30px;
  height: 30px;
}
.flyout-navigation-component .flyout-navigation-header .flyout-link {
  margin-right: 5px;
}
.flyout-navigation-component .flyout-navigation-header .flyout-link svg {
  height: 20px;
  width: 20px;
}
.flyout-navigation-component .flyout-navigation-header.go-back-link {
  padding-left: 0;
}
.flyout-navigation-component .flyout-navigation-items {
  list-style: none;
  padding: 20px 0;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item {
  padding: 10px 0;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item span {
  font-size: 1.2rem;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item a {
  position: relative;
  color: #6c757d;
  text-decoration: none;
  padding: 0 10px 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item a:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #6c757d;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item a svg {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  fill: #6c757d;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active,
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover {
  position: relative;
}
.admin-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active,
.admin-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover {
  color: #8f3d6b;
}
.educator-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active,
.educator-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover {
  color: #23b9bb;
}
.principal-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active,
.principal-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover {
  color: #23b9bb;
}
.director-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active,
.director-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover {
  color: #23b9bb;
}
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active::after,
.flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
}
.admin-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active::after,
.admin-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover::after {
  background-color: #8f3d6b;
}
.educator-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active::after,
.educator-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover::after {
  background-color: #23b9bb;
}
.principal-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active::after,
.principal-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover::after {
  background-color: #23b9bb;
}
.director-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active::after,
.director-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover::after {
  background-color: #23b9bb;
}
.admin-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active svg,
.admin-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover svg {
  fill: #8f3d6b;
}
.educator-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active svg,
.educator-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover svg {
  fill: #23b9bb;
}
.principal-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active svg,
.principal-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover svg {
  fill: #23b9bb;
}
.director-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active svg,
.director-portal .flyout-navigation-component .flyout-navigation-items .flyout-navigation-item .active:hover svg {
  fill: #23b9bb;
}

.admin-portal .flyout-navigation-component .flyout-navigation-item .active,
.admin-portal .flyout-navigation-component .flyout-navigation-item .active:hover {
  color: #8f3d6b;
}
.admin-portal .flyout-navigation-component .flyout-navigation-item .active::after,
.admin-portal .flyout-navigation-component .flyout-navigation-item .active:hover::after {
  background-color: #8f3d6b;
}

.educator-portal .flyout-navigation-component .flyout-navigation-item .active,
.educator-portal .flyout-navigation-component .flyout-navigation-item .active:hover,
.principal-portal .flyout-navigation-component .flyout-navigation-item .active,
.principal-portal .flyout-navigation-component .flyout-navigation-item .active:hover {
  color: #23b9bb;
}
.educator-portal .flyout-navigation-component .flyout-navigation-item .active::after,
.educator-portal .flyout-navigation-component .flyout-navigation-item .active:hover::after,
.principal-portal .flyout-navigation-component .flyout-navigation-item .active::after,
.principal-portal .flyout-navigation-component .flyout-navigation-item .active:hover::after {
  background-color: #23b9bb;
}

