@import "../../../Stylesheets/scss/module";

// Color Mixin Rules

@mixin ButtonRules($override, $primary, $secondary) {
	color: white;
	@if $override {
		background-color: $primary !important;

		&:hover {
			color: white;
			background-color: $secondary !important;

			&:disabled {
				background-color: $primary !important;
			}
		}
	} @else {
		background-color: $primary;

		&:hover {
			color: white;
			background-color: $secondary;

			&:disabled {
				background-color: $primary;
			}
		}
	}
}
@mixin CaregiverButtonRules($override: false) {
	@include ButtonRules($override, $primary-caregiver, $secondary-caregiver);
}
@mixin EducatorButtonRules($override: false) {
	@include ButtonRules($override, $primary-educator, $secondary-educator);
}
@mixin AdminButtonRules($override: false) {
	@include ButtonRules($override, $primary-admin, $secondary-admin);
}

.btn {
	// Common Button Properties
	font-family: $font-family-bold;
	border-radius: 10px;
	cursor: pointer;
	transition: background-color 0.2s ease;
	box-sizing: border-box;

	&:disabled {
		opacity: 0.75;
		cursor: default;
	}

	&:focus {
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}

	// Button Inner Content
	&--content {
		@include Flexbox(row);

		.icon {
			margin-left: 3px;
			svg {
				color: white;
			}
		}
	}

	// Button Properties By Type
	&--primary {
		@include CaregiverButtonRules(false);

		// Forced User Override
		&.btn--caregiver {
			@include CaregiverButtonRules(true);
		}

		&.btn--educator {
			@include EducatorButtonRules(true);

			&:hover {
				&:disabled {
					color: #fff;
				}
			}
		}

		&.btn--admin {
			@include AdminButtonRules(true);
		}
	}

	&--secondary {
		@include ButtonRules(false, $button-gray, $button-darkgray);

		&:hover {
			&:disabled {
				box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.8);
			}
		}
	}

	&--alt {
		@include ButtonRules(false, white, $button-lightgray);

		color: $default-label-black;
		border: 1px solid $button-lightgray;

		.btn--content {
			.icon {
				svg {
					color: $default-label-black;
				}
			}
		}
	}

	&--text {
		background-color: #fff;

		&:focus {
			box-shadow: none;
		}

		&.btn--caregiver {
			color: $primary-caregiver;
			&:hover {
				color: $secondary-caregiver;
			}
		}

		&.btn--educator {
			color: $primary-educator;
			&:hover {
				color: $secondary-educator;
			}
		}

		&.btn--admin {
			color: $primary-admin;
			&:hover {
				color: $secondary-admin;
			}
		}
	}

	// Button Sizing Properties
	&--xsm {
		font-size: 0.875rem;
		padding: 0.375rem 0.75rem;

		.icon {
			font-size: 12px;
			margin-left: 0;
		}
	}

	&--sm {
		font-size: 12px;
		padding: 10px 16px;
	}

	&--md {
		font-size: 14px;
		padding: 11px 20px;
	}

	&--lg {
		font-size: 16px;
		padding: 12px 24px;
	}
}
