@import "../../../../module";

.portal-error-screen {
	height: 100vh;
	width: 100vw;
	@include Flexbox(row, center, center);

	background-image: url("../../../../../../../Assets/Caregiver/Backgrounds/grass-sky.svg");
	background-size: contain;
	@include SetMediaQuery(bootstrap-sm-md) {
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.portal-error-content {
		max-width: 600px;
		min-width: 400px;
		width: 100%;
		background-color: white;
		padding: 1rem;
		text-align: center;
		border-radius: 10px;
		@include Flexbox(column, center, center);

		.error-text {
			margin-bottom: 0.5rem;

			&:first-child {
				color: $brand-danger;
			}
		}
	}
}
